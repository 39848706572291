import React, { useEffect, useState } from "react";
import { useQuickstatsQuery } from "../../generated/graphql";
import { QSCard } from "../molecules/QSCard";

export const MainDashboardQuickstats = () => {
  const [turnover, setTurnover] = useState(0);
  const { data, loading } = useQuickstatsQuery({
    pollInterval: 1000,
    fetchPolicy: "cache-and-network",
  });
  useEffect(() => {
    if (data && data.turnover) {
      let sum = 0;
      data.turnover.map((to) => {
        to.organizations.map((o) => {
          sum += o.zreports_aggregate.aggregate?.sum?.total_sales * to.rate;
        });
      });
      setTurnover(sum);
    }
  }, [data]);
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 py-4 gap-4">
      <QSCard
        label="Plinka oms."
        value={Intl.NumberFormat("se-SV", {
          style: "currency",
          currency: "SEK",
        }).format(turnover || 0)}
        loading={loading}
      />
      <QSCard
        label="Försäljning totalt"
        value={Intl.NumberFormat("se-SV", {
          style: "currency",
          currency: "SEK",
        }).format(data?.total_sales.aggregate?.sum?.total_sales || 0)}
        loading={loading}
      />
      <QSCard
        label="Antal organisationer"
        value={data?.organizations.aggregate?.count || 0}
        loading={loading}
      />
      <QSCard
        label="Antal använare"
        value={data?.users.aggregate?.count || 0}
        loading={loading}
      />
    </div>
  );
};
