import type { NextPage } from "next";
import Link from "next/link";

const Home: NextPage = () => {
  return (
    <div className="text-lg w-full h-full flex justify-center items-center flex-1">
      <Link href="/dashboard">Fejkad inloggning, till dashboarden</Link>
    </div>
  );
};

export default Home;
