import { useRouter } from "next/dist/client/router";
import Link from "next/link";
import React from "react";

interface Props {
  href: string;
  label: string;
  icon?: React.ReactElement;
}
export const SidebarNavItem: React.FC<Props> = ({ href, label, icon }) => {
  const router = useRouter();
  return (
    <li>
      <Link href={href} passHref>
        <a className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-blue-800 dark:hover:bg-gray-600 text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-blue-500 dark:hover:border-gray-800 pr-6">
          <span className="inline-flex justify-center items-center ml-4">
            <svg
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
              ></path>
            </svg>
          </span>
          <span
            className={`ml-2 text-sm tracking-wide truncate ${
              router.pathname.includes(href) ? "font-bold" : ""
            }`}
          >
            {label}
          </span>
        </a>
      </Link>
    </li>
  );
};
