import React from "react";
import { SidebarNav, SidebarNavItem, SidebarNavLabel } from "../molecules";
import { MainHeaderBar } from "../organisms";

export const Layout: React.FC = ({ children }) => {
  return (
    <div className="h-screen overflow-hidden flex flex-col antialiased bg-white text-gray-800 z-50">
      <MainHeaderBar />
      {/* sidebar */}
      <SidebarNav>
        <SidebarNavItem href="/dashboard" label="Hem" />
        <SidebarNavItem href="/organizations" label="Föreningar" />
        <SidebarNavItem href="/users" label="Användare" />
        <SidebarNavItem href="/deals" label="Avtal" />
        <SidebarNavItem href="/reports" label="Rapporter" />
        <SidebarNavLabel label="Ekonomi" />
        <SidebarNavItem href="/billing" label="Fakturering" />
        <SidebarNavLabel label="Systeminställningar" />
        <SidebarNavItem href="/settings/dealtypes" label="Avtalstyper" />
        <SidebarNavItem href="/settings/vat" label="Momssatser" />
        <SidebarNavLabel label="Övrigt" />
        <SidebarNavItem href="/profile" label="Profil" />
        <SidebarNavItem href="/logout" label="Logga ut" />
      </SidebarNav>
      {/* /sidebar */}

      {/* content */}

      {/* <div className="h-full pl-8 pt-4 ml-24 mt-14 mb-10 md:ml-2/5 max-w-7xl"> */}
      <div className="h-full pl-8 pt-4  mt-14 max-w-7xl ml-96">{children}</div>
      {/* /content */}
    </div>
  );
};
