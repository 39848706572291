import { BarDatum } from "@nivo/bar";
import dayjs from "dayjs";
import React from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useGetAllZreportsQuery } from "../../generated/graphql";
require("dayjs/locale/sv");

dayjs.locale("sv");

interface iDataArray {
  date: string;
  total_sales: number;
}

export const MainDashboardSalesChart = () => {
  const { data, loading } = useGetAllZreportsQuery();
  const dataArray: BarDatum[] = [];

  if (loading) return null;

  // console.log(groupByDate(data?.order_zreports, "date"));

  data?.order_zreports.map((zr) => {
    const date = dayjs(zr.date).format("DD MMM");
    let found = dataArray.some((obj) => {
      if (obj.date === date) {
        obj.total_sales += zr.total_sales;
        return true;
      }
    });
    if (!found) {
      dataArray.push({ date, total_sales: zr.total_sales });
    }
  });
  console.log(dataArray);
  return (
    <div className="my-8 text-xs">
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart width={900} height={300} data={dataArray}>
          <defs>
            <linearGradient
              id="dashboard-sales-chart"
              x1={0}
              y1={0}
              x2={0}
              y2={1}
            >
              <stop offset="0%" stopColor="#00a13a" stopOpacity={0.2} />
              <stop offset="100%" stopColor="#00a13a" stopOpacity={0.01} />
            </linearGradient>
          </defs>
          <Area
            type="monotone"
            dataKey="total_sales"
            stroke="#00a13a"
            fill="url(#dashboard-sales-chart)"
          />
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <Tooltip content={<CustomTooltip />} />
          <XAxis dataKey="date" tickLine={false} axisLine={false} />
          <YAxis
            label={<CustomizedLabel />}
            width={120}
            tickLine={false}
            axisLine={false}
            tickFormatter={(n) =>
              Intl.NumberFormat("se-SV", {
                style: "currency",
                currency: "SEK",
              }).format(n)
            }
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

interface iCustomizedLabelProps {
  label?: any;
}
const CustomizedLabel: React.FC<iCustomizedLabelProps> = ({ label }) => {
  return <div className="text-xs">{label}</div>;
};

interface iCustomTooltipProps {
  active?: boolean;
  payload?: any[];
  label?: string;
}
const CustomTooltip: React.FC<iCustomTooltipProps> = ({
  active,
  payload,
  label,
}) => {
  if (active) {
    const value = Intl.NumberFormat("se-SV", {
      style: "currency",
      currency: "SEK",
    }).format(payload ? payload[0].payload.total_sales : 0);
    return (
      <div className="rounded-lg shadow-lg bg-petroleum text-white p-4 text-center">
        <div className="text-xl">{value}</div>
        <div className="text-sm">{label}</div>
      </div>
    );
  }
  return null;
};
