import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN: string =
  process.env.BUGS_DSN ||
  process.env.NEXT_PUBLIC_BUGS_DSN ||
  "https://38629157436e488ea983756ef3bdd111@bugs.getplinka.com/7";
const SENTRY_ENV: string =
  process.env.BUGS_ENV ||
  process.env.NEXT_PUBLIC_BUGS_ENV ||
  process.env.NODE_ENV ||
  "staging";

Sentry.init({
  dsn: SENTRY_DSN,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  // ...
  environment: `${SENTRY_ENV}-clientside`,
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
