import Link from "next/link";
import React, { useEffect, useState } from "react";
import {
  SystemSearchQuery,
  useSystemSearchLazyQuery,
} from "../../generated/graphql";
import { TinyLabel } from "../atoms";

export const MainHeaderSearchBar = () => {
  const [searchString, setSearchString] = useState("");
  const [results, setResults] = useState<SystemSearchQuery | undefined>();
  const [doSearch, { data, loading }] = useSystemSearchLazyQuery();

  useEffect(() => {
    if (searchString.length > 1) {
      doSearch({
        variables: {
          str: `${searchString}%`,
        },
      }).then(() => {
        setResults(data);
      });
    } else {
      setResults(undefined);
    }
  }, [searchString]);

  return (
    <div className="w-full relative">
      <input
        type="search"
        name=""
        id=""
        placeholder="Sök"
        className="w-full pl-3 text-sm text-black outline-none focus:outline-none bg-white rounded-full ml-2"
        value={searchString}
        onChange={(e) => setSearchString(e.target.value)}
      />
      {results && (
        <div className="left-8 right-4 top-9 bg-white text-black p-8 absolute shadow-2xl z-30 border border-gray-300 rounded-t-sm">
          {data?.organizations && data?.organizations.length > 0 && (
            <ul className="flex flex-col space-y-1 mb-4">
              <li className="text-sm text-gray-600">
                Organisationer <TinyLabel label="visar max 10" />
              </li>
              {data.organizations.map((org) => (
                <Link href={`/organizations/${org.id}`} key={org.id} passHref>
                  <a>
                    <li>
                      <span className="font-medium">{org.name}</span>
                      <span className="text-sm">
                        {" "}
                        - Org nr. {org.organization_number}
                      </span>
                    </li>
                  </a>
                </Link>
              ))}
            </ul>
          )}
          {data?.users && data?.users.length > 0 && (
            <ul className="flex flex-col space-y-1 mb-4">
              <li className="text-sm text-gray-600">
                Användare <TinyLabel label="visar max 10" />
              </li>
              {data.users.map((user) => (
                <Link href={`/users/${user.id}`} key={user.id} passHref>
                  <a>
                    <li key={user.id}>
                      <span className="font-medium">
                        {`${user.first_name} ${user.last_name}`}
                      </span>
                      <span className="text-sm">
                        {" "}
                        - Mob nr. {user.mobile_number}
                      </span>
                    </li>
                  </a>
                </Link>
              ))}
            </ul>
          )}
          {data?.organizations &&
            data.organizations.length < 1 &&
            data.users &&
            data.users.length < 1 && <div>Inga resultat</div>}
        </div>
      )}
    </div>
  );
};
