import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  numeric: any;
  timestamptz: any;
  uuid: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export type CreateOrderInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type CreateOrderOutput = {
  __typename?: 'CreateOrderOutput';
  accessToken: Scalars['String'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

export type NumbercheckOutput = {
  __typename?: 'NumbercheckOutput';
  exists: Scalars['Boolean'];
  id?: Maybe<Scalars['String']>;
};

export type OrgNumbercheckOutput = {
  __typename?: 'OrgNumbercheckOutput';
  exists: Scalars['Boolean'];
};

export type StartLoginOutput = {
  __typename?: 'StartLoginOutput';
  ok: Scalars['Boolean'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};

export type ValidateLoginOutput = {
  __typename?: 'ValidateLoginOutput';
  jwt?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** columns and relationships of "agreement_contacts" */
export type Agreement_Contacts = {
  __typename?: 'agreement_contacts';
  agreement_contact_email: Scalars['String'];
  agreement_contact_name: Scalars['String'];
  agreement_contact_phone: Scalars['String'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  id: Scalars['uuid'];
  organization_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['uuid'];
};

/** aggregated selection of "agreement_contacts" */
export type Agreement_Contacts_Aggregate = {
  __typename?: 'agreement_contacts_aggregate';
  aggregate?: Maybe<Agreement_Contacts_Aggregate_Fields>;
  nodes: Array<Agreement_Contacts>;
};

/** aggregate fields of "agreement_contacts" */
export type Agreement_Contacts_Aggregate_Fields = {
  __typename?: 'agreement_contacts_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Agreement_Contacts_Max_Fields>;
  min?: Maybe<Agreement_Contacts_Min_Fields>;
};


/** aggregate fields of "agreement_contacts" */
export type Agreement_Contacts_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Agreement_Contacts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "agreement_contacts" */
export type Agreement_Contacts_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Agreement_Contacts_Max_Order_By>;
  min?: Maybe<Agreement_Contacts_Min_Order_By>;
};

/** input type for inserting array relation for remote table "agreement_contacts" */
export type Agreement_Contacts_Arr_Rel_Insert_Input = {
  data: Array<Agreement_Contacts_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Agreement_Contacts_On_Conflict>;
};

/** Boolean expression to filter rows from the table "agreement_contacts". All fields are combined with a logical 'AND'. */
export type Agreement_Contacts_Bool_Exp = {
  _and?: Maybe<Array<Agreement_Contacts_Bool_Exp>>;
  _not?: Maybe<Agreement_Contacts_Bool_Exp>;
  _or?: Maybe<Array<Agreement_Contacts_Bool_Exp>>;
  agreement_contact_email?: Maybe<String_Comparison_Exp>;
  agreement_contact_name?: Maybe<String_Comparison_Exp>;
  agreement_contact_phone?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  organization_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_by?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "agreement_contacts" */
export enum Agreement_Contacts_Constraint {
  /** unique or primary key constraint */
  AgreementContactsPkey = 'agreement_contacts_pkey'
}

/** input type for inserting data into table "agreement_contacts" */
export type Agreement_Contacts_Insert_Input = {
  agreement_contact_email?: Maybe<Scalars['String']>;
  agreement_contact_name?: Maybe<Scalars['String']>;
  agreement_contact_phone?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Agreement_Contacts_Max_Fields = {
  __typename?: 'agreement_contacts_max_fields';
  agreement_contact_email?: Maybe<Scalars['String']>;
  agreement_contact_name?: Maybe<Scalars['String']>;
  agreement_contact_phone?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "agreement_contacts" */
export type Agreement_Contacts_Max_Order_By = {
  agreement_contact_email?: Maybe<Order_By>;
  agreement_contact_name?: Maybe<Order_By>;
  agreement_contact_phone?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Agreement_Contacts_Min_Fields = {
  __typename?: 'agreement_contacts_min_fields';
  agreement_contact_email?: Maybe<Scalars['String']>;
  agreement_contact_name?: Maybe<Scalars['String']>;
  agreement_contact_phone?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "agreement_contacts" */
export type Agreement_Contacts_Min_Order_By = {
  agreement_contact_email?: Maybe<Order_By>;
  agreement_contact_name?: Maybe<Order_By>;
  agreement_contact_phone?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
};

/** response of any mutation on the table "agreement_contacts" */
export type Agreement_Contacts_Mutation_Response = {
  __typename?: 'agreement_contacts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Agreement_Contacts>;
};

/** on conflict condition type for table "agreement_contacts" */
export type Agreement_Contacts_On_Conflict = {
  constraint: Agreement_Contacts_Constraint;
  update_columns?: Array<Agreement_Contacts_Update_Column>;
  where?: Maybe<Agreement_Contacts_Bool_Exp>;
};

/** Ordering options when selecting data from "agreement_contacts". */
export type Agreement_Contacts_Order_By = {
  agreement_contact_email?: Maybe<Order_By>;
  agreement_contact_name?: Maybe<Order_By>;
  agreement_contact_phone?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
};

/** primary key columns input for table: agreement_contacts */
export type Agreement_Contacts_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "agreement_contacts" */
export enum Agreement_Contacts_Select_Column {
  /** column name */
  AgreementContactEmail = 'agreement_contact_email',
  /** column name */
  AgreementContactName = 'agreement_contact_name',
  /** column name */
  AgreementContactPhone = 'agreement_contact_phone',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "agreement_contacts" */
export type Agreement_Contacts_Set_Input = {
  agreement_contact_email?: Maybe<Scalars['String']>;
  agreement_contact_name?: Maybe<Scalars['String']>;
  agreement_contact_phone?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** update columns of table "agreement_contacts" */
export enum Agreement_Contacts_Update_Column {
  /** column name */
  AgreementContactEmail = 'agreement_contact_email',
  /** column name */
  AgreementContactName = 'agreement_contact_name',
  /** column name */
  AgreementContactPhone = 'agreement_contact_phone',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** columns and relationships of "agreement_types" */
export type Agreement_Types = {
  __typename?: 'agreement_types';
  code: Scalars['String'];
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  getaccept_template_id?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  organizations: Array<Organizations>;
  /** An aggregate relationship */
  organizations_aggregate: Organizations_Aggregate;
  rate: Scalars['numeric'];
  updated_at: Scalars['timestamptz'];
  valid_num_days?: Maybe<Scalars['numeric']>;
};


/** columns and relationships of "agreement_types" */
export type Agreement_TypesOrganizationsArgs = {
  distinct_on?: Maybe<Array<Organizations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organizations_Order_By>>;
  where?: Maybe<Organizations_Bool_Exp>;
};


/** columns and relationships of "agreement_types" */
export type Agreement_TypesOrganizations_AggregateArgs = {
  distinct_on?: Maybe<Array<Organizations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organizations_Order_By>>;
  where?: Maybe<Organizations_Bool_Exp>;
};

/** aggregated selection of "agreement_types" */
export type Agreement_Types_Aggregate = {
  __typename?: 'agreement_types_aggregate';
  aggregate?: Maybe<Agreement_Types_Aggregate_Fields>;
  nodes: Array<Agreement_Types>;
};

/** aggregate fields of "agreement_types" */
export type Agreement_Types_Aggregate_Fields = {
  __typename?: 'agreement_types_aggregate_fields';
  avg?: Maybe<Agreement_Types_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Agreement_Types_Max_Fields>;
  min?: Maybe<Agreement_Types_Min_Fields>;
  stddev?: Maybe<Agreement_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Agreement_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Agreement_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Agreement_Types_Sum_Fields>;
  var_pop?: Maybe<Agreement_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Agreement_Types_Var_Samp_Fields>;
  variance?: Maybe<Agreement_Types_Variance_Fields>;
};


/** aggregate fields of "agreement_types" */
export type Agreement_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Agreement_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Agreement_Types_Avg_Fields = {
  __typename?: 'agreement_types_avg_fields';
  rate?: Maybe<Scalars['Float']>;
  valid_num_days?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "agreement_types". All fields are combined with a logical 'AND'. */
export type Agreement_Types_Bool_Exp = {
  _and?: Maybe<Array<Agreement_Types_Bool_Exp>>;
  _not?: Maybe<Agreement_Types_Bool_Exp>;
  _or?: Maybe<Array<Agreement_Types_Bool_Exp>>;
  code?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  getaccept_template_id?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  organizations?: Maybe<Organizations_Bool_Exp>;
  rate?: Maybe<Numeric_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  valid_num_days?: Maybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "agreement_types" */
export enum Agreement_Types_Constraint {
  /** unique or primary key constraint */
  AgreementTypesCodeKey = 'agreement_types_code_key',
  /** unique or primary key constraint */
  AgreementTypesPkey = 'agreement_types_pkey'
}

/** input type for incrementing numeric columns in table "agreement_types" */
export type Agreement_Types_Inc_Input = {
  rate?: Maybe<Scalars['numeric']>;
  valid_num_days?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "agreement_types" */
export type Agreement_Types_Insert_Input = {
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  getaccept_template_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organizations?: Maybe<Organizations_Arr_Rel_Insert_Input>;
  rate?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  valid_num_days?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Agreement_Types_Max_Fields = {
  __typename?: 'agreement_types_max_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  getaccept_template_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  valid_num_days?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Agreement_Types_Min_Fields = {
  __typename?: 'agreement_types_min_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  getaccept_template_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  valid_num_days?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "agreement_types" */
export type Agreement_Types_Mutation_Response = {
  __typename?: 'agreement_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Agreement_Types>;
};

/** input type for inserting object relation for remote table "agreement_types" */
export type Agreement_Types_Obj_Rel_Insert_Input = {
  data: Agreement_Types_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Agreement_Types_On_Conflict>;
};

/** on conflict condition type for table "agreement_types" */
export type Agreement_Types_On_Conflict = {
  constraint: Agreement_Types_Constraint;
  update_columns?: Array<Agreement_Types_Update_Column>;
  where?: Maybe<Agreement_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "agreement_types". */
export type Agreement_Types_Order_By = {
  code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  getaccept_template_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organizations_aggregate?: Maybe<Organizations_Aggregate_Order_By>;
  rate?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  valid_num_days?: Maybe<Order_By>;
};

/** primary key columns input for table: agreement_types */
export type Agreement_Types_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "agreement_types" */
export enum Agreement_Types_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  GetacceptTemplateId = 'getaccept_template_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Rate = 'rate',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValidNumDays = 'valid_num_days'
}

/** input type for updating data in table "agreement_types" */
export type Agreement_Types_Set_Input = {
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  getaccept_template_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  valid_num_days?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Agreement_Types_Stddev_Fields = {
  __typename?: 'agreement_types_stddev_fields';
  rate?: Maybe<Scalars['Float']>;
  valid_num_days?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Agreement_Types_Stddev_Pop_Fields = {
  __typename?: 'agreement_types_stddev_pop_fields';
  rate?: Maybe<Scalars['Float']>;
  valid_num_days?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Agreement_Types_Stddev_Samp_Fields = {
  __typename?: 'agreement_types_stddev_samp_fields';
  rate?: Maybe<Scalars['Float']>;
  valid_num_days?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Agreement_Types_Sum_Fields = {
  __typename?: 'agreement_types_sum_fields';
  rate?: Maybe<Scalars['numeric']>;
  valid_num_days?: Maybe<Scalars['numeric']>;
};

/** update columns of table "agreement_types" */
export enum Agreement_Types_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  GetacceptTemplateId = 'getaccept_template_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Rate = 'rate',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValidNumDays = 'valid_num_days'
}

/** aggregate var_pop on columns */
export type Agreement_Types_Var_Pop_Fields = {
  __typename?: 'agreement_types_var_pop_fields';
  rate?: Maybe<Scalars['Float']>;
  valid_num_days?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Agreement_Types_Var_Samp_Fields = {
  __typename?: 'agreement_types_var_samp_fields';
  rate?: Maybe<Scalars['Float']>;
  valid_num_days?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Agreement_Types_Variance_Fields = {
  __typename?: 'agreement_types_variance_fields';
  rate?: Maybe<Scalars['Float']>;
  valid_num_days?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "categories" */
export type Categories = {
  __typename?: 'categories';
  active: Scalars['Boolean'];
  color?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  icon: Scalars['String'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid'];
  parent_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  pointofsale: Pointofsales;
  pointofsale_id: Scalars['uuid'];
  /** An array relationship */
  products: Array<Products>;
  /** An aggregate relationship */
  products_aggregate: Products_Aggregate;
  sortorder?: Maybe<Scalars['numeric']>;
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "categories" */
export type CategoriesProductsArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


/** columns and relationships of "categories" */
export type CategoriesProducts_AggregateArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};

/** aggregated selection of "categories" */
export type Categories_Aggregate = {
  __typename?: 'categories_aggregate';
  aggregate?: Maybe<Categories_Aggregate_Fields>;
  nodes: Array<Categories>;
};

/** aggregate fields of "categories" */
export type Categories_Aggregate_Fields = {
  __typename?: 'categories_aggregate_fields';
  avg?: Maybe<Categories_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Categories_Max_Fields>;
  min?: Maybe<Categories_Min_Fields>;
  stddev?: Maybe<Categories_Stddev_Fields>;
  stddev_pop?: Maybe<Categories_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Categories_Stddev_Samp_Fields>;
  sum?: Maybe<Categories_Sum_Fields>;
  var_pop?: Maybe<Categories_Var_Pop_Fields>;
  var_samp?: Maybe<Categories_Var_Samp_Fields>;
  variance?: Maybe<Categories_Variance_Fields>;
};


/** aggregate fields of "categories" */
export type Categories_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Categories_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "categories" */
export type Categories_Aggregate_Order_By = {
  avg?: Maybe<Categories_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Categories_Max_Order_By>;
  min?: Maybe<Categories_Min_Order_By>;
  stddev?: Maybe<Categories_Stddev_Order_By>;
  stddev_pop?: Maybe<Categories_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Categories_Stddev_Samp_Order_By>;
  sum?: Maybe<Categories_Sum_Order_By>;
  var_pop?: Maybe<Categories_Var_Pop_Order_By>;
  var_samp?: Maybe<Categories_Var_Samp_Order_By>;
  variance?: Maybe<Categories_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "categories" */
export type Categories_Arr_Rel_Insert_Input = {
  data: Array<Categories_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Categories_On_Conflict>;
};

/** aggregate avg on columns */
export type Categories_Avg_Fields = {
  __typename?: 'categories_avg_fields';
  sortorder?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "categories" */
export type Categories_Avg_Order_By = {
  sortorder?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "categories". All fields are combined with a logical 'AND'. */
export type Categories_Bool_Exp = {
  _and?: Maybe<Array<Categories_Bool_Exp>>;
  _not?: Maybe<Categories_Bool_Exp>;
  _or?: Maybe<Array<Categories_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  color?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Uuid_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_by?: Maybe<Uuid_Comparison_Exp>;
  icon?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  organization?: Maybe<Organizations_Bool_Exp>;
  organization_id?: Maybe<Uuid_Comparison_Exp>;
  parent_id?: Maybe<Uuid_Comparison_Exp>;
  pointofsale?: Maybe<Pointofsales_Bool_Exp>;
  pointofsale_id?: Maybe<Uuid_Comparison_Exp>;
  products?: Maybe<Products_Bool_Exp>;
  sortorder?: Maybe<Numeric_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_by?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "categories" */
export enum Categories_Constraint {
  /** unique or primary key constraint */
  CategoriesPkey = 'categories_pkey'
}

/** input type for incrementing numeric columns in table "categories" */
export type Categories_Inc_Input = {
  sortorder?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "categories" */
export type Categories_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: Maybe<Scalars['uuid']>;
  parent_id?: Maybe<Scalars['uuid']>;
  pointofsale?: Maybe<Pointofsales_Obj_Rel_Insert_Input>;
  pointofsale_id?: Maybe<Scalars['uuid']>;
  products?: Maybe<Products_Arr_Rel_Insert_Input>;
  sortorder?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Categories_Max_Fields = {
  __typename?: 'categories_max_fields';
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  parent_id?: Maybe<Scalars['uuid']>;
  pointofsale_id?: Maybe<Scalars['uuid']>;
  sortorder?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "categories" */
export type Categories_Max_Order_By = {
  color?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  deleted_by?: Maybe<Order_By>;
  icon?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  parent_id?: Maybe<Order_By>;
  pointofsale_id?: Maybe<Order_By>;
  sortorder?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Categories_Min_Fields = {
  __typename?: 'categories_min_fields';
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  parent_id?: Maybe<Scalars['uuid']>;
  pointofsale_id?: Maybe<Scalars['uuid']>;
  sortorder?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "categories" */
export type Categories_Min_Order_By = {
  color?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  deleted_by?: Maybe<Order_By>;
  icon?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  parent_id?: Maybe<Order_By>;
  pointofsale_id?: Maybe<Order_By>;
  sortorder?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
};

/** response of any mutation on the table "categories" */
export type Categories_Mutation_Response = {
  __typename?: 'categories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Categories>;
};

/** input type for inserting object relation for remote table "categories" */
export type Categories_Obj_Rel_Insert_Input = {
  data: Categories_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Categories_On_Conflict>;
};

/** on conflict condition type for table "categories" */
export type Categories_On_Conflict = {
  constraint: Categories_Constraint;
  update_columns?: Array<Categories_Update_Column>;
  where?: Maybe<Categories_Bool_Exp>;
};

/** Ordering options when selecting data from "categories". */
export type Categories_Order_By = {
  active?: Maybe<Order_By>;
  color?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  deleted_by?: Maybe<Order_By>;
  icon?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization?: Maybe<Organizations_Order_By>;
  organization_id?: Maybe<Order_By>;
  parent_id?: Maybe<Order_By>;
  pointofsale?: Maybe<Pointofsales_Order_By>;
  pointofsale_id?: Maybe<Order_By>;
  products_aggregate?: Maybe<Products_Aggregate_Order_By>;
  sortorder?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
};

/** primary key columns input for table: categories */
export type Categories_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "categories" */
export enum Categories_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  PointofsaleId = 'pointofsale_id',
  /** column name */
  Sortorder = 'sortorder',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "categories" */
export type Categories_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  parent_id?: Maybe<Scalars['uuid']>;
  pointofsale_id?: Maybe<Scalars['uuid']>;
  sortorder?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Categories_Stddev_Fields = {
  __typename?: 'categories_stddev_fields';
  sortorder?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "categories" */
export type Categories_Stddev_Order_By = {
  sortorder?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Categories_Stddev_Pop_Fields = {
  __typename?: 'categories_stddev_pop_fields';
  sortorder?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "categories" */
export type Categories_Stddev_Pop_Order_By = {
  sortorder?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Categories_Stddev_Samp_Fields = {
  __typename?: 'categories_stddev_samp_fields';
  sortorder?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "categories" */
export type Categories_Stddev_Samp_Order_By = {
  sortorder?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Categories_Sum_Fields = {
  __typename?: 'categories_sum_fields';
  sortorder?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "categories" */
export type Categories_Sum_Order_By = {
  sortorder?: Maybe<Order_By>;
};

/** update columns of table "categories" */
export enum Categories_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  PointofsaleId = 'pointofsale_id',
  /** column name */
  Sortorder = 'sortorder',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate var_pop on columns */
export type Categories_Var_Pop_Fields = {
  __typename?: 'categories_var_pop_fields';
  sortorder?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "categories" */
export type Categories_Var_Pop_Order_By = {
  sortorder?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Categories_Var_Samp_Fields = {
  __typename?: 'categories_var_samp_fields';
  sortorder?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "categories" */
export type Categories_Var_Samp_Order_By = {
  sortorder?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Categories_Variance_Fields = {
  __typename?: 'categories_variance_fields';
  sortorder?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "categories" */
export type Categories_Variance_Order_By = {
  sortorder?: Maybe<Order_By>;
};

/** columns and relationships of "groups" */
export type Groups = {
  __typename?: 'groups';
  active: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  demomode: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid'];
  /** An array relationship */
  organization_users: Array<Organization_Users>;
  /** An aggregate relationship */
  organization_users_aggregate: Organization_Users_Aggregate;
  parent_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  paymentmethods: Array<Paymentmethods>;
  /** An aggregate relationship */
  paymentmethods_aggregate: Paymentmethods_Aggregate;
  /** fetch data from the table: "pointofsales" */
  pointofsales: Array<Pointofsales>;
  /** An aggregate relationship */
  pointofsales_aggregate: Pointofsales_Aggregate;
  responsible_id?: Maybe<Scalars['uuid']>;
  sortorder?: Maybe<Scalars['Int']>;
  systemmanaged: Scalars['Boolean'];
  tags?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "groups" */
export type GroupsOrganization_UsersArgs = {
  distinct_on?: Maybe<Array<Organization_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organization_Users_Order_By>>;
  where?: Maybe<Organization_Users_Bool_Exp>;
};


/** columns and relationships of "groups" */
export type GroupsOrganization_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Organization_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organization_Users_Order_By>>;
  where?: Maybe<Organization_Users_Bool_Exp>;
};


/** columns and relationships of "groups" */
export type GroupsPaymentmethodsArgs = {
  distinct_on?: Maybe<Array<Paymentmethods_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Paymentmethods_Order_By>>;
  where?: Maybe<Paymentmethods_Bool_Exp>;
};


/** columns and relationships of "groups" */
export type GroupsPaymentmethods_AggregateArgs = {
  distinct_on?: Maybe<Array<Paymentmethods_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Paymentmethods_Order_By>>;
  where?: Maybe<Paymentmethods_Bool_Exp>;
};


/** columns and relationships of "groups" */
export type GroupsPointofsalesArgs = {
  distinct_on?: Maybe<Array<Pointofsales_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pointofsales_Order_By>>;
  where?: Maybe<Pointofsales_Bool_Exp>;
};


/** columns and relationships of "groups" */
export type GroupsPointofsales_AggregateArgs = {
  distinct_on?: Maybe<Array<Pointofsales_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pointofsales_Order_By>>;
  where?: Maybe<Pointofsales_Bool_Exp>;
};

/** aggregated selection of "groups" */
export type Groups_Aggregate = {
  __typename?: 'groups_aggregate';
  aggregate?: Maybe<Groups_Aggregate_Fields>;
  nodes: Array<Groups>;
};

/** aggregate fields of "groups" */
export type Groups_Aggregate_Fields = {
  __typename?: 'groups_aggregate_fields';
  avg?: Maybe<Groups_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Groups_Max_Fields>;
  min?: Maybe<Groups_Min_Fields>;
  stddev?: Maybe<Groups_Stddev_Fields>;
  stddev_pop?: Maybe<Groups_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Groups_Stddev_Samp_Fields>;
  sum?: Maybe<Groups_Sum_Fields>;
  var_pop?: Maybe<Groups_Var_Pop_Fields>;
  var_samp?: Maybe<Groups_Var_Samp_Fields>;
  variance?: Maybe<Groups_Variance_Fields>;
};


/** aggregate fields of "groups" */
export type Groups_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Groups_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "groups" */
export type Groups_Aggregate_Order_By = {
  avg?: Maybe<Groups_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Groups_Max_Order_By>;
  min?: Maybe<Groups_Min_Order_By>;
  stddev?: Maybe<Groups_Stddev_Order_By>;
  stddev_pop?: Maybe<Groups_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Groups_Stddev_Samp_Order_By>;
  sum?: Maybe<Groups_Sum_Order_By>;
  var_pop?: Maybe<Groups_Var_Pop_Order_By>;
  var_samp?: Maybe<Groups_Var_Samp_Order_By>;
  variance?: Maybe<Groups_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "groups" */
export type Groups_Arr_Rel_Insert_Input = {
  data: Array<Groups_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Groups_On_Conflict>;
};

/** aggregate avg on columns */
export type Groups_Avg_Fields = {
  __typename?: 'groups_avg_fields';
  sortorder?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "groups" */
export type Groups_Avg_Order_By = {
  sortorder?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "groups". All fields are combined with a logical 'AND'. */
export type Groups_Bool_Exp = {
  _and?: Maybe<Array<Groups_Bool_Exp>>;
  _not?: Maybe<Groups_Bool_Exp>;
  _or?: Maybe<Array<Groups_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Uuid_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_by?: Maybe<Uuid_Comparison_Exp>;
  demomode?: Maybe<Boolean_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  organization?: Maybe<Organizations_Bool_Exp>;
  organization_id?: Maybe<Uuid_Comparison_Exp>;
  organization_users?: Maybe<Organization_Users_Bool_Exp>;
  parent_id?: Maybe<Uuid_Comparison_Exp>;
  paymentmethods?: Maybe<Paymentmethods_Bool_Exp>;
  pointofsales?: Maybe<Pointofsales_Bool_Exp>;
  responsible_id?: Maybe<Uuid_Comparison_Exp>;
  sortorder?: Maybe<Int_Comparison_Exp>;
  systemmanaged?: Maybe<Boolean_Comparison_Exp>;
  tags?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_by?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "groups" */
export enum Groups_Constraint {
  /** unique or primary key constraint */
  GroupsPkey = 'groups_pkey'
}

/** input type for incrementing numeric columns in table "groups" */
export type Groups_Inc_Input = {
  sortorder?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "groups" */
export type Groups_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  demomode?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: Maybe<Scalars['uuid']>;
  organization_users?: Maybe<Organization_Users_Arr_Rel_Insert_Input>;
  parent_id?: Maybe<Scalars['uuid']>;
  paymentmethods?: Maybe<Paymentmethods_Arr_Rel_Insert_Input>;
  pointofsales?: Maybe<Pointofsales_Arr_Rel_Insert_Input>;
  responsible_id?: Maybe<Scalars['uuid']>;
  sortorder?: Maybe<Scalars['Int']>;
  systemmanaged?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Groups_Max_Fields = {
  __typename?: 'groups_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  parent_id?: Maybe<Scalars['uuid']>;
  responsible_id?: Maybe<Scalars['uuid']>;
  sortorder?: Maybe<Scalars['Int']>;
  tags?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "groups" */
export type Groups_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  deleted_by?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  parent_id?: Maybe<Order_By>;
  responsible_id?: Maybe<Order_By>;
  sortorder?: Maybe<Order_By>;
  tags?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Groups_Min_Fields = {
  __typename?: 'groups_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  parent_id?: Maybe<Scalars['uuid']>;
  responsible_id?: Maybe<Scalars['uuid']>;
  sortorder?: Maybe<Scalars['Int']>;
  tags?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "groups" */
export type Groups_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  deleted_by?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  parent_id?: Maybe<Order_By>;
  responsible_id?: Maybe<Order_By>;
  sortorder?: Maybe<Order_By>;
  tags?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
};

/** response of any mutation on the table "groups" */
export type Groups_Mutation_Response = {
  __typename?: 'groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Groups>;
};

/** input type for inserting object relation for remote table "groups" */
export type Groups_Obj_Rel_Insert_Input = {
  data: Groups_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Groups_On_Conflict>;
};

/** on conflict condition type for table "groups" */
export type Groups_On_Conflict = {
  constraint: Groups_Constraint;
  update_columns?: Array<Groups_Update_Column>;
  where?: Maybe<Groups_Bool_Exp>;
};

/** Ordering options when selecting data from "groups". */
export type Groups_Order_By = {
  active?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  deleted_by?: Maybe<Order_By>;
  demomode?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization?: Maybe<Organizations_Order_By>;
  organization_id?: Maybe<Order_By>;
  organization_users_aggregate?: Maybe<Organization_Users_Aggregate_Order_By>;
  parent_id?: Maybe<Order_By>;
  paymentmethods_aggregate?: Maybe<Paymentmethods_Aggregate_Order_By>;
  pointofsales_aggregate?: Maybe<Pointofsales_Aggregate_Order_By>;
  responsible_id?: Maybe<Order_By>;
  sortorder?: Maybe<Order_By>;
  systemmanaged?: Maybe<Order_By>;
  tags?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
};

/** primary key columns input for table: groups */
export type Groups_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "groups" */
export enum Groups_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Demomode = 'demomode',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  ResponsibleId = 'responsible_id',
  /** column name */
  Sortorder = 'sortorder',
  /** column name */
  Systemmanaged = 'systemmanaged',
  /** column name */
  Tags = 'tags',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "groups" */
export type Groups_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  demomode?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  parent_id?: Maybe<Scalars['uuid']>;
  responsible_id?: Maybe<Scalars['uuid']>;
  sortorder?: Maybe<Scalars['Int']>;
  systemmanaged?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Groups_Stddev_Fields = {
  __typename?: 'groups_stddev_fields';
  sortorder?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "groups" */
export type Groups_Stddev_Order_By = {
  sortorder?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Groups_Stddev_Pop_Fields = {
  __typename?: 'groups_stddev_pop_fields';
  sortorder?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "groups" */
export type Groups_Stddev_Pop_Order_By = {
  sortorder?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Groups_Stddev_Samp_Fields = {
  __typename?: 'groups_stddev_samp_fields';
  sortorder?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "groups" */
export type Groups_Stddev_Samp_Order_By = {
  sortorder?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Groups_Sum_Fields = {
  __typename?: 'groups_sum_fields';
  sortorder?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "groups" */
export type Groups_Sum_Order_By = {
  sortorder?: Maybe<Order_By>;
};

/** update columns of table "groups" */
export enum Groups_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Demomode = 'demomode',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  ResponsibleId = 'responsible_id',
  /** column name */
  Sortorder = 'sortorder',
  /** column name */
  Systemmanaged = 'systemmanaged',
  /** column name */
  Tags = 'tags',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate var_pop on columns */
export type Groups_Var_Pop_Fields = {
  __typename?: 'groups_var_pop_fields';
  sortorder?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "groups" */
export type Groups_Var_Pop_Order_By = {
  sortorder?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Groups_Var_Samp_Fields = {
  __typename?: 'groups_var_samp_fields';
  sortorder?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "groups" */
export type Groups_Var_Samp_Order_By = {
  sortorder?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Groups_Variance_Fields = {
  __typename?: 'groups_variance_fields';
  sortorder?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "groups" */
export type Groups_Variance_Order_By = {
  sortorder?: Maybe<Order_By>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  createOrder?: Maybe<CreateOrderOutput>;
  /** delete data from the table: "agreement_contacts" */
  delete_agreement_contacts?: Maybe<Agreement_Contacts_Mutation_Response>;
  /** delete single row from the table: "agreement_contacts" */
  delete_agreement_contacts_by_pk?: Maybe<Agreement_Contacts>;
  /** delete data from the table: "agreement_types" */
  delete_agreement_types?: Maybe<Agreement_Types_Mutation_Response>;
  /** delete single row from the table: "agreement_types" */
  delete_agreement_types_by_pk?: Maybe<Agreement_Types>;
  /** delete data from the table: "categories" */
  delete_categories?: Maybe<Categories_Mutation_Response>;
  /** delete single row from the table: "categories" */
  delete_categories_by_pk?: Maybe<Categories>;
  /** delete data from the table: "groups" */
  delete_groups?: Maybe<Groups_Mutation_Response>;
  /** delete single row from the table: "groups" */
  delete_groups_by_pk?: Maybe<Groups>;
  /** delete data from the table: "order_session_connected_users" */
  delete_order_session_connected_users?: Maybe<Order_Session_Connected_Users_Mutation_Response>;
  /** delete single row from the table: "order_session_connected_users" */
  delete_order_session_connected_users_by_pk?: Maybe<Order_Session_Connected_Users>;
  /** delete data from the table: "order_session_groups_responsible" */
  delete_order_session_groups_responsible?: Maybe<Order_Session_Groups_Responsible_Mutation_Response>;
  /** delete single row from the table: "order_session_groups_responsible" */
  delete_order_session_groups_responsible_by_pk?: Maybe<Order_Session_Groups_Responsible>;
  /** delete data from the table: "order_session_payment_methods" */
  delete_order_session_payment_methods?: Maybe<Order_Session_Payment_Methods_Mutation_Response>;
  /** delete single row from the table: "order_session_payment_methods" */
  delete_order_session_payment_methods_by_pk?: Maybe<Order_Session_Payment_Methods>;
  /** delete data from the table: "order_sessions" */
  delete_order_sessions?: Maybe<Order_Sessions_Mutation_Response>;
  /** delete single row from the table: "order_sessions" */
  delete_order_sessions_by_pk?: Maybe<Order_Sessions>;
  /** delete data from the table: "order_zreports" */
  delete_order_zreports?: Maybe<Order_Zreports_Mutation_Response>;
  /** delete single row from the table: "order_zreports" */
  delete_order_zreports_by_pk?: Maybe<Order_Zreports>;
  /** delete data from the table: "orders" */
  delete_orders?: Maybe<Orders_Mutation_Response>;
  /** delete single row from the table: "orders" */
  delete_orders_by_pk?: Maybe<Orders>;
  /** delete data from the table: "organization_users" */
  delete_organization_users?: Maybe<Organization_Users_Mutation_Response>;
  /** delete single row from the table: "organization_users" */
  delete_organization_users_by_pk?: Maybe<Organization_Users>;
  /** delete data from the table: "organizations" */
  delete_organizations?: Maybe<Organizations_Mutation_Response>;
  /** delete single row from the table: "organizations" */
  delete_organizations_by_pk?: Maybe<Organizations>;
  /** delete data from the table: "participants" */
  delete_participants?: Maybe<Participants_Mutation_Response>;
  /** delete single row from the table: "participants" */
  delete_participants_by_pk?: Maybe<Participants>;
  /** delete data from the table: "paymentmethods" */
  delete_paymentmethods?: Maybe<Paymentmethods_Mutation_Response>;
  /** delete single row from the table: "paymentmethods" */
  delete_paymentmethods_by_pk?: Maybe<Paymentmethods>;
  /** delete data from the table: "pointofsales" */
  delete_pointofsales?: Maybe<Pointofsales_Mutation_Response>;
  /** delete single row from the table: "pointofsales" */
  delete_pointofsales_by_pk?: Maybe<Pointofsales>;
  /** delete data from the table: "products" */
  delete_products?: Maybe<Products_Mutation_Response>;
  /** delete single row from the table: "products" */
  delete_products_by_pk?: Maybe<Products>;
  /** delete data from the table: "refreshtoken" */
  delete_refreshtoken?: Maybe<Refreshtoken_Mutation_Response>;
  /** delete single row from the table: "refreshtoken" */
  delete_refreshtoken_by_pk?: Maybe<Refreshtoken>;
  /** delete data from the table: "tags" */
  delete_tags?: Maybe<Tags_Mutation_Response>;
  /** delete single row from the table: "tags" */
  delete_tags_by_pk?: Maybe<Tags>;
  /** delete data from the table: "templates" */
  delete_templates?: Maybe<Templates_Mutation_Response>;
  /** delete single row from the table: "templates" */
  delete_templates_by_pk?: Maybe<Templates>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** delete data from the table: "vatrates" */
  delete_vatrates?: Maybe<Vatrates_Mutation_Response>;
  /** delete single row from the table: "vatrates" */
  delete_vatrates_by_pk?: Maybe<Vatrates>;
  /** insert data into the table: "agreement_contacts" */
  insert_agreement_contacts?: Maybe<Agreement_Contacts_Mutation_Response>;
  /** insert a single row into the table: "agreement_contacts" */
  insert_agreement_contacts_one?: Maybe<Agreement_Contacts>;
  /** insert data into the table: "agreement_types" */
  insert_agreement_types?: Maybe<Agreement_Types_Mutation_Response>;
  /** insert a single row into the table: "agreement_types" */
  insert_agreement_types_one?: Maybe<Agreement_Types>;
  /** insert data into the table: "categories" */
  insert_categories?: Maybe<Categories_Mutation_Response>;
  /** insert a single row into the table: "categories" */
  insert_categories_one?: Maybe<Categories>;
  /** insert data into the table: "groups" */
  insert_groups?: Maybe<Groups_Mutation_Response>;
  /** insert a single row into the table: "groups" */
  insert_groups_one?: Maybe<Groups>;
  /** insert data into the table: "order_session_connected_users" */
  insert_order_session_connected_users?: Maybe<Order_Session_Connected_Users_Mutation_Response>;
  /** insert a single row into the table: "order_session_connected_users" */
  insert_order_session_connected_users_one?: Maybe<Order_Session_Connected_Users>;
  /** insert data into the table: "order_session_groups_responsible" */
  insert_order_session_groups_responsible?: Maybe<Order_Session_Groups_Responsible_Mutation_Response>;
  /** insert a single row into the table: "order_session_groups_responsible" */
  insert_order_session_groups_responsible_one?: Maybe<Order_Session_Groups_Responsible>;
  /** insert data into the table: "order_session_payment_methods" */
  insert_order_session_payment_methods?: Maybe<Order_Session_Payment_Methods_Mutation_Response>;
  /** insert a single row into the table: "order_session_payment_methods" */
  insert_order_session_payment_methods_one?: Maybe<Order_Session_Payment_Methods>;
  /** insert data into the table: "order_sessions" */
  insert_order_sessions?: Maybe<Order_Sessions_Mutation_Response>;
  /** insert a single row into the table: "order_sessions" */
  insert_order_sessions_one?: Maybe<Order_Sessions>;
  /** insert data into the table: "order_zreports" */
  insert_order_zreports?: Maybe<Order_Zreports_Mutation_Response>;
  /** insert a single row into the table: "order_zreports" */
  insert_order_zreports_one?: Maybe<Order_Zreports>;
  /** insert data into the table: "orders" */
  insert_orders?: Maybe<Orders_Mutation_Response>;
  /** insert a single row into the table: "orders" */
  insert_orders_one?: Maybe<Orders>;
  /** insert data into the table: "organization_users" */
  insert_organization_users?: Maybe<Organization_Users_Mutation_Response>;
  /** insert a single row into the table: "organization_users" */
  insert_organization_users_one?: Maybe<Organization_Users>;
  /** insert data into the table: "organizations" */
  insert_organizations?: Maybe<Organizations_Mutation_Response>;
  /** insert a single row into the table: "organizations" */
  insert_organizations_one?: Maybe<Organizations>;
  /** insert data into the table: "participants" */
  insert_participants?: Maybe<Participants_Mutation_Response>;
  /** insert a single row into the table: "participants" */
  insert_participants_one?: Maybe<Participants>;
  /** insert data into the table: "paymentmethods" */
  insert_paymentmethods?: Maybe<Paymentmethods_Mutation_Response>;
  /** insert a single row into the table: "paymentmethods" */
  insert_paymentmethods_one?: Maybe<Paymentmethods>;
  /** insert data into the table: "pointofsales" */
  insert_pointofsales?: Maybe<Pointofsales_Mutation_Response>;
  /** insert a single row into the table: "pointofsales" */
  insert_pointofsales_one?: Maybe<Pointofsales>;
  /** insert data into the table: "products" */
  insert_products?: Maybe<Products_Mutation_Response>;
  /** insert a single row into the table: "products" */
  insert_products_one?: Maybe<Products>;
  /** insert data into the table: "refreshtoken" */
  insert_refreshtoken?: Maybe<Refreshtoken_Mutation_Response>;
  /** insert a single row into the table: "refreshtoken" */
  insert_refreshtoken_one?: Maybe<Refreshtoken>;
  /** insert data into the table: "tags" */
  insert_tags?: Maybe<Tags_Mutation_Response>;
  /** insert a single row into the table: "tags" */
  insert_tags_one?: Maybe<Tags>;
  /** insert data into the table: "templates" */
  insert_templates?: Maybe<Templates_Mutation_Response>;
  /** insert a single row into the table: "templates" */
  insert_templates_one?: Maybe<Templates>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** insert data into the table: "vatrates" */
  insert_vatrates?: Maybe<Vatrates_Mutation_Response>;
  /** insert a single row into the table: "vatrates" */
  insert_vatrates_one?: Maybe<Vatrates>;
  startLogin?: Maybe<StartLoginOutput>;
  /** update data of the table: "agreement_contacts" */
  update_agreement_contacts?: Maybe<Agreement_Contacts_Mutation_Response>;
  /** update single row of the table: "agreement_contacts" */
  update_agreement_contacts_by_pk?: Maybe<Agreement_Contacts>;
  /** update data of the table: "agreement_types" */
  update_agreement_types?: Maybe<Agreement_Types_Mutation_Response>;
  /** update single row of the table: "agreement_types" */
  update_agreement_types_by_pk?: Maybe<Agreement_Types>;
  /** update data of the table: "categories" */
  update_categories?: Maybe<Categories_Mutation_Response>;
  /** update single row of the table: "categories" */
  update_categories_by_pk?: Maybe<Categories>;
  /** update data of the table: "groups" */
  update_groups?: Maybe<Groups_Mutation_Response>;
  /** update single row of the table: "groups" */
  update_groups_by_pk?: Maybe<Groups>;
  /** update data of the table: "order_session_connected_users" */
  update_order_session_connected_users?: Maybe<Order_Session_Connected_Users_Mutation_Response>;
  /** update single row of the table: "order_session_connected_users" */
  update_order_session_connected_users_by_pk?: Maybe<Order_Session_Connected_Users>;
  /** update data of the table: "order_session_groups_responsible" */
  update_order_session_groups_responsible?: Maybe<Order_Session_Groups_Responsible_Mutation_Response>;
  /** update single row of the table: "order_session_groups_responsible" */
  update_order_session_groups_responsible_by_pk?: Maybe<Order_Session_Groups_Responsible>;
  /** update data of the table: "order_session_payment_methods" */
  update_order_session_payment_methods?: Maybe<Order_Session_Payment_Methods_Mutation_Response>;
  /** update single row of the table: "order_session_payment_methods" */
  update_order_session_payment_methods_by_pk?: Maybe<Order_Session_Payment_Methods>;
  /** update data of the table: "order_sessions" */
  update_order_sessions?: Maybe<Order_Sessions_Mutation_Response>;
  /** update single row of the table: "order_sessions" */
  update_order_sessions_by_pk?: Maybe<Order_Sessions>;
  /** update data of the table: "order_zreports" */
  update_order_zreports?: Maybe<Order_Zreports_Mutation_Response>;
  /** update single row of the table: "order_zreports" */
  update_order_zreports_by_pk?: Maybe<Order_Zreports>;
  /** update data of the table: "orders" */
  update_orders?: Maybe<Orders_Mutation_Response>;
  /** update single row of the table: "orders" */
  update_orders_by_pk?: Maybe<Orders>;
  /** update data of the table: "organization_users" */
  update_organization_users?: Maybe<Organization_Users_Mutation_Response>;
  /** update single row of the table: "organization_users" */
  update_organization_users_by_pk?: Maybe<Organization_Users>;
  /** update data of the table: "organizations" */
  update_organizations?: Maybe<Organizations_Mutation_Response>;
  /** update single row of the table: "organizations" */
  update_organizations_by_pk?: Maybe<Organizations>;
  /** update data of the table: "participants" */
  update_participants?: Maybe<Participants_Mutation_Response>;
  /** update single row of the table: "participants" */
  update_participants_by_pk?: Maybe<Participants>;
  /** update data of the table: "paymentmethods" */
  update_paymentmethods?: Maybe<Paymentmethods_Mutation_Response>;
  /** update single row of the table: "paymentmethods" */
  update_paymentmethods_by_pk?: Maybe<Paymentmethods>;
  /** update data of the table: "pointofsales" */
  update_pointofsales?: Maybe<Pointofsales_Mutation_Response>;
  /** update single row of the table: "pointofsales" */
  update_pointofsales_by_pk?: Maybe<Pointofsales>;
  /** update data of the table: "products" */
  update_products?: Maybe<Products_Mutation_Response>;
  /** update single row of the table: "products" */
  update_products_by_pk?: Maybe<Products>;
  /** update data of the table: "refreshtoken" */
  update_refreshtoken?: Maybe<Refreshtoken_Mutation_Response>;
  /** update single row of the table: "refreshtoken" */
  update_refreshtoken_by_pk?: Maybe<Refreshtoken>;
  /** update data of the table: "tags" */
  update_tags?: Maybe<Tags_Mutation_Response>;
  /** update single row of the table: "tags" */
  update_tags_by_pk?: Maybe<Tags>;
  /** update data of the table: "templates" */
  update_templates?: Maybe<Templates_Mutation_Response>;
  /** update single row of the table: "templates" */
  update_templates_by_pk?: Maybe<Templates>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update data of the table: "vatrates" */
  update_vatrates?: Maybe<Vatrates_Mutation_Response>;
  /** update single row of the table: "vatrates" */
  update_vatrates_by_pk?: Maybe<Vatrates>;
  validateLogin?: Maybe<ValidateLoginOutput>;
};


/** mutation root */
export type Mutation_RootCreateOrderArgs = {
  data: CreateOrderInput;
};


/** mutation root */
export type Mutation_RootDelete_Agreement_ContactsArgs = {
  where: Agreement_Contacts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Agreement_Contacts_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Agreement_TypesArgs = {
  where: Agreement_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Agreement_Types_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CategoriesArgs = {
  where: Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Categories_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_GroupsArgs = {
  where: Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Groups_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Order_Session_Connected_UsersArgs = {
  where: Order_Session_Connected_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_Session_Connected_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Order_Session_Groups_ResponsibleArgs = {
  where: Order_Session_Groups_Responsible_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_Session_Groups_Responsible_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Order_Session_Payment_MethodsArgs = {
  where: Order_Session_Payment_Methods_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_Session_Payment_Methods_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Order_SessionsArgs = {
  where: Order_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_Sessions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Order_ZreportsArgs = {
  where: Order_Zreports_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_Zreports_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_OrdersArgs = {
  where: Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Orders_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Organization_UsersArgs = {
  where: Organization_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Organization_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_OrganizationsArgs = {
  where: Organizations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Organizations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ParticipantsArgs = {
  where: Participants_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Participants_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PaymentmethodsArgs = {
  where: Paymentmethods_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Paymentmethods_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PointofsalesArgs = {
  where: Pointofsales_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Pointofsales_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ProductsArgs = {
  where: Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Products_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_RefreshtokenArgs = {
  where: Refreshtoken_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Refreshtoken_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_TagsArgs = {
  where: Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_TemplatesArgs = {
  where: Templates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Templates_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_VatratesArgs = {
  where: Vatrates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Vatrates_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootInsert_Agreement_ContactsArgs = {
  objects: Array<Agreement_Contacts_Insert_Input>;
  on_conflict?: Maybe<Agreement_Contacts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Agreement_Contacts_OneArgs = {
  object: Agreement_Contacts_Insert_Input;
  on_conflict?: Maybe<Agreement_Contacts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Agreement_TypesArgs = {
  objects: Array<Agreement_Types_Insert_Input>;
  on_conflict?: Maybe<Agreement_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Agreement_Types_OneArgs = {
  object: Agreement_Types_Insert_Input;
  on_conflict?: Maybe<Agreement_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CategoriesArgs = {
  objects: Array<Categories_Insert_Input>;
  on_conflict?: Maybe<Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Categories_OneArgs = {
  object: Categories_Insert_Input;
  on_conflict?: Maybe<Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GroupsArgs = {
  objects: Array<Groups_Insert_Input>;
  on_conflict?: Maybe<Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Groups_OneArgs = {
  object: Groups_Insert_Input;
  on_conflict?: Maybe<Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Session_Connected_UsersArgs = {
  objects: Array<Order_Session_Connected_Users_Insert_Input>;
  on_conflict?: Maybe<Order_Session_Connected_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Session_Connected_Users_OneArgs = {
  object: Order_Session_Connected_Users_Insert_Input;
  on_conflict?: Maybe<Order_Session_Connected_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Session_Groups_ResponsibleArgs = {
  objects: Array<Order_Session_Groups_Responsible_Insert_Input>;
  on_conflict?: Maybe<Order_Session_Groups_Responsible_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Session_Groups_Responsible_OneArgs = {
  object: Order_Session_Groups_Responsible_Insert_Input;
  on_conflict?: Maybe<Order_Session_Groups_Responsible_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Session_Payment_MethodsArgs = {
  objects: Array<Order_Session_Payment_Methods_Insert_Input>;
  on_conflict?: Maybe<Order_Session_Payment_Methods_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Session_Payment_Methods_OneArgs = {
  object: Order_Session_Payment_Methods_Insert_Input;
  on_conflict?: Maybe<Order_Session_Payment_Methods_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_SessionsArgs = {
  objects: Array<Order_Sessions_Insert_Input>;
  on_conflict?: Maybe<Order_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Sessions_OneArgs = {
  object: Order_Sessions_Insert_Input;
  on_conflict?: Maybe<Order_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_ZreportsArgs = {
  objects: Array<Order_Zreports_Insert_Input>;
  on_conflict?: Maybe<Order_Zreports_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Zreports_OneArgs = {
  object: Order_Zreports_Insert_Input;
  on_conflict?: Maybe<Order_Zreports_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OrdersArgs = {
  objects: Array<Orders_Insert_Input>;
  on_conflict?: Maybe<Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Orders_OneArgs = {
  object: Orders_Insert_Input;
  on_conflict?: Maybe<Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Organization_UsersArgs = {
  objects: Array<Organization_Users_Insert_Input>;
  on_conflict?: Maybe<Organization_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Organization_Users_OneArgs = {
  object: Organization_Users_Insert_Input;
  on_conflict?: Maybe<Organization_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OrganizationsArgs = {
  objects: Array<Organizations_Insert_Input>;
  on_conflict?: Maybe<Organizations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Organizations_OneArgs = {
  object: Organizations_Insert_Input;
  on_conflict?: Maybe<Organizations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ParticipantsArgs = {
  objects: Array<Participants_Insert_Input>;
  on_conflict?: Maybe<Participants_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Participants_OneArgs = {
  object: Participants_Insert_Input;
  on_conflict?: Maybe<Participants_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PaymentmethodsArgs = {
  objects: Array<Paymentmethods_Insert_Input>;
  on_conflict?: Maybe<Paymentmethods_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Paymentmethods_OneArgs = {
  object: Paymentmethods_Insert_Input;
  on_conflict?: Maybe<Paymentmethods_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PointofsalesArgs = {
  objects: Array<Pointofsales_Insert_Input>;
  on_conflict?: Maybe<Pointofsales_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pointofsales_OneArgs = {
  object: Pointofsales_Insert_Input;
  on_conflict?: Maybe<Pointofsales_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProductsArgs = {
  objects: Array<Products_Insert_Input>;
  on_conflict?: Maybe<Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Products_OneArgs = {
  object: Products_Insert_Input;
  on_conflict?: Maybe<Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RefreshtokenArgs = {
  objects: Array<Refreshtoken_Insert_Input>;
  on_conflict?: Maybe<Refreshtoken_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Refreshtoken_OneArgs = {
  object: Refreshtoken_Insert_Input;
  on_conflict?: Maybe<Refreshtoken_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TagsArgs = {
  objects: Array<Tags_Insert_Input>;
  on_conflict?: Maybe<Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tags_OneArgs = {
  object: Tags_Insert_Input;
  on_conflict?: Maybe<Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TemplatesArgs = {
  objects: Array<Templates_Insert_Input>;
  on_conflict?: Maybe<Templates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Templates_OneArgs = {
  object: Templates_Insert_Input;
  on_conflict?: Maybe<Templates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VatratesArgs = {
  objects: Array<Vatrates_Insert_Input>;
  on_conflict?: Maybe<Vatrates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vatrates_OneArgs = {
  object: Vatrates_Insert_Input;
  on_conflict?: Maybe<Vatrates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootStartLoginArgs = {
  mobile: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpdate_Agreement_ContactsArgs = {
  _set?: Maybe<Agreement_Contacts_Set_Input>;
  where: Agreement_Contacts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Agreement_Contacts_By_PkArgs = {
  _set?: Maybe<Agreement_Contacts_Set_Input>;
  pk_columns: Agreement_Contacts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Agreement_TypesArgs = {
  _inc?: Maybe<Agreement_Types_Inc_Input>;
  _set?: Maybe<Agreement_Types_Set_Input>;
  where: Agreement_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Agreement_Types_By_PkArgs = {
  _inc?: Maybe<Agreement_Types_Inc_Input>;
  _set?: Maybe<Agreement_Types_Set_Input>;
  pk_columns: Agreement_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CategoriesArgs = {
  _inc?: Maybe<Categories_Inc_Input>;
  _set?: Maybe<Categories_Set_Input>;
  where: Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Categories_By_PkArgs = {
  _inc?: Maybe<Categories_Inc_Input>;
  _set?: Maybe<Categories_Set_Input>;
  pk_columns: Categories_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_GroupsArgs = {
  _inc?: Maybe<Groups_Inc_Input>;
  _set?: Maybe<Groups_Set_Input>;
  where: Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Groups_By_PkArgs = {
  _inc?: Maybe<Groups_Inc_Input>;
  _set?: Maybe<Groups_Set_Input>;
  pk_columns: Groups_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Session_Connected_UsersArgs = {
  _set?: Maybe<Order_Session_Connected_Users_Set_Input>;
  where: Order_Session_Connected_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Session_Connected_Users_By_PkArgs = {
  _set?: Maybe<Order_Session_Connected_Users_Set_Input>;
  pk_columns: Order_Session_Connected_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Session_Groups_ResponsibleArgs = {
  _set?: Maybe<Order_Session_Groups_Responsible_Set_Input>;
  where: Order_Session_Groups_Responsible_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Session_Groups_Responsible_By_PkArgs = {
  _set?: Maybe<Order_Session_Groups_Responsible_Set_Input>;
  pk_columns: Order_Session_Groups_Responsible_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Session_Payment_MethodsArgs = {
  _set?: Maybe<Order_Session_Payment_Methods_Set_Input>;
  where: Order_Session_Payment_Methods_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Session_Payment_Methods_By_PkArgs = {
  _set?: Maybe<Order_Session_Payment_Methods_Set_Input>;
  pk_columns: Order_Session_Payment_Methods_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_SessionsArgs = {
  _inc?: Maybe<Order_Sessions_Inc_Input>;
  _set?: Maybe<Order_Sessions_Set_Input>;
  where: Order_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Sessions_By_PkArgs = {
  _inc?: Maybe<Order_Sessions_Inc_Input>;
  _set?: Maybe<Order_Sessions_Set_Input>;
  pk_columns: Order_Sessions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_ZreportsArgs = {
  _inc?: Maybe<Order_Zreports_Inc_Input>;
  _set?: Maybe<Order_Zreports_Set_Input>;
  where: Order_Zreports_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Zreports_By_PkArgs = {
  _inc?: Maybe<Order_Zreports_Inc_Input>;
  _set?: Maybe<Order_Zreports_Set_Input>;
  pk_columns: Order_Zreports_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_OrdersArgs = {
  _inc?: Maybe<Orders_Inc_Input>;
  _set?: Maybe<Orders_Set_Input>;
  where: Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Orders_By_PkArgs = {
  _inc?: Maybe<Orders_Inc_Input>;
  _set?: Maybe<Orders_Set_Input>;
  pk_columns: Orders_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Organization_UsersArgs = {
  _set?: Maybe<Organization_Users_Set_Input>;
  where: Organization_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Organization_Users_By_PkArgs = {
  _set?: Maybe<Organization_Users_Set_Input>;
  pk_columns: Organization_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_OrganizationsArgs = {
  _inc?: Maybe<Organizations_Inc_Input>;
  _set?: Maybe<Organizations_Set_Input>;
  where: Organizations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Organizations_By_PkArgs = {
  _inc?: Maybe<Organizations_Inc_Input>;
  _set?: Maybe<Organizations_Set_Input>;
  pk_columns: Organizations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ParticipantsArgs = {
  _set?: Maybe<Participants_Set_Input>;
  where: Participants_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Participants_By_PkArgs = {
  _set?: Maybe<Participants_Set_Input>;
  pk_columns: Participants_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PaymentmethodsArgs = {
  _set?: Maybe<Paymentmethods_Set_Input>;
  where: Paymentmethods_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Paymentmethods_By_PkArgs = {
  _set?: Maybe<Paymentmethods_Set_Input>;
  pk_columns: Paymentmethods_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PointofsalesArgs = {
  _inc?: Maybe<Pointofsales_Inc_Input>;
  _set?: Maybe<Pointofsales_Set_Input>;
  where: Pointofsales_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Pointofsales_By_PkArgs = {
  _inc?: Maybe<Pointofsales_Inc_Input>;
  _set?: Maybe<Pointofsales_Set_Input>;
  pk_columns: Pointofsales_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ProductsArgs = {
  _inc?: Maybe<Products_Inc_Input>;
  _set?: Maybe<Products_Set_Input>;
  where: Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Products_By_PkArgs = {
  _inc?: Maybe<Products_Inc_Input>;
  _set?: Maybe<Products_Set_Input>;
  pk_columns: Products_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RefreshtokenArgs = {
  _set?: Maybe<Refreshtoken_Set_Input>;
  where: Refreshtoken_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Refreshtoken_By_PkArgs = {
  _set?: Maybe<Refreshtoken_Set_Input>;
  pk_columns: Refreshtoken_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TagsArgs = {
  _set?: Maybe<Tags_Set_Input>;
  where: Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tags_By_PkArgs = {
  _set?: Maybe<Tags_Set_Input>;
  pk_columns: Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TemplatesArgs = {
  _set?: Maybe<Templates_Set_Input>;
  where: Templates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Templates_By_PkArgs = {
  _set?: Maybe<Templates_Set_Input>;
  pk_columns: Templates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _set?: Maybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _set?: Maybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_VatratesArgs = {
  _inc?: Maybe<Vatrates_Inc_Input>;
  _set?: Maybe<Vatrates_Set_Input>;
  where: Vatrates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Vatrates_By_PkArgs = {
  _inc?: Maybe<Vatrates_Inc_Input>;
  _set?: Maybe<Vatrates_Set_Input>;
  pk_columns: Vatrates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootValidateLoginArgs = {
  mobile: Scalars['String'];
  token: Scalars['Int'];
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "order_session_connected_users" */
export type Order_Session_Connected_Users = {
  __typename?: 'order_session_connected_users';
  connected: Scalars['timestamptz'];
  id: Scalars['uuid'];
  last_transaction: Scalars['timestamptz'];
  /** An object relationship */
  order_session: Order_Sessions;
  order_session_id: Scalars['uuid'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "order_session_connected_users" */
export type Order_Session_Connected_Users_Aggregate = {
  __typename?: 'order_session_connected_users_aggregate';
  aggregate?: Maybe<Order_Session_Connected_Users_Aggregate_Fields>;
  nodes: Array<Order_Session_Connected_Users>;
};

/** aggregate fields of "order_session_connected_users" */
export type Order_Session_Connected_Users_Aggregate_Fields = {
  __typename?: 'order_session_connected_users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Order_Session_Connected_Users_Max_Fields>;
  min?: Maybe<Order_Session_Connected_Users_Min_Fields>;
};


/** aggregate fields of "order_session_connected_users" */
export type Order_Session_Connected_Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Order_Session_Connected_Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "order_session_connected_users" */
export type Order_Session_Connected_Users_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Order_Session_Connected_Users_Max_Order_By>;
  min?: Maybe<Order_Session_Connected_Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "order_session_connected_users" */
export type Order_Session_Connected_Users_Arr_Rel_Insert_Input = {
  data: Array<Order_Session_Connected_Users_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Order_Session_Connected_Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "order_session_connected_users". All fields are combined with a logical 'AND'. */
export type Order_Session_Connected_Users_Bool_Exp = {
  _and?: Maybe<Array<Order_Session_Connected_Users_Bool_Exp>>;
  _not?: Maybe<Order_Session_Connected_Users_Bool_Exp>;
  _or?: Maybe<Array<Order_Session_Connected_Users_Bool_Exp>>;
  connected?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  last_transaction?: Maybe<Timestamptz_Comparison_Exp>;
  order_session?: Maybe<Order_Sessions_Bool_Exp>;
  order_session_id?: Maybe<Uuid_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_session_connected_users" */
export enum Order_Session_Connected_Users_Constraint {
  /** unique or primary key constraint */
  OrderSessionConnectedUsersOrderSessionIdUserIdKey = 'order_session_connected_users_order_session_id_user_id_key',
  /** unique or primary key constraint */
  OrderSessionConnectedUsersPkey = 'order_session_connected_users_pkey'
}

/** input type for inserting data into table "order_session_connected_users" */
export type Order_Session_Connected_Users_Insert_Input = {
  connected?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  last_transaction?: Maybe<Scalars['timestamptz']>;
  order_session?: Maybe<Order_Sessions_Obj_Rel_Insert_Input>;
  order_session_id?: Maybe<Scalars['uuid']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Order_Session_Connected_Users_Max_Fields = {
  __typename?: 'order_session_connected_users_max_fields';
  connected?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  last_transaction?: Maybe<Scalars['timestamptz']>;
  order_session_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "order_session_connected_users" */
export type Order_Session_Connected_Users_Max_Order_By = {
  connected?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_transaction?: Maybe<Order_By>;
  order_session_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Order_Session_Connected_Users_Min_Fields = {
  __typename?: 'order_session_connected_users_min_fields';
  connected?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  last_transaction?: Maybe<Scalars['timestamptz']>;
  order_session_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "order_session_connected_users" */
export type Order_Session_Connected_Users_Min_Order_By = {
  connected?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_transaction?: Maybe<Order_By>;
  order_session_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "order_session_connected_users" */
export type Order_Session_Connected_Users_Mutation_Response = {
  __typename?: 'order_session_connected_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_Session_Connected_Users>;
};

/** on conflict condition type for table "order_session_connected_users" */
export type Order_Session_Connected_Users_On_Conflict = {
  constraint: Order_Session_Connected_Users_Constraint;
  update_columns?: Array<Order_Session_Connected_Users_Update_Column>;
  where?: Maybe<Order_Session_Connected_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "order_session_connected_users". */
export type Order_Session_Connected_Users_Order_By = {
  connected?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_transaction?: Maybe<Order_By>;
  order_session?: Maybe<Order_Sessions_Order_By>;
  order_session_id?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: order_session_connected_users */
export type Order_Session_Connected_Users_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "order_session_connected_users" */
export enum Order_Session_Connected_Users_Select_Column {
  /** column name */
  Connected = 'connected',
  /** column name */
  Id = 'id',
  /** column name */
  LastTransaction = 'last_transaction',
  /** column name */
  OrderSessionId = 'order_session_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "order_session_connected_users" */
export type Order_Session_Connected_Users_Set_Input = {
  connected?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  last_transaction?: Maybe<Scalars['timestamptz']>;
  order_session_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "order_session_connected_users" */
export enum Order_Session_Connected_Users_Update_Column {
  /** column name */
  Connected = 'connected',
  /** column name */
  Id = 'id',
  /** column name */
  LastTransaction = 'last_transaction',
  /** column name */
  OrderSessionId = 'order_session_id',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "order_session_groups_responsible" */
export type Order_Session_Groups_Responsible = {
  __typename?: 'order_session_groups_responsible';
  group_id: Scalars['uuid'];
  id: Scalars['uuid'];
  order_session_id: Scalars['uuid'];
};

/** aggregated selection of "order_session_groups_responsible" */
export type Order_Session_Groups_Responsible_Aggregate = {
  __typename?: 'order_session_groups_responsible_aggregate';
  aggregate?: Maybe<Order_Session_Groups_Responsible_Aggregate_Fields>;
  nodes: Array<Order_Session_Groups_Responsible>;
};

/** aggregate fields of "order_session_groups_responsible" */
export type Order_Session_Groups_Responsible_Aggregate_Fields = {
  __typename?: 'order_session_groups_responsible_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Order_Session_Groups_Responsible_Max_Fields>;
  min?: Maybe<Order_Session_Groups_Responsible_Min_Fields>;
};


/** aggregate fields of "order_session_groups_responsible" */
export type Order_Session_Groups_Responsible_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Order_Session_Groups_Responsible_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "order_session_groups_responsible" */
export type Order_Session_Groups_Responsible_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Order_Session_Groups_Responsible_Max_Order_By>;
  min?: Maybe<Order_Session_Groups_Responsible_Min_Order_By>;
};

/** input type for inserting array relation for remote table "order_session_groups_responsible" */
export type Order_Session_Groups_Responsible_Arr_Rel_Insert_Input = {
  data: Array<Order_Session_Groups_Responsible_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Order_Session_Groups_Responsible_On_Conflict>;
};

/** Boolean expression to filter rows from the table "order_session_groups_responsible". All fields are combined with a logical 'AND'. */
export type Order_Session_Groups_Responsible_Bool_Exp = {
  _and?: Maybe<Array<Order_Session_Groups_Responsible_Bool_Exp>>;
  _not?: Maybe<Order_Session_Groups_Responsible_Bool_Exp>;
  _or?: Maybe<Array<Order_Session_Groups_Responsible_Bool_Exp>>;
  group_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  order_session_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_session_groups_responsible" */
export enum Order_Session_Groups_Responsible_Constraint {
  /** unique or primary key constraint */
  OrderSessionGroupsResponsiblePkey = 'order_session_groups_responsible_pkey'
}

/** input type for inserting data into table "order_session_groups_responsible" */
export type Order_Session_Groups_Responsible_Insert_Input = {
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  order_session_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Order_Session_Groups_Responsible_Max_Fields = {
  __typename?: 'order_session_groups_responsible_max_fields';
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  order_session_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "order_session_groups_responsible" */
export type Order_Session_Groups_Responsible_Max_Order_By = {
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_session_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Order_Session_Groups_Responsible_Min_Fields = {
  __typename?: 'order_session_groups_responsible_min_fields';
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  order_session_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "order_session_groups_responsible" */
export type Order_Session_Groups_Responsible_Min_Order_By = {
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_session_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "order_session_groups_responsible" */
export type Order_Session_Groups_Responsible_Mutation_Response = {
  __typename?: 'order_session_groups_responsible_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_Session_Groups_Responsible>;
};

/** on conflict condition type for table "order_session_groups_responsible" */
export type Order_Session_Groups_Responsible_On_Conflict = {
  constraint: Order_Session_Groups_Responsible_Constraint;
  update_columns?: Array<Order_Session_Groups_Responsible_Update_Column>;
  where?: Maybe<Order_Session_Groups_Responsible_Bool_Exp>;
};

/** Ordering options when selecting data from "order_session_groups_responsible". */
export type Order_Session_Groups_Responsible_Order_By = {
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_session_id?: Maybe<Order_By>;
};

/** primary key columns input for table: order_session_groups_responsible */
export type Order_Session_Groups_Responsible_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "order_session_groups_responsible" */
export enum Order_Session_Groups_Responsible_Select_Column {
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  OrderSessionId = 'order_session_id'
}

/** input type for updating data in table "order_session_groups_responsible" */
export type Order_Session_Groups_Responsible_Set_Input = {
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  order_session_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "order_session_groups_responsible" */
export enum Order_Session_Groups_Responsible_Update_Column {
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  OrderSessionId = 'order_session_id'
}

/** columns and relationships of "order_session_payment_methods" */
export type Order_Session_Payment_Methods = {
  __typename?: 'order_session_payment_methods';
  id: Scalars['uuid'];
  order_session_id: Scalars['uuid'];
  payment_details?: Maybe<Scalars['String']>;
  payment_method_id?: Maybe<Scalars['uuid']>;
  payment_method_key: Scalars['String'];
};

/** aggregated selection of "order_session_payment_methods" */
export type Order_Session_Payment_Methods_Aggregate = {
  __typename?: 'order_session_payment_methods_aggregate';
  aggregate?: Maybe<Order_Session_Payment_Methods_Aggregate_Fields>;
  nodes: Array<Order_Session_Payment_Methods>;
};

/** aggregate fields of "order_session_payment_methods" */
export type Order_Session_Payment_Methods_Aggregate_Fields = {
  __typename?: 'order_session_payment_methods_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Order_Session_Payment_Methods_Max_Fields>;
  min?: Maybe<Order_Session_Payment_Methods_Min_Fields>;
};


/** aggregate fields of "order_session_payment_methods" */
export type Order_Session_Payment_Methods_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Order_Session_Payment_Methods_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "order_session_payment_methods" */
export type Order_Session_Payment_Methods_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Order_Session_Payment_Methods_Max_Order_By>;
  min?: Maybe<Order_Session_Payment_Methods_Min_Order_By>;
};

/** input type for inserting array relation for remote table "order_session_payment_methods" */
export type Order_Session_Payment_Methods_Arr_Rel_Insert_Input = {
  data: Array<Order_Session_Payment_Methods_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Order_Session_Payment_Methods_On_Conflict>;
};

/** Boolean expression to filter rows from the table "order_session_payment_methods". All fields are combined with a logical 'AND'. */
export type Order_Session_Payment_Methods_Bool_Exp = {
  _and?: Maybe<Array<Order_Session_Payment_Methods_Bool_Exp>>;
  _not?: Maybe<Order_Session_Payment_Methods_Bool_Exp>;
  _or?: Maybe<Array<Order_Session_Payment_Methods_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  order_session_id?: Maybe<Uuid_Comparison_Exp>;
  payment_details?: Maybe<String_Comparison_Exp>;
  payment_method_id?: Maybe<Uuid_Comparison_Exp>;
  payment_method_key?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_session_payment_methods" */
export enum Order_Session_Payment_Methods_Constraint {
  /** unique or primary key constraint */
  OrderSessionPaymentmethodsPkey = 'order_session_paymentmethods_pkey'
}

/** input type for inserting data into table "order_session_payment_methods" */
export type Order_Session_Payment_Methods_Insert_Input = {
  id?: Maybe<Scalars['uuid']>;
  order_session_id?: Maybe<Scalars['uuid']>;
  payment_details?: Maybe<Scalars['String']>;
  payment_method_id?: Maybe<Scalars['uuid']>;
  payment_method_key?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Order_Session_Payment_Methods_Max_Fields = {
  __typename?: 'order_session_payment_methods_max_fields';
  id?: Maybe<Scalars['uuid']>;
  order_session_id?: Maybe<Scalars['uuid']>;
  payment_details?: Maybe<Scalars['String']>;
  payment_method_id?: Maybe<Scalars['uuid']>;
  payment_method_key?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "order_session_payment_methods" */
export type Order_Session_Payment_Methods_Max_Order_By = {
  id?: Maybe<Order_By>;
  order_session_id?: Maybe<Order_By>;
  payment_details?: Maybe<Order_By>;
  payment_method_id?: Maybe<Order_By>;
  payment_method_key?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Order_Session_Payment_Methods_Min_Fields = {
  __typename?: 'order_session_payment_methods_min_fields';
  id?: Maybe<Scalars['uuid']>;
  order_session_id?: Maybe<Scalars['uuid']>;
  payment_details?: Maybe<Scalars['String']>;
  payment_method_id?: Maybe<Scalars['uuid']>;
  payment_method_key?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "order_session_payment_methods" */
export type Order_Session_Payment_Methods_Min_Order_By = {
  id?: Maybe<Order_By>;
  order_session_id?: Maybe<Order_By>;
  payment_details?: Maybe<Order_By>;
  payment_method_id?: Maybe<Order_By>;
  payment_method_key?: Maybe<Order_By>;
};

/** response of any mutation on the table "order_session_payment_methods" */
export type Order_Session_Payment_Methods_Mutation_Response = {
  __typename?: 'order_session_payment_methods_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_Session_Payment_Methods>;
};

/** on conflict condition type for table "order_session_payment_methods" */
export type Order_Session_Payment_Methods_On_Conflict = {
  constraint: Order_Session_Payment_Methods_Constraint;
  update_columns?: Array<Order_Session_Payment_Methods_Update_Column>;
  where?: Maybe<Order_Session_Payment_Methods_Bool_Exp>;
};

/** Ordering options when selecting data from "order_session_payment_methods". */
export type Order_Session_Payment_Methods_Order_By = {
  id?: Maybe<Order_By>;
  order_session_id?: Maybe<Order_By>;
  payment_details?: Maybe<Order_By>;
  payment_method_id?: Maybe<Order_By>;
  payment_method_key?: Maybe<Order_By>;
};

/** primary key columns input for table: order_session_payment_methods */
export type Order_Session_Payment_Methods_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "order_session_payment_methods" */
export enum Order_Session_Payment_Methods_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  OrderSessionId = 'order_session_id',
  /** column name */
  PaymentDetails = 'payment_details',
  /** column name */
  PaymentMethodId = 'payment_method_id',
  /** column name */
  PaymentMethodKey = 'payment_method_key'
}

/** input type for updating data in table "order_session_payment_methods" */
export type Order_Session_Payment_Methods_Set_Input = {
  id?: Maybe<Scalars['uuid']>;
  order_session_id?: Maybe<Scalars['uuid']>;
  payment_details?: Maybe<Scalars['String']>;
  payment_method_id?: Maybe<Scalars['uuid']>;
  payment_method_key?: Maybe<Scalars['String']>;
};

/** update columns of table "order_session_payment_methods" */
export enum Order_Session_Payment_Methods_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  OrderSessionId = 'order_session_id',
  /** column name */
  PaymentDetails = 'payment_details',
  /** column name */
  PaymentMethodId = 'payment_method_id',
  /** column name */
  PaymentMethodKey = 'payment_method_key'
}

/** columns and relationships of "order_sessions" */
export type Order_Sessions = {
  __typename?: 'order_sessions';
  active: Scalars['Boolean'];
  cash_amount_in: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  first_reciept: Scalars['Int'];
  id: Scalars['uuid'];
  open_time: Scalars['timestamptz'];
  /** An array relationship */
  order_session_connected_users: Array<Order_Session_Connected_Users>;
  /** An aggregate relationship */
  order_session_connected_users_aggregate: Order_Session_Connected_Users_Aggregate;
  /** An array relationship */
  order_session_groups_responsible: Array<Order_Session_Groups_Responsible>;
  /** An aggregate relationship */
  order_session_groups_responsible_aggregate: Order_Session_Groups_Responsible_Aggregate;
  /** An array relationship */
  order_session_payment_methods: Array<Order_Session_Payment_Methods>;
  /** An aggregate relationship */
  order_session_payment_methods_aggregate: Order_Session_Payment_Methods_Aggregate;
  /** An object relationship */
  pointofsale: Pointofsales;
  pos_id: Scalars['uuid'];
  total_sales: Scalars['Int'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};


/** columns and relationships of "order_sessions" */
export type Order_SessionsOrder_Session_Connected_UsersArgs = {
  distinct_on?: Maybe<Array<Order_Session_Connected_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Session_Connected_Users_Order_By>>;
  where?: Maybe<Order_Session_Connected_Users_Bool_Exp>;
};


/** columns and relationships of "order_sessions" */
export type Order_SessionsOrder_Session_Connected_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Session_Connected_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Session_Connected_Users_Order_By>>;
  where?: Maybe<Order_Session_Connected_Users_Bool_Exp>;
};


/** columns and relationships of "order_sessions" */
export type Order_SessionsOrder_Session_Groups_ResponsibleArgs = {
  distinct_on?: Maybe<Array<Order_Session_Groups_Responsible_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Session_Groups_Responsible_Order_By>>;
  where?: Maybe<Order_Session_Groups_Responsible_Bool_Exp>;
};


/** columns and relationships of "order_sessions" */
export type Order_SessionsOrder_Session_Groups_Responsible_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Session_Groups_Responsible_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Session_Groups_Responsible_Order_By>>;
  where?: Maybe<Order_Session_Groups_Responsible_Bool_Exp>;
};


/** columns and relationships of "order_sessions" */
export type Order_SessionsOrder_Session_Payment_MethodsArgs = {
  distinct_on?: Maybe<Array<Order_Session_Payment_Methods_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Session_Payment_Methods_Order_By>>;
  where?: Maybe<Order_Session_Payment_Methods_Bool_Exp>;
};


/** columns and relationships of "order_sessions" */
export type Order_SessionsOrder_Session_Payment_Methods_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Session_Payment_Methods_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Session_Payment_Methods_Order_By>>;
  where?: Maybe<Order_Session_Payment_Methods_Bool_Exp>;
};

/** aggregated selection of "order_sessions" */
export type Order_Sessions_Aggregate = {
  __typename?: 'order_sessions_aggregate';
  aggregate?: Maybe<Order_Sessions_Aggregate_Fields>;
  nodes: Array<Order_Sessions>;
};

/** aggregate fields of "order_sessions" */
export type Order_Sessions_Aggregate_Fields = {
  __typename?: 'order_sessions_aggregate_fields';
  avg?: Maybe<Order_Sessions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Order_Sessions_Max_Fields>;
  min?: Maybe<Order_Sessions_Min_Fields>;
  stddev?: Maybe<Order_Sessions_Stddev_Fields>;
  stddev_pop?: Maybe<Order_Sessions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Order_Sessions_Stddev_Samp_Fields>;
  sum?: Maybe<Order_Sessions_Sum_Fields>;
  var_pop?: Maybe<Order_Sessions_Var_Pop_Fields>;
  var_samp?: Maybe<Order_Sessions_Var_Samp_Fields>;
  variance?: Maybe<Order_Sessions_Variance_Fields>;
};


/** aggregate fields of "order_sessions" */
export type Order_Sessions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Order_Sessions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "order_sessions" */
export type Order_Sessions_Aggregate_Order_By = {
  avg?: Maybe<Order_Sessions_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Order_Sessions_Max_Order_By>;
  min?: Maybe<Order_Sessions_Min_Order_By>;
  stddev?: Maybe<Order_Sessions_Stddev_Order_By>;
  stddev_pop?: Maybe<Order_Sessions_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Order_Sessions_Stddev_Samp_Order_By>;
  sum?: Maybe<Order_Sessions_Sum_Order_By>;
  var_pop?: Maybe<Order_Sessions_Var_Pop_Order_By>;
  var_samp?: Maybe<Order_Sessions_Var_Samp_Order_By>;
  variance?: Maybe<Order_Sessions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "order_sessions" */
export type Order_Sessions_Arr_Rel_Insert_Input = {
  data: Array<Order_Sessions_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Order_Sessions_On_Conflict>;
};

/** aggregate avg on columns */
export type Order_Sessions_Avg_Fields = {
  __typename?: 'order_sessions_avg_fields';
  cash_amount_in?: Maybe<Scalars['Float']>;
  first_reciept?: Maybe<Scalars['Float']>;
  total_sales?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "order_sessions" */
export type Order_Sessions_Avg_Order_By = {
  cash_amount_in?: Maybe<Order_By>;
  first_reciept?: Maybe<Order_By>;
  total_sales?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_sessions". All fields are combined with a logical 'AND'. */
export type Order_Sessions_Bool_Exp = {
  _and?: Maybe<Array<Order_Sessions_Bool_Exp>>;
  _not?: Maybe<Order_Sessions_Bool_Exp>;
  _or?: Maybe<Array<Order_Sessions_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  cash_amount_in?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  first_reciept?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  open_time?: Maybe<Timestamptz_Comparison_Exp>;
  order_session_connected_users?: Maybe<Order_Session_Connected_Users_Bool_Exp>;
  order_session_groups_responsible?: Maybe<Order_Session_Groups_Responsible_Bool_Exp>;
  order_session_payment_methods?: Maybe<Order_Session_Payment_Methods_Bool_Exp>;
  pointofsale?: Maybe<Pointofsales_Bool_Exp>;
  pos_id?: Maybe<Uuid_Comparison_Exp>;
  total_sales?: Maybe<Int_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_sessions" */
export enum Order_Sessions_Constraint {
  /** unique or primary key constraint */
  OrderSessionsPkey = 'order_sessions_pkey'
}

/** input type for incrementing numeric columns in table "order_sessions" */
export type Order_Sessions_Inc_Input = {
  cash_amount_in?: Maybe<Scalars['Int']>;
  first_reciept?: Maybe<Scalars['Int']>;
  total_sales?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "order_sessions" */
export type Order_Sessions_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  cash_amount_in?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  first_reciept?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  open_time?: Maybe<Scalars['timestamptz']>;
  order_session_connected_users?: Maybe<Order_Session_Connected_Users_Arr_Rel_Insert_Input>;
  order_session_groups_responsible?: Maybe<Order_Session_Groups_Responsible_Arr_Rel_Insert_Input>;
  order_session_payment_methods?: Maybe<Order_Session_Payment_Methods_Arr_Rel_Insert_Input>;
  pointofsale?: Maybe<Pointofsales_Obj_Rel_Insert_Input>;
  pos_id?: Maybe<Scalars['uuid']>;
  total_sales?: Maybe<Scalars['Int']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Order_Sessions_Max_Fields = {
  __typename?: 'order_sessions_max_fields';
  cash_amount_in?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  first_reciept?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  open_time?: Maybe<Scalars['timestamptz']>;
  pos_id?: Maybe<Scalars['uuid']>;
  total_sales?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "order_sessions" */
export type Order_Sessions_Max_Order_By = {
  cash_amount_in?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  first_reciept?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  open_time?: Maybe<Order_By>;
  pos_id?: Maybe<Order_By>;
  total_sales?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Order_Sessions_Min_Fields = {
  __typename?: 'order_sessions_min_fields';
  cash_amount_in?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  first_reciept?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  open_time?: Maybe<Scalars['timestamptz']>;
  pos_id?: Maybe<Scalars['uuid']>;
  total_sales?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "order_sessions" */
export type Order_Sessions_Min_Order_By = {
  cash_amount_in?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  first_reciept?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  open_time?: Maybe<Order_By>;
  pos_id?: Maybe<Order_By>;
  total_sales?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "order_sessions" */
export type Order_Sessions_Mutation_Response = {
  __typename?: 'order_sessions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_Sessions>;
};

/** input type for inserting object relation for remote table "order_sessions" */
export type Order_Sessions_Obj_Rel_Insert_Input = {
  data: Order_Sessions_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Order_Sessions_On_Conflict>;
};

/** on conflict condition type for table "order_sessions" */
export type Order_Sessions_On_Conflict = {
  constraint: Order_Sessions_Constraint;
  update_columns?: Array<Order_Sessions_Update_Column>;
  where?: Maybe<Order_Sessions_Bool_Exp>;
};

/** Ordering options when selecting data from "order_sessions". */
export type Order_Sessions_Order_By = {
  active?: Maybe<Order_By>;
  cash_amount_in?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  first_reciept?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  open_time?: Maybe<Order_By>;
  order_session_connected_users_aggregate?: Maybe<Order_Session_Connected_Users_Aggregate_Order_By>;
  order_session_groups_responsible_aggregate?: Maybe<Order_Session_Groups_Responsible_Aggregate_Order_By>;
  order_session_payment_methods_aggregate?: Maybe<Order_Session_Payment_Methods_Aggregate_Order_By>;
  pointofsale?: Maybe<Pointofsales_Order_By>;
  pos_id?: Maybe<Order_By>;
  total_sales?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: order_sessions */
export type Order_Sessions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "order_sessions" */
export enum Order_Sessions_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CashAmountIn = 'cash_amount_in',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstReciept = 'first_reciept',
  /** column name */
  Id = 'id',
  /** column name */
  OpenTime = 'open_time',
  /** column name */
  PosId = 'pos_id',
  /** column name */
  TotalSales = 'total_sales',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "order_sessions" */
export type Order_Sessions_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  cash_amount_in?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  first_reciept?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  open_time?: Maybe<Scalars['timestamptz']>;
  pos_id?: Maybe<Scalars['uuid']>;
  total_sales?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Order_Sessions_Stddev_Fields = {
  __typename?: 'order_sessions_stddev_fields';
  cash_amount_in?: Maybe<Scalars['Float']>;
  first_reciept?: Maybe<Scalars['Float']>;
  total_sales?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "order_sessions" */
export type Order_Sessions_Stddev_Order_By = {
  cash_amount_in?: Maybe<Order_By>;
  first_reciept?: Maybe<Order_By>;
  total_sales?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Order_Sessions_Stddev_Pop_Fields = {
  __typename?: 'order_sessions_stddev_pop_fields';
  cash_amount_in?: Maybe<Scalars['Float']>;
  first_reciept?: Maybe<Scalars['Float']>;
  total_sales?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "order_sessions" */
export type Order_Sessions_Stddev_Pop_Order_By = {
  cash_amount_in?: Maybe<Order_By>;
  first_reciept?: Maybe<Order_By>;
  total_sales?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Order_Sessions_Stddev_Samp_Fields = {
  __typename?: 'order_sessions_stddev_samp_fields';
  cash_amount_in?: Maybe<Scalars['Float']>;
  first_reciept?: Maybe<Scalars['Float']>;
  total_sales?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "order_sessions" */
export type Order_Sessions_Stddev_Samp_Order_By = {
  cash_amount_in?: Maybe<Order_By>;
  first_reciept?: Maybe<Order_By>;
  total_sales?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Order_Sessions_Sum_Fields = {
  __typename?: 'order_sessions_sum_fields';
  cash_amount_in?: Maybe<Scalars['Int']>;
  first_reciept?: Maybe<Scalars['Int']>;
  total_sales?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "order_sessions" */
export type Order_Sessions_Sum_Order_By = {
  cash_amount_in?: Maybe<Order_By>;
  first_reciept?: Maybe<Order_By>;
  total_sales?: Maybe<Order_By>;
};

/** update columns of table "order_sessions" */
export enum Order_Sessions_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CashAmountIn = 'cash_amount_in',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstReciept = 'first_reciept',
  /** column name */
  Id = 'id',
  /** column name */
  OpenTime = 'open_time',
  /** column name */
  PosId = 'pos_id',
  /** column name */
  TotalSales = 'total_sales',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Order_Sessions_Var_Pop_Fields = {
  __typename?: 'order_sessions_var_pop_fields';
  cash_amount_in?: Maybe<Scalars['Float']>;
  first_reciept?: Maybe<Scalars['Float']>;
  total_sales?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "order_sessions" */
export type Order_Sessions_Var_Pop_Order_By = {
  cash_amount_in?: Maybe<Order_By>;
  first_reciept?: Maybe<Order_By>;
  total_sales?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Order_Sessions_Var_Samp_Fields = {
  __typename?: 'order_sessions_var_samp_fields';
  cash_amount_in?: Maybe<Scalars['Float']>;
  first_reciept?: Maybe<Scalars['Float']>;
  total_sales?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "order_sessions" */
export type Order_Sessions_Var_Samp_Order_By = {
  cash_amount_in?: Maybe<Order_By>;
  first_reciept?: Maybe<Order_By>;
  total_sales?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Order_Sessions_Variance_Fields = {
  __typename?: 'order_sessions_variance_fields';
  cash_amount_in?: Maybe<Scalars['Float']>;
  first_reciept?: Maybe<Scalars['Float']>;
  total_sales?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "order_sessions" */
export type Order_Sessions_Variance_Order_By = {
  cash_amount_in?: Maybe<Order_By>;
  first_reciept?: Maybe<Order_By>;
  total_sales?: Maybe<Order_By>;
};

/** columns and relationships of "order_zreports" */
export type Order_Zreports = {
  __typename?: 'order_zreports';
  avg_per_sale?: Maybe<Scalars['numeric']>;
  cash_counted?: Maybe<Scalars['numeric']>;
  cash_deposit?: Maybe<Scalars['numeric']>;
  cash_diff?: Maybe<Scalars['numeric']>;
  cash_in?: Maybe<Scalars['numeric']>;
  cash_sales?: Maybe<Scalars['numeric']>;
  cash_withdrawal?: Maybe<Scalars['numeric']>;
  created_by_user: Scalars['uuid'];
  date: Scalars['timestamptz'];
  id: Scalars['uuid'];
  number_of_reciepts?: Maybe<Scalars['numeric']>;
  number_of_returnedtems: Scalars['numeric'];
  number_of_returns?: Maybe<Scalars['numeric']>;
  number_of_sales: Scalars['numeric'];
  number_of_sold_items: Scalars['numeric'];
  org_name: Scalars['String'];
  org_number: Scalars['String'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid'];
  pos_id: Scalars['uuid'];
  pos_name: Scalars['String'];
  reciept_number_high: Scalars['numeric'];
  reciept_number_low: Scalars['numeric'];
  report_number: Scalars['numeric'];
  returns_total: Scalars['numeric'];
  session_id: Scalars['uuid'];
  tags: Scalars['String'];
  total_amount: Scalars['numeric'];
  total_sales: Scalars['numeric'];
  total_sales_excl_vat?: Maybe<Scalars['numeric']>;
  total_vat: Scalars['numeric'];
  vat_enabled: Scalars['Boolean'];
};

/** aggregated selection of "order_zreports" */
export type Order_Zreports_Aggregate = {
  __typename?: 'order_zreports_aggregate';
  aggregate?: Maybe<Order_Zreports_Aggregate_Fields>;
  nodes: Array<Order_Zreports>;
};

/** aggregate fields of "order_zreports" */
export type Order_Zreports_Aggregate_Fields = {
  __typename?: 'order_zreports_aggregate_fields';
  avg?: Maybe<Order_Zreports_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Order_Zreports_Max_Fields>;
  min?: Maybe<Order_Zreports_Min_Fields>;
  stddev?: Maybe<Order_Zreports_Stddev_Fields>;
  stddev_pop?: Maybe<Order_Zreports_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Order_Zreports_Stddev_Samp_Fields>;
  sum?: Maybe<Order_Zreports_Sum_Fields>;
  var_pop?: Maybe<Order_Zreports_Var_Pop_Fields>;
  var_samp?: Maybe<Order_Zreports_Var_Samp_Fields>;
  variance?: Maybe<Order_Zreports_Variance_Fields>;
};


/** aggregate fields of "order_zreports" */
export type Order_Zreports_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Order_Zreports_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "order_zreports" */
export type Order_Zreports_Aggregate_Order_By = {
  avg?: Maybe<Order_Zreports_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Order_Zreports_Max_Order_By>;
  min?: Maybe<Order_Zreports_Min_Order_By>;
  stddev?: Maybe<Order_Zreports_Stddev_Order_By>;
  stddev_pop?: Maybe<Order_Zreports_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Order_Zreports_Stddev_Samp_Order_By>;
  sum?: Maybe<Order_Zreports_Sum_Order_By>;
  var_pop?: Maybe<Order_Zreports_Var_Pop_Order_By>;
  var_samp?: Maybe<Order_Zreports_Var_Samp_Order_By>;
  variance?: Maybe<Order_Zreports_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "order_zreports" */
export type Order_Zreports_Arr_Rel_Insert_Input = {
  data: Array<Order_Zreports_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Order_Zreports_On_Conflict>;
};

/** aggregate avg on columns */
export type Order_Zreports_Avg_Fields = {
  __typename?: 'order_zreports_avg_fields';
  avg_per_sale?: Maybe<Scalars['Float']>;
  cash_counted?: Maybe<Scalars['Float']>;
  cash_deposit?: Maybe<Scalars['Float']>;
  cash_diff?: Maybe<Scalars['Float']>;
  cash_in?: Maybe<Scalars['Float']>;
  cash_sales?: Maybe<Scalars['Float']>;
  cash_withdrawal?: Maybe<Scalars['Float']>;
  number_of_reciepts?: Maybe<Scalars['Float']>;
  number_of_returnedtems?: Maybe<Scalars['Float']>;
  number_of_returns?: Maybe<Scalars['Float']>;
  number_of_sales?: Maybe<Scalars['Float']>;
  number_of_sold_items?: Maybe<Scalars['Float']>;
  reciept_number_high?: Maybe<Scalars['Float']>;
  reciept_number_low?: Maybe<Scalars['Float']>;
  report_number?: Maybe<Scalars['Float']>;
  returns_total?: Maybe<Scalars['Float']>;
  total_amount?: Maybe<Scalars['Float']>;
  total_sales?: Maybe<Scalars['Float']>;
  total_sales_excl_vat?: Maybe<Scalars['Float']>;
  total_vat?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "order_zreports" */
export type Order_Zreports_Avg_Order_By = {
  avg_per_sale?: Maybe<Order_By>;
  cash_counted?: Maybe<Order_By>;
  cash_deposit?: Maybe<Order_By>;
  cash_diff?: Maybe<Order_By>;
  cash_in?: Maybe<Order_By>;
  cash_sales?: Maybe<Order_By>;
  cash_withdrawal?: Maybe<Order_By>;
  number_of_reciepts?: Maybe<Order_By>;
  number_of_returnedtems?: Maybe<Order_By>;
  number_of_returns?: Maybe<Order_By>;
  number_of_sales?: Maybe<Order_By>;
  number_of_sold_items?: Maybe<Order_By>;
  reciept_number_high?: Maybe<Order_By>;
  reciept_number_low?: Maybe<Order_By>;
  report_number?: Maybe<Order_By>;
  returns_total?: Maybe<Order_By>;
  total_amount?: Maybe<Order_By>;
  total_sales?: Maybe<Order_By>;
  total_sales_excl_vat?: Maybe<Order_By>;
  total_vat?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_zreports". All fields are combined with a logical 'AND'. */
export type Order_Zreports_Bool_Exp = {
  _and?: Maybe<Array<Order_Zreports_Bool_Exp>>;
  _not?: Maybe<Order_Zreports_Bool_Exp>;
  _or?: Maybe<Array<Order_Zreports_Bool_Exp>>;
  avg_per_sale?: Maybe<Numeric_Comparison_Exp>;
  cash_counted?: Maybe<Numeric_Comparison_Exp>;
  cash_deposit?: Maybe<Numeric_Comparison_Exp>;
  cash_diff?: Maybe<Numeric_Comparison_Exp>;
  cash_in?: Maybe<Numeric_Comparison_Exp>;
  cash_sales?: Maybe<Numeric_Comparison_Exp>;
  cash_withdrawal?: Maybe<Numeric_Comparison_Exp>;
  created_by_user?: Maybe<Uuid_Comparison_Exp>;
  date?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  number_of_reciepts?: Maybe<Numeric_Comparison_Exp>;
  number_of_returnedtems?: Maybe<Numeric_Comparison_Exp>;
  number_of_returns?: Maybe<Numeric_Comparison_Exp>;
  number_of_sales?: Maybe<Numeric_Comparison_Exp>;
  number_of_sold_items?: Maybe<Numeric_Comparison_Exp>;
  org_name?: Maybe<String_Comparison_Exp>;
  org_number?: Maybe<String_Comparison_Exp>;
  organization?: Maybe<Organizations_Bool_Exp>;
  organization_id?: Maybe<Uuid_Comparison_Exp>;
  pos_id?: Maybe<Uuid_Comparison_Exp>;
  pos_name?: Maybe<String_Comparison_Exp>;
  reciept_number_high?: Maybe<Numeric_Comparison_Exp>;
  reciept_number_low?: Maybe<Numeric_Comparison_Exp>;
  report_number?: Maybe<Numeric_Comparison_Exp>;
  returns_total?: Maybe<Numeric_Comparison_Exp>;
  session_id?: Maybe<Uuid_Comparison_Exp>;
  tags?: Maybe<String_Comparison_Exp>;
  total_amount?: Maybe<Numeric_Comparison_Exp>;
  total_sales?: Maybe<Numeric_Comparison_Exp>;
  total_sales_excl_vat?: Maybe<Numeric_Comparison_Exp>;
  total_vat?: Maybe<Numeric_Comparison_Exp>;
  vat_enabled?: Maybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_zreports" */
export enum Order_Zreports_Constraint {
  /** unique or primary key constraint */
  OrderZreportsPkey = 'order_zreports_pkey'
}

/** input type for incrementing numeric columns in table "order_zreports" */
export type Order_Zreports_Inc_Input = {
  avg_per_sale?: Maybe<Scalars['numeric']>;
  cash_counted?: Maybe<Scalars['numeric']>;
  cash_deposit?: Maybe<Scalars['numeric']>;
  cash_diff?: Maybe<Scalars['numeric']>;
  cash_in?: Maybe<Scalars['numeric']>;
  cash_sales?: Maybe<Scalars['numeric']>;
  cash_withdrawal?: Maybe<Scalars['numeric']>;
  number_of_reciepts?: Maybe<Scalars['numeric']>;
  number_of_returnedtems?: Maybe<Scalars['numeric']>;
  number_of_returns?: Maybe<Scalars['numeric']>;
  number_of_sales?: Maybe<Scalars['numeric']>;
  number_of_sold_items?: Maybe<Scalars['numeric']>;
  reciept_number_high?: Maybe<Scalars['numeric']>;
  reciept_number_low?: Maybe<Scalars['numeric']>;
  report_number?: Maybe<Scalars['numeric']>;
  returns_total?: Maybe<Scalars['numeric']>;
  total_amount?: Maybe<Scalars['numeric']>;
  total_sales?: Maybe<Scalars['numeric']>;
  total_sales_excl_vat?: Maybe<Scalars['numeric']>;
  total_vat?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "order_zreports" */
export type Order_Zreports_Insert_Input = {
  avg_per_sale?: Maybe<Scalars['numeric']>;
  cash_counted?: Maybe<Scalars['numeric']>;
  cash_deposit?: Maybe<Scalars['numeric']>;
  cash_diff?: Maybe<Scalars['numeric']>;
  cash_in?: Maybe<Scalars['numeric']>;
  cash_sales?: Maybe<Scalars['numeric']>;
  cash_withdrawal?: Maybe<Scalars['numeric']>;
  created_by_user?: Maybe<Scalars['uuid']>;
  date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  number_of_reciepts?: Maybe<Scalars['numeric']>;
  number_of_returnedtems?: Maybe<Scalars['numeric']>;
  number_of_returns?: Maybe<Scalars['numeric']>;
  number_of_sales?: Maybe<Scalars['numeric']>;
  number_of_sold_items?: Maybe<Scalars['numeric']>;
  org_name?: Maybe<Scalars['String']>;
  org_number?: Maybe<Scalars['String']>;
  organization?: Maybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: Maybe<Scalars['uuid']>;
  pos_id?: Maybe<Scalars['uuid']>;
  pos_name?: Maybe<Scalars['String']>;
  reciept_number_high?: Maybe<Scalars['numeric']>;
  reciept_number_low?: Maybe<Scalars['numeric']>;
  report_number?: Maybe<Scalars['numeric']>;
  returns_total?: Maybe<Scalars['numeric']>;
  session_id?: Maybe<Scalars['uuid']>;
  tags?: Maybe<Scalars['String']>;
  total_amount?: Maybe<Scalars['numeric']>;
  total_sales?: Maybe<Scalars['numeric']>;
  total_sales_excl_vat?: Maybe<Scalars['numeric']>;
  total_vat?: Maybe<Scalars['numeric']>;
  vat_enabled?: Maybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Order_Zreports_Max_Fields = {
  __typename?: 'order_zreports_max_fields';
  avg_per_sale?: Maybe<Scalars['numeric']>;
  cash_counted?: Maybe<Scalars['numeric']>;
  cash_deposit?: Maybe<Scalars['numeric']>;
  cash_diff?: Maybe<Scalars['numeric']>;
  cash_in?: Maybe<Scalars['numeric']>;
  cash_sales?: Maybe<Scalars['numeric']>;
  cash_withdrawal?: Maybe<Scalars['numeric']>;
  created_by_user?: Maybe<Scalars['uuid']>;
  date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  number_of_reciepts?: Maybe<Scalars['numeric']>;
  number_of_returnedtems?: Maybe<Scalars['numeric']>;
  number_of_returns?: Maybe<Scalars['numeric']>;
  number_of_sales?: Maybe<Scalars['numeric']>;
  number_of_sold_items?: Maybe<Scalars['numeric']>;
  org_name?: Maybe<Scalars['String']>;
  org_number?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  pos_id?: Maybe<Scalars['uuid']>;
  pos_name?: Maybe<Scalars['String']>;
  reciept_number_high?: Maybe<Scalars['numeric']>;
  reciept_number_low?: Maybe<Scalars['numeric']>;
  report_number?: Maybe<Scalars['numeric']>;
  returns_total?: Maybe<Scalars['numeric']>;
  session_id?: Maybe<Scalars['uuid']>;
  tags?: Maybe<Scalars['String']>;
  total_amount?: Maybe<Scalars['numeric']>;
  total_sales?: Maybe<Scalars['numeric']>;
  total_sales_excl_vat?: Maybe<Scalars['numeric']>;
  total_vat?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "order_zreports" */
export type Order_Zreports_Max_Order_By = {
  avg_per_sale?: Maybe<Order_By>;
  cash_counted?: Maybe<Order_By>;
  cash_deposit?: Maybe<Order_By>;
  cash_diff?: Maybe<Order_By>;
  cash_in?: Maybe<Order_By>;
  cash_sales?: Maybe<Order_By>;
  cash_withdrawal?: Maybe<Order_By>;
  created_by_user?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  number_of_reciepts?: Maybe<Order_By>;
  number_of_returnedtems?: Maybe<Order_By>;
  number_of_returns?: Maybe<Order_By>;
  number_of_sales?: Maybe<Order_By>;
  number_of_sold_items?: Maybe<Order_By>;
  org_name?: Maybe<Order_By>;
  org_number?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  pos_id?: Maybe<Order_By>;
  pos_name?: Maybe<Order_By>;
  reciept_number_high?: Maybe<Order_By>;
  reciept_number_low?: Maybe<Order_By>;
  report_number?: Maybe<Order_By>;
  returns_total?: Maybe<Order_By>;
  session_id?: Maybe<Order_By>;
  tags?: Maybe<Order_By>;
  total_amount?: Maybe<Order_By>;
  total_sales?: Maybe<Order_By>;
  total_sales_excl_vat?: Maybe<Order_By>;
  total_vat?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Order_Zreports_Min_Fields = {
  __typename?: 'order_zreports_min_fields';
  avg_per_sale?: Maybe<Scalars['numeric']>;
  cash_counted?: Maybe<Scalars['numeric']>;
  cash_deposit?: Maybe<Scalars['numeric']>;
  cash_diff?: Maybe<Scalars['numeric']>;
  cash_in?: Maybe<Scalars['numeric']>;
  cash_sales?: Maybe<Scalars['numeric']>;
  cash_withdrawal?: Maybe<Scalars['numeric']>;
  created_by_user?: Maybe<Scalars['uuid']>;
  date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  number_of_reciepts?: Maybe<Scalars['numeric']>;
  number_of_returnedtems?: Maybe<Scalars['numeric']>;
  number_of_returns?: Maybe<Scalars['numeric']>;
  number_of_sales?: Maybe<Scalars['numeric']>;
  number_of_sold_items?: Maybe<Scalars['numeric']>;
  org_name?: Maybe<Scalars['String']>;
  org_number?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  pos_id?: Maybe<Scalars['uuid']>;
  pos_name?: Maybe<Scalars['String']>;
  reciept_number_high?: Maybe<Scalars['numeric']>;
  reciept_number_low?: Maybe<Scalars['numeric']>;
  report_number?: Maybe<Scalars['numeric']>;
  returns_total?: Maybe<Scalars['numeric']>;
  session_id?: Maybe<Scalars['uuid']>;
  tags?: Maybe<Scalars['String']>;
  total_amount?: Maybe<Scalars['numeric']>;
  total_sales?: Maybe<Scalars['numeric']>;
  total_sales_excl_vat?: Maybe<Scalars['numeric']>;
  total_vat?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "order_zreports" */
export type Order_Zreports_Min_Order_By = {
  avg_per_sale?: Maybe<Order_By>;
  cash_counted?: Maybe<Order_By>;
  cash_deposit?: Maybe<Order_By>;
  cash_diff?: Maybe<Order_By>;
  cash_in?: Maybe<Order_By>;
  cash_sales?: Maybe<Order_By>;
  cash_withdrawal?: Maybe<Order_By>;
  created_by_user?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  number_of_reciepts?: Maybe<Order_By>;
  number_of_returnedtems?: Maybe<Order_By>;
  number_of_returns?: Maybe<Order_By>;
  number_of_sales?: Maybe<Order_By>;
  number_of_sold_items?: Maybe<Order_By>;
  org_name?: Maybe<Order_By>;
  org_number?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  pos_id?: Maybe<Order_By>;
  pos_name?: Maybe<Order_By>;
  reciept_number_high?: Maybe<Order_By>;
  reciept_number_low?: Maybe<Order_By>;
  report_number?: Maybe<Order_By>;
  returns_total?: Maybe<Order_By>;
  session_id?: Maybe<Order_By>;
  tags?: Maybe<Order_By>;
  total_amount?: Maybe<Order_By>;
  total_sales?: Maybe<Order_By>;
  total_sales_excl_vat?: Maybe<Order_By>;
  total_vat?: Maybe<Order_By>;
};

/** response of any mutation on the table "order_zreports" */
export type Order_Zreports_Mutation_Response = {
  __typename?: 'order_zreports_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_Zreports>;
};

/** on conflict condition type for table "order_zreports" */
export type Order_Zreports_On_Conflict = {
  constraint: Order_Zreports_Constraint;
  update_columns?: Array<Order_Zreports_Update_Column>;
  where?: Maybe<Order_Zreports_Bool_Exp>;
};

/** Ordering options when selecting data from "order_zreports". */
export type Order_Zreports_Order_By = {
  avg_per_sale?: Maybe<Order_By>;
  cash_counted?: Maybe<Order_By>;
  cash_deposit?: Maybe<Order_By>;
  cash_diff?: Maybe<Order_By>;
  cash_in?: Maybe<Order_By>;
  cash_sales?: Maybe<Order_By>;
  cash_withdrawal?: Maybe<Order_By>;
  created_by_user?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  number_of_reciepts?: Maybe<Order_By>;
  number_of_returnedtems?: Maybe<Order_By>;
  number_of_returns?: Maybe<Order_By>;
  number_of_sales?: Maybe<Order_By>;
  number_of_sold_items?: Maybe<Order_By>;
  org_name?: Maybe<Order_By>;
  org_number?: Maybe<Order_By>;
  organization?: Maybe<Organizations_Order_By>;
  organization_id?: Maybe<Order_By>;
  pos_id?: Maybe<Order_By>;
  pos_name?: Maybe<Order_By>;
  reciept_number_high?: Maybe<Order_By>;
  reciept_number_low?: Maybe<Order_By>;
  report_number?: Maybe<Order_By>;
  returns_total?: Maybe<Order_By>;
  session_id?: Maybe<Order_By>;
  tags?: Maybe<Order_By>;
  total_amount?: Maybe<Order_By>;
  total_sales?: Maybe<Order_By>;
  total_sales_excl_vat?: Maybe<Order_By>;
  total_vat?: Maybe<Order_By>;
  vat_enabled?: Maybe<Order_By>;
};

/** primary key columns input for table: order_zreports */
export type Order_Zreports_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "order_zreports" */
export enum Order_Zreports_Select_Column {
  /** column name */
  AvgPerSale = 'avg_per_sale',
  /** column name */
  CashCounted = 'cash_counted',
  /** column name */
  CashDeposit = 'cash_deposit',
  /** column name */
  CashDiff = 'cash_diff',
  /** column name */
  CashIn = 'cash_in',
  /** column name */
  CashSales = 'cash_sales',
  /** column name */
  CashWithdrawal = 'cash_withdrawal',
  /** column name */
  CreatedByUser = 'created_by_user',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  NumberOfReciepts = 'number_of_reciepts',
  /** column name */
  NumberOfReturnedtems = 'number_of_returnedtems',
  /** column name */
  NumberOfReturns = 'number_of_returns',
  /** column name */
  NumberOfSales = 'number_of_sales',
  /** column name */
  NumberOfSoldItems = 'number_of_sold_items',
  /** column name */
  OrgName = 'org_name',
  /** column name */
  OrgNumber = 'org_number',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  PosId = 'pos_id',
  /** column name */
  PosName = 'pos_name',
  /** column name */
  RecieptNumberHigh = 'reciept_number_high',
  /** column name */
  RecieptNumberLow = 'reciept_number_low',
  /** column name */
  ReportNumber = 'report_number',
  /** column name */
  ReturnsTotal = 'returns_total',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  Tags = 'tags',
  /** column name */
  TotalAmount = 'total_amount',
  /** column name */
  TotalSales = 'total_sales',
  /** column name */
  TotalSalesExclVat = 'total_sales_excl_vat',
  /** column name */
  TotalVat = 'total_vat',
  /** column name */
  VatEnabled = 'vat_enabled'
}

/** input type for updating data in table "order_zreports" */
export type Order_Zreports_Set_Input = {
  avg_per_sale?: Maybe<Scalars['numeric']>;
  cash_counted?: Maybe<Scalars['numeric']>;
  cash_deposit?: Maybe<Scalars['numeric']>;
  cash_diff?: Maybe<Scalars['numeric']>;
  cash_in?: Maybe<Scalars['numeric']>;
  cash_sales?: Maybe<Scalars['numeric']>;
  cash_withdrawal?: Maybe<Scalars['numeric']>;
  created_by_user?: Maybe<Scalars['uuid']>;
  date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  number_of_reciepts?: Maybe<Scalars['numeric']>;
  number_of_returnedtems?: Maybe<Scalars['numeric']>;
  number_of_returns?: Maybe<Scalars['numeric']>;
  number_of_sales?: Maybe<Scalars['numeric']>;
  number_of_sold_items?: Maybe<Scalars['numeric']>;
  org_name?: Maybe<Scalars['String']>;
  org_number?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  pos_id?: Maybe<Scalars['uuid']>;
  pos_name?: Maybe<Scalars['String']>;
  reciept_number_high?: Maybe<Scalars['numeric']>;
  reciept_number_low?: Maybe<Scalars['numeric']>;
  report_number?: Maybe<Scalars['numeric']>;
  returns_total?: Maybe<Scalars['numeric']>;
  session_id?: Maybe<Scalars['uuid']>;
  tags?: Maybe<Scalars['String']>;
  total_amount?: Maybe<Scalars['numeric']>;
  total_sales?: Maybe<Scalars['numeric']>;
  total_sales_excl_vat?: Maybe<Scalars['numeric']>;
  total_vat?: Maybe<Scalars['numeric']>;
  vat_enabled?: Maybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Order_Zreports_Stddev_Fields = {
  __typename?: 'order_zreports_stddev_fields';
  avg_per_sale?: Maybe<Scalars['Float']>;
  cash_counted?: Maybe<Scalars['Float']>;
  cash_deposit?: Maybe<Scalars['Float']>;
  cash_diff?: Maybe<Scalars['Float']>;
  cash_in?: Maybe<Scalars['Float']>;
  cash_sales?: Maybe<Scalars['Float']>;
  cash_withdrawal?: Maybe<Scalars['Float']>;
  number_of_reciepts?: Maybe<Scalars['Float']>;
  number_of_returnedtems?: Maybe<Scalars['Float']>;
  number_of_returns?: Maybe<Scalars['Float']>;
  number_of_sales?: Maybe<Scalars['Float']>;
  number_of_sold_items?: Maybe<Scalars['Float']>;
  reciept_number_high?: Maybe<Scalars['Float']>;
  reciept_number_low?: Maybe<Scalars['Float']>;
  report_number?: Maybe<Scalars['Float']>;
  returns_total?: Maybe<Scalars['Float']>;
  total_amount?: Maybe<Scalars['Float']>;
  total_sales?: Maybe<Scalars['Float']>;
  total_sales_excl_vat?: Maybe<Scalars['Float']>;
  total_vat?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "order_zreports" */
export type Order_Zreports_Stddev_Order_By = {
  avg_per_sale?: Maybe<Order_By>;
  cash_counted?: Maybe<Order_By>;
  cash_deposit?: Maybe<Order_By>;
  cash_diff?: Maybe<Order_By>;
  cash_in?: Maybe<Order_By>;
  cash_sales?: Maybe<Order_By>;
  cash_withdrawal?: Maybe<Order_By>;
  number_of_reciepts?: Maybe<Order_By>;
  number_of_returnedtems?: Maybe<Order_By>;
  number_of_returns?: Maybe<Order_By>;
  number_of_sales?: Maybe<Order_By>;
  number_of_sold_items?: Maybe<Order_By>;
  reciept_number_high?: Maybe<Order_By>;
  reciept_number_low?: Maybe<Order_By>;
  report_number?: Maybe<Order_By>;
  returns_total?: Maybe<Order_By>;
  total_amount?: Maybe<Order_By>;
  total_sales?: Maybe<Order_By>;
  total_sales_excl_vat?: Maybe<Order_By>;
  total_vat?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Order_Zreports_Stddev_Pop_Fields = {
  __typename?: 'order_zreports_stddev_pop_fields';
  avg_per_sale?: Maybe<Scalars['Float']>;
  cash_counted?: Maybe<Scalars['Float']>;
  cash_deposit?: Maybe<Scalars['Float']>;
  cash_diff?: Maybe<Scalars['Float']>;
  cash_in?: Maybe<Scalars['Float']>;
  cash_sales?: Maybe<Scalars['Float']>;
  cash_withdrawal?: Maybe<Scalars['Float']>;
  number_of_reciepts?: Maybe<Scalars['Float']>;
  number_of_returnedtems?: Maybe<Scalars['Float']>;
  number_of_returns?: Maybe<Scalars['Float']>;
  number_of_sales?: Maybe<Scalars['Float']>;
  number_of_sold_items?: Maybe<Scalars['Float']>;
  reciept_number_high?: Maybe<Scalars['Float']>;
  reciept_number_low?: Maybe<Scalars['Float']>;
  report_number?: Maybe<Scalars['Float']>;
  returns_total?: Maybe<Scalars['Float']>;
  total_amount?: Maybe<Scalars['Float']>;
  total_sales?: Maybe<Scalars['Float']>;
  total_sales_excl_vat?: Maybe<Scalars['Float']>;
  total_vat?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "order_zreports" */
export type Order_Zreports_Stddev_Pop_Order_By = {
  avg_per_sale?: Maybe<Order_By>;
  cash_counted?: Maybe<Order_By>;
  cash_deposit?: Maybe<Order_By>;
  cash_diff?: Maybe<Order_By>;
  cash_in?: Maybe<Order_By>;
  cash_sales?: Maybe<Order_By>;
  cash_withdrawal?: Maybe<Order_By>;
  number_of_reciepts?: Maybe<Order_By>;
  number_of_returnedtems?: Maybe<Order_By>;
  number_of_returns?: Maybe<Order_By>;
  number_of_sales?: Maybe<Order_By>;
  number_of_sold_items?: Maybe<Order_By>;
  reciept_number_high?: Maybe<Order_By>;
  reciept_number_low?: Maybe<Order_By>;
  report_number?: Maybe<Order_By>;
  returns_total?: Maybe<Order_By>;
  total_amount?: Maybe<Order_By>;
  total_sales?: Maybe<Order_By>;
  total_sales_excl_vat?: Maybe<Order_By>;
  total_vat?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Order_Zreports_Stddev_Samp_Fields = {
  __typename?: 'order_zreports_stddev_samp_fields';
  avg_per_sale?: Maybe<Scalars['Float']>;
  cash_counted?: Maybe<Scalars['Float']>;
  cash_deposit?: Maybe<Scalars['Float']>;
  cash_diff?: Maybe<Scalars['Float']>;
  cash_in?: Maybe<Scalars['Float']>;
  cash_sales?: Maybe<Scalars['Float']>;
  cash_withdrawal?: Maybe<Scalars['Float']>;
  number_of_reciepts?: Maybe<Scalars['Float']>;
  number_of_returnedtems?: Maybe<Scalars['Float']>;
  number_of_returns?: Maybe<Scalars['Float']>;
  number_of_sales?: Maybe<Scalars['Float']>;
  number_of_sold_items?: Maybe<Scalars['Float']>;
  reciept_number_high?: Maybe<Scalars['Float']>;
  reciept_number_low?: Maybe<Scalars['Float']>;
  report_number?: Maybe<Scalars['Float']>;
  returns_total?: Maybe<Scalars['Float']>;
  total_amount?: Maybe<Scalars['Float']>;
  total_sales?: Maybe<Scalars['Float']>;
  total_sales_excl_vat?: Maybe<Scalars['Float']>;
  total_vat?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "order_zreports" */
export type Order_Zreports_Stddev_Samp_Order_By = {
  avg_per_sale?: Maybe<Order_By>;
  cash_counted?: Maybe<Order_By>;
  cash_deposit?: Maybe<Order_By>;
  cash_diff?: Maybe<Order_By>;
  cash_in?: Maybe<Order_By>;
  cash_sales?: Maybe<Order_By>;
  cash_withdrawal?: Maybe<Order_By>;
  number_of_reciepts?: Maybe<Order_By>;
  number_of_returnedtems?: Maybe<Order_By>;
  number_of_returns?: Maybe<Order_By>;
  number_of_sales?: Maybe<Order_By>;
  number_of_sold_items?: Maybe<Order_By>;
  reciept_number_high?: Maybe<Order_By>;
  reciept_number_low?: Maybe<Order_By>;
  report_number?: Maybe<Order_By>;
  returns_total?: Maybe<Order_By>;
  total_amount?: Maybe<Order_By>;
  total_sales?: Maybe<Order_By>;
  total_sales_excl_vat?: Maybe<Order_By>;
  total_vat?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Order_Zreports_Sum_Fields = {
  __typename?: 'order_zreports_sum_fields';
  avg_per_sale?: Maybe<Scalars['numeric']>;
  cash_counted?: Maybe<Scalars['numeric']>;
  cash_deposit?: Maybe<Scalars['numeric']>;
  cash_diff?: Maybe<Scalars['numeric']>;
  cash_in?: Maybe<Scalars['numeric']>;
  cash_sales?: Maybe<Scalars['numeric']>;
  cash_withdrawal?: Maybe<Scalars['numeric']>;
  number_of_reciepts?: Maybe<Scalars['numeric']>;
  number_of_returnedtems?: Maybe<Scalars['numeric']>;
  number_of_returns?: Maybe<Scalars['numeric']>;
  number_of_sales?: Maybe<Scalars['numeric']>;
  number_of_sold_items?: Maybe<Scalars['numeric']>;
  reciept_number_high?: Maybe<Scalars['numeric']>;
  reciept_number_low?: Maybe<Scalars['numeric']>;
  report_number?: Maybe<Scalars['numeric']>;
  returns_total?: Maybe<Scalars['numeric']>;
  total_amount?: Maybe<Scalars['numeric']>;
  total_sales?: Maybe<Scalars['numeric']>;
  total_sales_excl_vat?: Maybe<Scalars['numeric']>;
  total_vat?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "order_zreports" */
export type Order_Zreports_Sum_Order_By = {
  avg_per_sale?: Maybe<Order_By>;
  cash_counted?: Maybe<Order_By>;
  cash_deposit?: Maybe<Order_By>;
  cash_diff?: Maybe<Order_By>;
  cash_in?: Maybe<Order_By>;
  cash_sales?: Maybe<Order_By>;
  cash_withdrawal?: Maybe<Order_By>;
  number_of_reciepts?: Maybe<Order_By>;
  number_of_returnedtems?: Maybe<Order_By>;
  number_of_returns?: Maybe<Order_By>;
  number_of_sales?: Maybe<Order_By>;
  number_of_sold_items?: Maybe<Order_By>;
  reciept_number_high?: Maybe<Order_By>;
  reciept_number_low?: Maybe<Order_By>;
  report_number?: Maybe<Order_By>;
  returns_total?: Maybe<Order_By>;
  total_amount?: Maybe<Order_By>;
  total_sales?: Maybe<Order_By>;
  total_sales_excl_vat?: Maybe<Order_By>;
  total_vat?: Maybe<Order_By>;
};

/** update columns of table "order_zreports" */
export enum Order_Zreports_Update_Column {
  /** column name */
  AvgPerSale = 'avg_per_sale',
  /** column name */
  CashCounted = 'cash_counted',
  /** column name */
  CashDeposit = 'cash_deposit',
  /** column name */
  CashDiff = 'cash_diff',
  /** column name */
  CashIn = 'cash_in',
  /** column name */
  CashSales = 'cash_sales',
  /** column name */
  CashWithdrawal = 'cash_withdrawal',
  /** column name */
  CreatedByUser = 'created_by_user',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  NumberOfReciepts = 'number_of_reciepts',
  /** column name */
  NumberOfReturnedtems = 'number_of_returnedtems',
  /** column name */
  NumberOfReturns = 'number_of_returns',
  /** column name */
  NumberOfSales = 'number_of_sales',
  /** column name */
  NumberOfSoldItems = 'number_of_sold_items',
  /** column name */
  OrgName = 'org_name',
  /** column name */
  OrgNumber = 'org_number',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  PosId = 'pos_id',
  /** column name */
  PosName = 'pos_name',
  /** column name */
  RecieptNumberHigh = 'reciept_number_high',
  /** column name */
  RecieptNumberLow = 'reciept_number_low',
  /** column name */
  ReportNumber = 'report_number',
  /** column name */
  ReturnsTotal = 'returns_total',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  Tags = 'tags',
  /** column name */
  TotalAmount = 'total_amount',
  /** column name */
  TotalSales = 'total_sales',
  /** column name */
  TotalSalesExclVat = 'total_sales_excl_vat',
  /** column name */
  TotalVat = 'total_vat',
  /** column name */
  VatEnabled = 'vat_enabled'
}

/** aggregate var_pop on columns */
export type Order_Zreports_Var_Pop_Fields = {
  __typename?: 'order_zreports_var_pop_fields';
  avg_per_sale?: Maybe<Scalars['Float']>;
  cash_counted?: Maybe<Scalars['Float']>;
  cash_deposit?: Maybe<Scalars['Float']>;
  cash_diff?: Maybe<Scalars['Float']>;
  cash_in?: Maybe<Scalars['Float']>;
  cash_sales?: Maybe<Scalars['Float']>;
  cash_withdrawal?: Maybe<Scalars['Float']>;
  number_of_reciepts?: Maybe<Scalars['Float']>;
  number_of_returnedtems?: Maybe<Scalars['Float']>;
  number_of_returns?: Maybe<Scalars['Float']>;
  number_of_sales?: Maybe<Scalars['Float']>;
  number_of_sold_items?: Maybe<Scalars['Float']>;
  reciept_number_high?: Maybe<Scalars['Float']>;
  reciept_number_low?: Maybe<Scalars['Float']>;
  report_number?: Maybe<Scalars['Float']>;
  returns_total?: Maybe<Scalars['Float']>;
  total_amount?: Maybe<Scalars['Float']>;
  total_sales?: Maybe<Scalars['Float']>;
  total_sales_excl_vat?: Maybe<Scalars['Float']>;
  total_vat?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "order_zreports" */
export type Order_Zreports_Var_Pop_Order_By = {
  avg_per_sale?: Maybe<Order_By>;
  cash_counted?: Maybe<Order_By>;
  cash_deposit?: Maybe<Order_By>;
  cash_diff?: Maybe<Order_By>;
  cash_in?: Maybe<Order_By>;
  cash_sales?: Maybe<Order_By>;
  cash_withdrawal?: Maybe<Order_By>;
  number_of_reciepts?: Maybe<Order_By>;
  number_of_returnedtems?: Maybe<Order_By>;
  number_of_returns?: Maybe<Order_By>;
  number_of_sales?: Maybe<Order_By>;
  number_of_sold_items?: Maybe<Order_By>;
  reciept_number_high?: Maybe<Order_By>;
  reciept_number_low?: Maybe<Order_By>;
  report_number?: Maybe<Order_By>;
  returns_total?: Maybe<Order_By>;
  total_amount?: Maybe<Order_By>;
  total_sales?: Maybe<Order_By>;
  total_sales_excl_vat?: Maybe<Order_By>;
  total_vat?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Order_Zreports_Var_Samp_Fields = {
  __typename?: 'order_zreports_var_samp_fields';
  avg_per_sale?: Maybe<Scalars['Float']>;
  cash_counted?: Maybe<Scalars['Float']>;
  cash_deposit?: Maybe<Scalars['Float']>;
  cash_diff?: Maybe<Scalars['Float']>;
  cash_in?: Maybe<Scalars['Float']>;
  cash_sales?: Maybe<Scalars['Float']>;
  cash_withdrawal?: Maybe<Scalars['Float']>;
  number_of_reciepts?: Maybe<Scalars['Float']>;
  number_of_returnedtems?: Maybe<Scalars['Float']>;
  number_of_returns?: Maybe<Scalars['Float']>;
  number_of_sales?: Maybe<Scalars['Float']>;
  number_of_sold_items?: Maybe<Scalars['Float']>;
  reciept_number_high?: Maybe<Scalars['Float']>;
  reciept_number_low?: Maybe<Scalars['Float']>;
  report_number?: Maybe<Scalars['Float']>;
  returns_total?: Maybe<Scalars['Float']>;
  total_amount?: Maybe<Scalars['Float']>;
  total_sales?: Maybe<Scalars['Float']>;
  total_sales_excl_vat?: Maybe<Scalars['Float']>;
  total_vat?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "order_zreports" */
export type Order_Zreports_Var_Samp_Order_By = {
  avg_per_sale?: Maybe<Order_By>;
  cash_counted?: Maybe<Order_By>;
  cash_deposit?: Maybe<Order_By>;
  cash_diff?: Maybe<Order_By>;
  cash_in?: Maybe<Order_By>;
  cash_sales?: Maybe<Order_By>;
  cash_withdrawal?: Maybe<Order_By>;
  number_of_reciepts?: Maybe<Order_By>;
  number_of_returnedtems?: Maybe<Order_By>;
  number_of_returns?: Maybe<Order_By>;
  number_of_sales?: Maybe<Order_By>;
  number_of_sold_items?: Maybe<Order_By>;
  reciept_number_high?: Maybe<Order_By>;
  reciept_number_low?: Maybe<Order_By>;
  report_number?: Maybe<Order_By>;
  returns_total?: Maybe<Order_By>;
  total_amount?: Maybe<Order_By>;
  total_sales?: Maybe<Order_By>;
  total_sales_excl_vat?: Maybe<Order_By>;
  total_vat?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Order_Zreports_Variance_Fields = {
  __typename?: 'order_zreports_variance_fields';
  avg_per_sale?: Maybe<Scalars['Float']>;
  cash_counted?: Maybe<Scalars['Float']>;
  cash_deposit?: Maybe<Scalars['Float']>;
  cash_diff?: Maybe<Scalars['Float']>;
  cash_in?: Maybe<Scalars['Float']>;
  cash_sales?: Maybe<Scalars['Float']>;
  cash_withdrawal?: Maybe<Scalars['Float']>;
  number_of_reciepts?: Maybe<Scalars['Float']>;
  number_of_returnedtems?: Maybe<Scalars['Float']>;
  number_of_returns?: Maybe<Scalars['Float']>;
  number_of_sales?: Maybe<Scalars['Float']>;
  number_of_sold_items?: Maybe<Scalars['Float']>;
  reciept_number_high?: Maybe<Scalars['Float']>;
  reciept_number_low?: Maybe<Scalars['Float']>;
  report_number?: Maybe<Scalars['Float']>;
  returns_total?: Maybe<Scalars['Float']>;
  total_amount?: Maybe<Scalars['Float']>;
  total_sales?: Maybe<Scalars['Float']>;
  total_sales_excl_vat?: Maybe<Scalars['Float']>;
  total_vat?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "order_zreports" */
export type Order_Zreports_Variance_Order_By = {
  avg_per_sale?: Maybe<Order_By>;
  cash_counted?: Maybe<Order_By>;
  cash_deposit?: Maybe<Order_By>;
  cash_diff?: Maybe<Order_By>;
  cash_in?: Maybe<Order_By>;
  cash_sales?: Maybe<Order_By>;
  cash_withdrawal?: Maybe<Order_By>;
  number_of_reciepts?: Maybe<Order_By>;
  number_of_returnedtems?: Maybe<Order_By>;
  number_of_returns?: Maybe<Order_By>;
  number_of_sales?: Maybe<Order_By>;
  number_of_sold_items?: Maybe<Order_By>;
  reciept_number_high?: Maybe<Order_By>;
  reciept_number_low?: Maybe<Order_By>;
  report_number?: Maybe<Order_By>;
  returns_total?: Maybe<Order_By>;
  total_amount?: Maybe<Order_By>;
  total_sales?: Maybe<Order_By>;
  total_sales_excl_vat?: Maybe<Order_By>;
  total_vat?: Maybe<Order_By>;
};

/** columns and relationships of "orders" */
export type Orders = {
  __typename?: 'orders';
  amount_paied: Scalars['numeric'];
  basket_id: Scalars['uuid'];
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  number_of_items: Scalars['Int'];
  organization_id: Scalars['uuid'];
  payment_method: Scalars['String'];
  payment_receipent: Scalars['String'];
  payment_reference: Scalars['String'];
  pos_id: Scalars['uuid'];
  receipt_number: Scalars['Int'];
  status: Scalars['String'];
  transaction_direction: Scalars['String'];
  transaction_reference: Scalars['String'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "orders" */
export type Orders_Aggregate = {
  __typename?: 'orders_aggregate';
  aggregate?: Maybe<Orders_Aggregate_Fields>;
  nodes: Array<Orders>;
};

/** aggregate fields of "orders" */
export type Orders_Aggregate_Fields = {
  __typename?: 'orders_aggregate_fields';
  avg?: Maybe<Orders_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Orders_Max_Fields>;
  min?: Maybe<Orders_Min_Fields>;
  stddev?: Maybe<Orders_Stddev_Fields>;
  stddev_pop?: Maybe<Orders_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Orders_Stddev_Samp_Fields>;
  sum?: Maybe<Orders_Sum_Fields>;
  var_pop?: Maybe<Orders_Var_Pop_Fields>;
  var_samp?: Maybe<Orders_Var_Samp_Fields>;
  variance?: Maybe<Orders_Variance_Fields>;
};


/** aggregate fields of "orders" */
export type Orders_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Orders_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "orders" */
export type Orders_Aggregate_Order_By = {
  avg?: Maybe<Orders_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Orders_Max_Order_By>;
  min?: Maybe<Orders_Min_Order_By>;
  stddev?: Maybe<Orders_Stddev_Order_By>;
  stddev_pop?: Maybe<Orders_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Orders_Stddev_Samp_Order_By>;
  sum?: Maybe<Orders_Sum_Order_By>;
  var_pop?: Maybe<Orders_Var_Pop_Order_By>;
  var_samp?: Maybe<Orders_Var_Samp_Order_By>;
  variance?: Maybe<Orders_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "orders" */
export type Orders_Arr_Rel_Insert_Input = {
  data: Array<Orders_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Orders_On_Conflict>;
};

/** aggregate avg on columns */
export type Orders_Avg_Fields = {
  __typename?: 'orders_avg_fields';
  amount_paied?: Maybe<Scalars['Float']>;
  number_of_items?: Maybe<Scalars['Float']>;
  receipt_number?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "orders" */
export type Orders_Avg_Order_By = {
  amount_paied?: Maybe<Order_By>;
  number_of_items?: Maybe<Order_By>;
  receipt_number?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "orders". All fields are combined with a logical 'AND'. */
export type Orders_Bool_Exp = {
  _and?: Maybe<Array<Orders_Bool_Exp>>;
  _not?: Maybe<Orders_Bool_Exp>;
  _or?: Maybe<Array<Orders_Bool_Exp>>;
  amount_paied?: Maybe<Numeric_Comparison_Exp>;
  basket_id?: Maybe<Uuid_Comparison_Exp>;
  created?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  number_of_items?: Maybe<Int_Comparison_Exp>;
  organization_id?: Maybe<Uuid_Comparison_Exp>;
  payment_method?: Maybe<String_Comparison_Exp>;
  payment_receipent?: Maybe<String_Comparison_Exp>;
  payment_reference?: Maybe<String_Comparison_Exp>;
  pos_id?: Maybe<Uuid_Comparison_Exp>;
  receipt_number?: Maybe<Int_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  transaction_direction?: Maybe<String_Comparison_Exp>;
  transaction_reference?: Maybe<String_Comparison_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "orders" */
export enum Orders_Constraint {
  /** unique or primary key constraint */
  OrdersPkey = 'orders_pkey'
}

/** input type for incrementing numeric columns in table "orders" */
export type Orders_Inc_Input = {
  amount_paied?: Maybe<Scalars['numeric']>;
  number_of_items?: Maybe<Scalars['Int']>;
  receipt_number?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "orders" */
export type Orders_Insert_Input = {
  amount_paied?: Maybe<Scalars['numeric']>;
  basket_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  number_of_items?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['uuid']>;
  payment_method?: Maybe<Scalars['String']>;
  payment_receipent?: Maybe<Scalars['String']>;
  payment_reference?: Maybe<Scalars['String']>;
  pos_id?: Maybe<Scalars['uuid']>;
  receipt_number?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  transaction_direction?: Maybe<Scalars['String']>;
  transaction_reference?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Orders_Max_Fields = {
  __typename?: 'orders_max_fields';
  amount_paied?: Maybe<Scalars['numeric']>;
  basket_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  number_of_items?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['uuid']>;
  payment_method?: Maybe<Scalars['String']>;
  payment_receipent?: Maybe<Scalars['String']>;
  payment_reference?: Maybe<Scalars['String']>;
  pos_id?: Maybe<Scalars['uuid']>;
  receipt_number?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  transaction_direction?: Maybe<Scalars['String']>;
  transaction_reference?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "orders" */
export type Orders_Max_Order_By = {
  amount_paied?: Maybe<Order_By>;
  basket_id?: Maybe<Order_By>;
  created?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  number_of_items?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  payment_method?: Maybe<Order_By>;
  payment_receipent?: Maybe<Order_By>;
  payment_reference?: Maybe<Order_By>;
  pos_id?: Maybe<Order_By>;
  receipt_number?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  transaction_direction?: Maybe<Order_By>;
  transaction_reference?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Orders_Min_Fields = {
  __typename?: 'orders_min_fields';
  amount_paied?: Maybe<Scalars['numeric']>;
  basket_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  number_of_items?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['uuid']>;
  payment_method?: Maybe<Scalars['String']>;
  payment_receipent?: Maybe<Scalars['String']>;
  payment_reference?: Maybe<Scalars['String']>;
  pos_id?: Maybe<Scalars['uuid']>;
  receipt_number?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  transaction_direction?: Maybe<Scalars['String']>;
  transaction_reference?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "orders" */
export type Orders_Min_Order_By = {
  amount_paied?: Maybe<Order_By>;
  basket_id?: Maybe<Order_By>;
  created?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  number_of_items?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  payment_method?: Maybe<Order_By>;
  payment_receipent?: Maybe<Order_By>;
  payment_reference?: Maybe<Order_By>;
  pos_id?: Maybe<Order_By>;
  receipt_number?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  transaction_direction?: Maybe<Order_By>;
  transaction_reference?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "orders" */
export type Orders_Mutation_Response = {
  __typename?: 'orders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Orders>;
};

/** on conflict condition type for table "orders" */
export type Orders_On_Conflict = {
  constraint: Orders_Constraint;
  update_columns?: Array<Orders_Update_Column>;
  where?: Maybe<Orders_Bool_Exp>;
};

/** Ordering options when selecting data from "orders". */
export type Orders_Order_By = {
  amount_paied?: Maybe<Order_By>;
  basket_id?: Maybe<Order_By>;
  created?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  number_of_items?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  payment_method?: Maybe<Order_By>;
  payment_receipent?: Maybe<Order_By>;
  payment_reference?: Maybe<Order_By>;
  pos_id?: Maybe<Order_By>;
  receipt_number?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  transaction_direction?: Maybe<Order_By>;
  transaction_reference?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: orders */
export type Orders_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "orders" */
export enum Orders_Select_Column {
  /** column name */
  AmountPaied = 'amount_paied',
  /** column name */
  BasketId = 'basket_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  NumberOfItems = 'number_of_items',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  PaymentMethod = 'payment_method',
  /** column name */
  PaymentReceipent = 'payment_receipent',
  /** column name */
  PaymentReference = 'payment_reference',
  /** column name */
  PosId = 'pos_id',
  /** column name */
  ReceiptNumber = 'receipt_number',
  /** column name */
  Status = 'status',
  /** column name */
  TransactionDirection = 'transaction_direction',
  /** column name */
  TransactionReference = 'transaction_reference',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "orders" */
export type Orders_Set_Input = {
  amount_paied?: Maybe<Scalars['numeric']>;
  basket_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  number_of_items?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['uuid']>;
  payment_method?: Maybe<Scalars['String']>;
  payment_receipent?: Maybe<Scalars['String']>;
  payment_reference?: Maybe<Scalars['String']>;
  pos_id?: Maybe<Scalars['uuid']>;
  receipt_number?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  transaction_direction?: Maybe<Scalars['String']>;
  transaction_reference?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Orders_Stddev_Fields = {
  __typename?: 'orders_stddev_fields';
  amount_paied?: Maybe<Scalars['Float']>;
  number_of_items?: Maybe<Scalars['Float']>;
  receipt_number?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "orders" */
export type Orders_Stddev_Order_By = {
  amount_paied?: Maybe<Order_By>;
  number_of_items?: Maybe<Order_By>;
  receipt_number?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Orders_Stddev_Pop_Fields = {
  __typename?: 'orders_stddev_pop_fields';
  amount_paied?: Maybe<Scalars['Float']>;
  number_of_items?: Maybe<Scalars['Float']>;
  receipt_number?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "orders" */
export type Orders_Stddev_Pop_Order_By = {
  amount_paied?: Maybe<Order_By>;
  number_of_items?: Maybe<Order_By>;
  receipt_number?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Orders_Stddev_Samp_Fields = {
  __typename?: 'orders_stddev_samp_fields';
  amount_paied?: Maybe<Scalars['Float']>;
  number_of_items?: Maybe<Scalars['Float']>;
  receipt_number?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "orders" */
export type Orders_Stddev_Samp_Order_By = {
  amount_paied?: Maybe<Order_By>;
  number_of_items?: Maybe<Order_By>;
  receipt_number?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Orders_Sum_Fields = {
  __typename?: 'orders_sum_fields';
  amount_paied?: Maybe<Scalars['numeric']>;
  number_of_items?: Maybe<Scalars['Int']>;
  receipt_number?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "orders" */
export type Orders_Sum_Order_By = {
  amount_paied?: Maybe<Order_By>;
  number_of_items?: Maybe<Order_By>;
  receipt_number?: Maybe<Order_By>;
};

/** update columns of table "orders" */
export enum Orders_Update_Column {
  /** column name */
  AmountPaied = 'amount_paied',
  /** column name */
  BasketId = 'basket_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  NumberOfItems = 'number_of_items',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  PaymentMethod = 'payment_method',
  /** column name */
  PaymentReceipent = 'payment_receipent',
  /** column name */
  PaymentReference = 'payment_reference',
  /** column name */
  PosId = 'pos_id',
  /** column name */
  ReceiptNumber = 'receipt_number',
  /** column name */
  Status = 'status',
  /** column name */
  TransactionDirection = 'transaction_direction',
  /** column name */
  TransactionReference = 'transaction_reference',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Orders_Var_Pop_Fields = {
  __typename?: 'orders_var_pop_fields';
  amount_paied?: Maybe<Scalars['Float']>;
  number_of_items?: Maybe<Scalars['Float']>;
  receipt_number?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "orders" */
export type Orders_Var_Pop_Order_By = {
  amount_paied?: Maybe<Order_By>;
  number_of_items?: Maybe<Order_By>;
  receipt_number?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Orders_Var_Samp_Fields = {
  __typename?: 'orders_var_samp_fields';
  amount_paied?: Maybe<Scalars['Float']>;
  number_of_items?: Maybe<Scalars['Float']>;
  receipt_number?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "orders" */
export type Orders_Var_Samp_Order_By = {
  amount_paied?: Maybe<Order_By>;
  number_of_items?: Maybe<Order_By>;
  receipt_number?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Orders_Variance_Fields = {
  __typename?: 'orders_variance_fields';
  amount_paied?: Maybe<Scalars['Float']>;
  number_of_items?: Maybe<Scalars['Float']>;
  receipt_number?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "orders" */
export type Orders_Variance_Order_By = {
  amount_paied?: Maybe<Order_By>;
  number_of_items?: Maybe<Order_By>;
  receipt_number?: Maybe<Order_By>;
};

/** columns and relationships of "organization_users" */
export type Organization_Users = {
  __typename?: 'organization_users';
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  group?: Maybe<Groups>;
  group_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid'];
  role: Scalars['String'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "organization_users" */
export type Organization_Users_Aggregate = {
  __typename?: 'organization_users_aggregate';
  aggregate?: Maybe<Organization_Users_Aggregate_Fields>;
  nodes: Array<Organization_Users>;
};

/** aggregate fields of "organization_users" */
export type Organization_Users_Aggregate_Fields = {
  __typename?: 'organization_users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Organization_Users_Max_Fields>;
  min?: Maybe<Organization_Users_Min_Fields>;
};


/** aggregate fields of "organization_users" */
export type Organization_Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Organization_Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "organization_users" */
export type Organization_Users_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Organization_Users_Max_Order_By>;
  min?: Maybe<Organization_Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "organization_users" */
export type Organization_Users_Arr_Rel_Insert_Input = {
  data: Array<Organization_Users_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Organization_Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "organization_users". All fields are combined with a logical 'AND'. */
export type Organization_Users_Bool_Exp = {
  _and?: Maybe<Array<Organization_Users_Bool_Exp>>;
  _not?: Maybe<Organization_Users_Bool_Exp>;
  _or?: Maybe<Array<Organization_Users_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  group?: Maybe<Groups_Bool_Exp>;
  group_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  organization?: Maybe<Organizations_Bool_Exp>;
  organization_id?: Maybe<Uuid_Comparison_Exp>;
  role?: Maybe<String_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "organization_users" */
export enum Organization_Users_Constraint {
  /** unique or primary key constraint */
  OrganizationUsersOrganizationIdUserIdGroupIdKey = 'organization_users_organization_id_user_id_group_id_key',
  /** unique or primary key constraint */
  OrganizationUsersPkey = 'organization_users_pkey'
}

/** input type for inserting data into table "organization_users" */
export type Organization_Users_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  group?: Maybe<Groups_Obj_Rel_Insert_Input>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  organization?: Maybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Organization_Users_Max_Fields = {
  __typename?: 'organization_users_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "organization_users" */
export type Organization_Users_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Organization_Users_Min_Fields = {
  __typename?: 'organization_users_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "organization_users" */
export type Organization_Users_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "organization_users" */
export type Organization_Users_Mutation_Response = {
  __typename?: 'organization_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization_Users>;
};

/** on conflict condition type for table "organization_users" */
export type Organization_Users_On_Conflict = {
  constraint: Organization_Users_Constraint;
  update_columns?: Array<Organization_Users_Update_Column>;
  where?: Maybe<Organization_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_users". */
export type Organization_Users_Order_By = {
  created_at?: Maybe<Order_By>;
  group?: Maybe<Groups_Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organization?: Maybe<Organizations_Order_By>;
  organization_id?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: organization_users */
export type Organization_Users_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "organization_users" */
export enum Organization_Users_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Role = 'role',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "organization_users" */
export type Organization_Users_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "organization_users" */
export enum Organization_Users_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Role = 'role',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "organizations" */
export type Organizations = {
  __typename?: 'organizations';
  active: Scalars['Boolean'];
  active_agreement: Scalars['Boolean'];
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  admin_only_reports: Scalars['Boolean'];
  agreement_code: Scalars['String'];
  /** An array relationship */
  agreement_contacts: Array<Agreement_Contacts>;
  /** An aggregate relationship */
  agreement_contacts_aggregate: Agreement_Contacts_Aggregate;
  /** An object relationship */
  agreement_type?: Maybe<Agreement_Types>;
  billing_address1?: Maybe<Scalars['String']>;
  billing_address2?: Maybe<Scalars['String']>;
  billing_city?: Maybe<Scalars['String']>;
  billing_email?: Maybe<Scalars['String']>;
  billing_zip?: Maybe<Scalars['String']>;
  /** An array relationship */
  categories: Array<Categories>;
  /** An aggregate relationship */
  categories_aggregate: Categories_Aggregate;
  city?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['uuid']>;
  customer_number?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  demomode: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  /** An array relationship */
  groups: Array<Groups>;
  /** An aggregate relationship */
  groups_aggregate: Groups_Aggregate;
  guest_users: Scalars['Boolean'];
  guest_users_invite: Scalars['numeric'];
  icon: Scalars['String'];
  id: Scalars['uuid'];
  logo_id?: Maybe<Scalars['uuid']>;
  name: Scalars['String'];
  organization_number: Scalars['String'];
  /** An array relationship */
  organization_users: Array<Organization_Users>;
  /** An aggregate relationship */
  organization_users_aggregate: Organization_Users_Aggregate;
  /** An array relationship */
  participants: Array<Participants>;
  /** An aggregate relationship */
  participants_aggregate: Participants_Aggregate;
  /** An array relationship */
  paymentmethods: Array<Paymentmethods>;
  /** An aggregate relationship */
  paymentmethods_aggregate: Paymentmethods_Aggregate;
  phone?: Maybe<Scalars['String']>;
  /** fetch data from the table: "pointofsales" */
  pointofsales: Array<Pointofsales>;
  /** An aggregate relationship */
  pointofsales_aggregate: Pointofsales_Aggregate;
  privateperson: Scalars['Boolean'];
  /** An array relationship */
  products: Array<Products>;
  /** An aggregate relationship */
  products_aggregate: Products_Aggregate;
  receipttextfoot?: Maybe<Scalars['String']>;
  receipttexthead?: Maybe<Scalars['String']>;
  registered_for_vat: Scalars['Boolean'];
  report_recievers?: Maybe<Scalars['String']>;
  responsible_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  tags: Array<Tags>;
  /** An aggregate relationship */
  tags_aggregate: Tags_Aggregate;
  /** An array relationship */
  templates: Array<Templates>;
  /** An aggregate relationship */
  templates_aggregate: Templates_Aggregate;
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['uuid']>;
  web?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  /** An array relationship */
  zreports: Array<Order_Zreports>;
  /** An aggregate relationship */
  zreports_aggregate: Order_Zreports_Aggregate;
};


/** columns and relationships of "organizations" */
export type OrganizationsAgreement_ContactsArgs = {
  distinct_on?: Maybe<Array<Agreement_Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agreement_Contacts_Order_By>>;
  where?: Maybe<Agreement_Contacts_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsAgreement_Contacts_AggregateArgs = {
  distinct_on?: Maybe<Array<Agreement_Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agreement_Contacts_Order_By>>;
  where?: Maybe<Agreement_Contacts_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsCategoriesArgs = {
  distinct_on?: Maybe<Array<Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Categories_Order_By>>;
  where?: Maybe<Categories_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsCategories_AggregateArgs = {
  distinct_on?: Maybe<Array<Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Categories_Order_By>>;
  where?: Maybe<Categories_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsGroupsArgs = {
  distinct_on?: Maybe<Array<Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Groups_Order_By>>;
  where?: Maybe<Groups_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsGroups_AggregateArgs = {
  distinct_on?: Maybe<Array<Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Groups_Order_By>>;
  where?: Maybe<Groups_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsOrganization_UsersArgs = {
  distinct_on?: Maybe<Array<Organization_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organization_Users_Order_By>>;
  where?: Maybe<Organization_Users_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsOrganization_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Organization_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organization_Users_Order_By>>;
  where?: Maybe<Organization_Users_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsParticipantsArgs = {
  distinct_on?: Maybe<Array<Participants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Participants_Order_By>>;
  where?: Maybe<Participants_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsParticipants_AggregateArgs = {
  distinct_on?: Maybe<Array<Participants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Participants_Order_By>>;
  where?: Maybe<Participants_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsPaymentmethodsArgs = {
  distinct_on?: Maybe<Array<Paymentmethods_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Paymentmethods_Order_By>>;
  where?: Maybe<Paymentmethods_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsPaymentmethods_AggregateArgs = {
  distinct_on?: Maybe<Array<Paymentmethods_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Paymentmethods_Order_By>>;
  where?: Maybe<Paymentmethods_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsPointofsalesArgs = {
  distinct_on?: Maybe<Array<Pointofsales_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pointofsales_Order_By>>;
  where?: Maybe<Pointofsales_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsPointofsales_AggregateArgs = {
  distinct_on?: Maybe<Array<Pointofsales_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pointofsales_Order_By>>;
  where?: Maybe<Pointofsales_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsProductsArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsProducts_AggregateArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsTagsArgs = {
  distinct_on?: Maybe<Array<Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tags_Order_By>>;
  where?: Maybe<Tags_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsTags_AggregateArgs = {
  distinct_on?: Maybe<Array<Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tags_Order_By>>;
  where?: Maybe<Tags_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsTemplatesArgs = {
  distinct_on?: Maybe<Array<Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Templates_Order_By>>;
  where?: Maybe<Templates_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsTemplates_AggregateArgs = {
  distinct_on?: Maybe<Array<Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Templates_Order_By>>;
  where?: Maybe<Templates_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsZreportsArgs = {
  distinct_on?: Maybe<Array<Order_Zreports_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Zreports_Order_By>>;
  where?: Maybe<Order_Zreports_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsZreports_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Zreports_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Zreports_Order_By>>;
  where?: Maybe<Order_Zreports_Bool_Exp>;
};

/** aggregated selection of "organizations" */
export type Organizations_Aggregate = {
  __typename?: 'organizations_aggregate';
  aggregate?: Maybe<Organizations_Aggregate_Fields>;
  nodes: Array<Organizations>;
};

/** aggregate fields of "organizations" */
export type Organizations_Aggregate_Fields = {
  __typename?: 'organizations_aggregate_fields';
  avg?: Maybe<Organizations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Organizations_Max_Fields>;
  min?: Maybe<Organizations_Min_Fields>;
  stddev?: Maybe<Organizations_Stddev_Fields>;
  stddev_pop?: Maybe<Organizations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Organizations_Stddev_Samp_Fields>;
  sum?: Maybe<Organizations_Sum_Fields>;
  var_pop?: Maybe<Organizations_Var_Pop_Fields>;
  var_samp?: Maybe<Organizations_Var_Samp_Fields>;
  variance?: Maybe<Organizations_Variance_Fields>;
};


/** aggregate fields of "organizations" */
export type Organizations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Organizations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "organizations" */
export type Organizations_Aggregate_Order_By = {
  avg?: Maybe<Organizations_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Organizations_Max_Order_By>;
  min?: Maybe<Organizations_Min_Order_By>;
  stddev?: Maybe<Organizations_Stddev_Order_By>;
  stddev_pop?: Maybe<Organizations_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Organizations_Stddev_Samp_Order_By>;
  sum?: Maybe<Organizations_Sum_Order_By>;
  var_pop?: Maybe<Organizations_Var_Pop_Order_By>;
  var_samp?: Maybe<Organizations_Var_Samp_Order_By>;
  variance?: Maybe<Organizations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "organizations" */
export type Organizations_Arr_Rel_Insert_Input = {
  data: Array<Organizations_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Organizations_On_Conflict>;
};

/** aggregate avg on columns */
export type Organizations_Avg_Fields = {
  __typename?: 'organizations_avg_fields';
  guest_users_invite?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "organizations" */
export type Organizations_Avg_Order_By = {
  guest_users_invite?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "organizations". All fields are combined with a logical 'AND'. */
export type Organizations_Bool_Exp = {
  _and?: Maybe<Array<Organizations_Bool_Exp>>;
  _not?: Maybe<Organizations_Bool_Exp>;
  _or?: Maybe<Array<Organizations_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  active_agreement?: Maybe<Boolean_Comparison_Exp>;
  address1?: Maybe<String_Comparison_Exp>;
  address2?: Maybe<String_Comparison_Exp>;
  admin_only_reports?: Maybe<Boolean_Comparison_Exp>;
  agreement_code?: Maybe<String_Comparison_Exp>;
  agreement_contacts?: Maybe<Agreement_Contacts_Bool_Exp>;
  agreement_type?: Maybe<Agreement_Types_Bool_Exp>;
  billing_address1?: Maybe<String_Comparison_Exp>;
  billing_address2?: Maybe<String_Comparison_Exp>;
  billing_city?: Maybe<String_Comparison_Exp>;
  billing_email?: Maybe<String_Comparison_Exp>;
  billing_zip?: Maybe<String_Comparison_Exp>;
  categories?: Maybe<Categories_Bool_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Uuid_Comparison_Exp>;
  customer_number?: Maybe<String_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_by?: Maybe<Uuid_Comparison_Exp>;
  demomode?: Maybe<Boolean_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  groups?: Maybe<Groups_Bool_Exp>;
  guest_users?: Maybe<Boolean_Comparison_Exp>;
  guest_users_invite?: Maybe<Numeric_Comparison_Exp>;
  icon?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  logo_id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  organization_number?: Maybe<String_Comparison_Exp>;
  organization_users?: Maybe<Organization_Users_Bool_Exp>;
  participants?: Maybe<Participants_Bool_Exp>;
  paymentmethods?: Maybe<Paymentmethods_Bool_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  pointofsales?: Maybe<Pointofsales_Bool_Exp>;
  privateperson?: Maybe<Boolean_Comparison_Exp>;
  products?: Maybe<Products_Bool_Exp>;
  receipttextfoot?: Maybe<String_Comparison_Exp>;
  receipttexthead?: Maybe<String_Comparison_Exp>;
  registered_for_vat?: Maybe<Boolean_Comparison_Exp>;
  report_recievers?: Maybe<String_Comparison_Exp>;
  responsible_id?: Maybe<Uuid_Comparison_Exp>;
  tags?: Maybe<Tags_Bool_Exp>;
  templates?: Maybe<Templates_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_by?: Maybe<Uuid_Comparison_Exp>;
  web?: Maybe<String_Comparison_Exp>;
  zip?: Maybe<String_Comparison_Exp>;
  zreports?: Maybe<Order_Zreports_Bool_Exp>;
};

/** unique or primary key constraints on table "organizations" */
export enum Organizations_Constraint {
  /** unique or primary key constraint */
  OrganizationsOrgnumberKey = 'organizations_orgnumber_key',
  /** unique or primary key constraint */
  OrganizationsPkey = 'organizations_pkey'
}

/** input type for incrementing numeric columns in table "organizations" */
export type Organizations_Inc_Input = {
  guest_users_invite?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "organizations" */
export type Organizations_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  active_agreement?: Maybe<Scalars['Boolean']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  admin_only_reports?: Maybe<Scalars['Boolean']>;
  agreement_code?: Maybe<Scalars['String']>;
  agreement_contacts?: Maybe<Agreement_Contacts_Arr_Rel_Insert_Input>;
  agreement_type?: Maybe<Agreement_Types_Obj_Rel_Insert_Input>;
  billing_address1?: Maybe<Scalars['String']>;
  billing_address2?: Maybe<Scalars['String']>;
  billing_city?: Maybe<Scalars['String']>;
  billing_email?: Maybe<Scalars['String']>;
  billing_zip?: Maybe<Scalars['String']>;
  categories?: Maybe<Categories_Arr_Rel_Insert_Input>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  customer_number?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  demomode?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  groups?: Maybe<Groups_Arr_Rel_Insert_Input>;
  guest_users?: Maybe<Scalars['Boolean']>;
  guest_users_invite?: Maybe<Scalars['numeric']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  logo_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_number?: Maybe<Scalars['String']>;
  organization_users?: Maybe<Organization_Users_Arr_Rel_Insert_Input>;
  participants?: Maybe<Participants_Arr_Rel_Insert_Input>;
  paymentmethods?: Maybe<Paymentmethods_Arr_Rel_Insert_Input>;
  phone?: Maybe<Scalars['String']>;
  pointofsales?: Maybe<Pointofsales_Arr_Rel_Insert_Input>;
  privateperson?: Maybe<Scalars['Boolean']>;
  products?: Maybe<Products_Arr_Rel_Insert_Input>;
  receipttextfoot?: Maybe<Scalars['String']>;
  receipttexthead?: Maybe<Scalars['String']>;
  registered_for_vat?: Maybe<Scalars['Boolean']>;
  report_recievers?: Maybe<Scalars['String']>;
  responsible_id?: Maybe<Scalars['uuid']>;
  tags?: Maybe<Tags_Arr_Rel_Insert_Input>;
  templates?: Maybe<Templates_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
  web?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  zreports?: Maybe<Order_Zreports_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Organizations_Max_Fields = {
  __typename?: 'organizations_max_fields';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  agreement_code?: Maybe<Scalars['String']>;
  billing_address1?: Maybe<Scalars['String']>;
  billing_address2?: Maybe<Scalars['String']>;
  billing_city?: Maybe<Scalars['String']>;
  billing_email?: Maybe<Scalars['String']>;
  billing_zip?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  customer_number?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  guest_users_invite?: Maybe<Scalars['numeric']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  logo_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_number?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  receipttextfoot?: Maybe<Scalars['String']>;
  receipttexthead?: Maybe<Scalars['String']>;
  report_recievers?: Maybe<Scalars['String']>;
  responsible_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
  web?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "organizations" */
export type Organizations_Max_Order_By = {
  address1?: Maybe<Order_By>;
  address2?: Maybe<Order_By>;
  agreement_code?: Maybe<Order_By>;
  billing_address1?: Maybe<Order_By>;
  billing_address2?: Maybe<Order_By>;
  billing_city?: Maybe<Order_By>;
  billing_email?: Maybe<Order_By>;
  billing_zip?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_number?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  deleted_by?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  guest_users_invite?: Maybe<Order_By>;
  icon?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logo_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization_number?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  receipttextfoot?: Maybe<Order_By>;
  receipttexthead?: Maybe<Order_By>;
  report_recievers?: Maybe<Order_By>;
  responsible_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
  web?: Maybe<Order_By>;
  zip?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Organizations_Min_Fields = {
  __typename?: 'organizations_min_fields';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  agreement_code?: Maybe<Scalars['String']>;
  billing_address1?: Maybe<Scalars['String']>;
  billing_address2?: Maybe<Scalars['String']>;
  billing_city?: Maybe<Scalars['String']>;
  billing_email?: Maybe<Scalars['String']>;
  billing_zip?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  customer_number?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  guest_users_invite?: Maybe<Scalars['numeric']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  logo_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_number?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  receipttextfoot?: Maybe<Scalars['String']>;
  receipttexthead?: Maybe<Scalars['String']>;
  report_recievers?: Maybe<Scalars['String']>;
  responsible_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
  web?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "organizations" */
export type Organizations_Min_Order_By = {
  address1?: Maybe<Order_By>;
  address2?: Maybe<Order_By>;
  agreement_code?: Maybe<Order_By>;
  billing_address1?: Maybe<Order_By>;
  billing_address2?: Maybe<Order_By>;
  billing_city?: Maybe<Order_By>;
  billing_email?: Maybe<Order_By>;
  billing_zip?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_number?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  deleted_by?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  guest_users_invite?: Maybe<Order_By>;
  icon?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logo_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization_number?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  receipttextfoot?: Maybe<Order_By>;
  receipttexthead?: Maybe<Order_By>;
  report_recievers?: Maybe<Order_By>;
  responsible_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
  web?: Maybe<Order_By>;
  zip?: Maybe<Order_By>;
};

/** response of any mutation on the table "organizations" */
export type Organizations_Mutation_Response = {
  __typename?: 'organizations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organizations>;
};

/** input type for inserting object relation for remote table "organizations" */
export type Organizations_Obj_Rel_Insert_Input = {
  data: Organizations_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Organizations_On_Conflict>;
};

/** on conflict condition type for table "organizations" */
export type Organizations_On_Conflict = {
  constraint: Organizations_Constraint;
  update_columns?: Array<Organizations_Update_Column>;
  where?: Maybe<Organizations_Bool_Exp>;
};

/** Ordering options when selecting data from "organizations". */
export type Organizations_Order_By = {
  active?: Maybe<Order_By>;
  active_agreement?: Maybe<Order_By>;
  address1?: Maybe<Order_By>;
  address2?: Maybe<Order_By>;
  admin_only_reports?: Maybe<Order_By>;
  agreement_code?: Maybe<Order_By>;
  agreement_contacts_aggregate?: Maybe<Agreement_Contacts_Aggregate_Order_By>;
  agreement_type?: Maybe<Agreement_Types_Order_By>;
  billing_address1?: Maybe<Order_By>;
  billing_address2?: Maybe<Order_By>;
  billing_city?: Maybe<Order_By>;
  billing_email?: Maybe<Order_By>;
  billing_zip?: Maybe<Order_By>;
  categories_aggregate?: Maybe<Categories_Aggregate_Order_By>;
  city?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_number?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  deleted_by?: Maybe<Order_By>;
  demomode?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  groups_aggregate?: Maybe<Groups_Aggregate_Order_By>;
  guest_users?: Maybe<Order_By>;
  guest_users_invite?: Maybe<Order_By>;
  icon?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logo_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization_number?: Maybe<Order_By>;
  organization_users_aggregate?: Maybe<Organization_Users_Aggregate_Order_By>;
  participants_aggregate?: Maybe<Participants_Aggregate_Order_By>;
  paymentmethods_aggregate?: Maybe<Paymentmethods_Aggregate_Order_By>;
  phone?: Maybe<Order_By>;
  pointofsales_aggregate?: Maybe<Pointofsales_Aggregate_Order_By>;
  privateperson?: Maybe<Order_By>;
  products_aggregate?: Maybe<Products_Aggregate_Order_By>;
  receipttextfoot?: Maybe<Order_By>;
  receipttexthead?: Maybe<Order_By>;
  registered_for_vat?: Maybe<Order_By>;
  report_recievers?: Maybe<Order_By>;
  responsible_id?: Maybe<Order_By>;
  tags_aggregate?: Maybe<Tags_Aggregate_Order_By>;
  templates_aggregate?: Maybe<Templates_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
  web?: Maybe<Order_By>;
  zip?: Maybe<Order_By>;
  zreports_aggregate?: Maybe<Order_Zreports_Aggregate_Order_By>;
};

/** primary key columns input for table: organizations */
export type Organizations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "organizations" */
export enum Organizations_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ActiveAgreement = 'active_agreement',
  /** column name */
  Address1 = 'address1',
  /** column name */
  Address2 = 'address2',
  /** column name */
  AdminOnlyReports = 'admin_only_reports',
  /** column name */
  AgreementCode = 'agreement_code',
  /** column name */
  BillingAddress1 = 'billing_address1',
  /** column name */
  BillingAddress2 = 'billing_address2',
  /** column name */
  BillingCity = 'billing_city',
  /** column name */
  BillingEmail = 'billing_email',
  /** column name */
  BillingZip = 'billing_zip',
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CustomerNumber = 'customer_number',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Demomode = 'demomode',
  /** column name */
  Email = 'email',
  /** column name */
  GuestUsers = 'guest_users',
  /** column name */
  GuestUsersInvite = 'guest_users_invite',
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  LogoId = 'logo_id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationNumber = 'organization_number',
  /** column name */
  Phone = 'phone',
  /** column name */
  Privateperson = 'privateperson',
  /** column name */
  Receipttextfoot = 'receipttextfoot',
  /** column name */
  Receipttexthead = 'receipttexthead',
  /** column name */
  RegisteredForVat = 'registered_for_vat',
  /** column name */
  ReportRecievers = 'report_recievers',
  /** column name */
  ResponsibleId = 'responsible_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Web = 'web',
  /** column name */
  Zip = 'zip'
}

/** input type for updating data in table "organizations" */
export type Organizations_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  active_agreement?: Maybe<Scalars['Boolean']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  admin_only_reports?: Maybe<Scalars['Boolean']>;
  agreement_code?: Maybe<Scalars['String']>;
  billing_address1?: Maybe<Scalars['String']>;
  billing_address2?: Maybe<Scalars['String']>;
  billing_city?: Maybe<Scalars['String']>;
  billing_email?: Maybe<Scalars['String']>;
  billing_zip?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  customer_number?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  demomode?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  guest_users?: Maybe<Scalars['Boolean']>;
  guest_users_invite?: Maybe<Scalars['numeric']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  logo_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_number?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  privateperson?: Maybe<Scalars['Boolean']>;
  receipttextfoot?: Maybe<Scalars['String']>;
  receipttexthead?: Maybe<Scalars['String']>;
  registered_for_vat?: Maybe<Scalars['Boolean']>;
  report_recievers?: Maybe<Scalars['String']>;
  responsible_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
  web?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Organizations_Stddev_Fields = {
  __typename?: 'organizations_stddev_fields';
  guest_users_invite?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "organizations" */
export type Organizations_Stddev_Order_By = {
  guest_users_invite?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Organizations_Stddev_Pop_Fields = {
  __typename?: 'organizations_stddev_pop_fields';
  guest_users_invite?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "organizations" */
export type Organizations_Stddev_Pop_Order_By = {
  guest_users_invite?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Organizations_Stddev_Samp_Fields = {
  __typename?: 'organizations_stddev_samp_fields';
  guest_users_invite?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "organizations" */
export type Organizations_Stddev_Samp_Order_By = {
  guest_users_invite?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Organizations_Sum_Fields = {
  __typename?: 'organizations_sum_fields';
  guest_users_invite?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "organizations" */
export type Organizations_Sum_Order_By = {
  guest_users_invite?: Maybe<Order_By>;
};

/** update columns of table "organizations" */
export enum Organizations_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ActiveAgreement = 'active_agreement',
  /** column name */
  Address1 = 'address1',
  /** column name */
  Address2 = 'address2',
  /** column name */
  AdminOnlyReports = 'admin_only_reports',
  /** column name */
  AgreementCode = 'agreement_code',
  /** column name */
  BillingAddress1 = 'billing_address1',
  /** column name */
  BillingAddress2 = 'billing_address2',
  /** column name */
  BillingCity = 'billing_city',
  /** column name */
  BillingEmail = 'billing_email',
  /** column name */
  BillingZip = 'billing_zip',
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CustomerNumber = 'customer_number',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Demomode = 'demomode',
  /** column name */
  Email = 'email',
  /** column name */
  GuestUsers = 'guest_users',
  /** column name */
  GuestUsersInvite = 'guest_users_invite',
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  LogoId = 'logo_id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationNumber = 'organization_number',
  /** column name */
  Phone = 'phone',
  /** column name */
  Privateperson = 'privateperson',
  /** column name */
  Receipttextfoot = 'receipttextfoot',
  /** column name */
  Receipttexthead = 'receipttexthead',
  /** column name */
  RegisteredForVat = 'registered_for_vat',
  /** column name */
  ReportRecievers = 'report_recievers',
  /** column name */
  ResponsibleId = 'responsible_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Web = 'web',
  /** column name */
  Zip = 'zip'
}

/** aggregate var_pop on columns */
export type Organizations_Var_Pop_Fields = {
  __typename?: 'organizations_var_pop_fields';
  guest_users_invite?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "organizations" */
export type Organizations_Var_Pop_Order_By = {
  guest_users_invite?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Organizations_Var_Samp_Fields = {
  __typename?: 'organizations_var_samp_fields';
  guest_users_invite?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "organizations" */
export type Organizations_Var_Samp_Order_By = {
  guest_users_invite?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Organizations_Variance_Fields = {
  __typename?: 'organizations_variance_fields';
  guest_users_invite?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "organizations" */
export type Organizations_Variance_Order_By = {
  guest_users_invite?: Maybe<Order_By>;
};

/** columns and relationships of "participants" */
export type Participants = {
  __typename?: 'participants';
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid'];
  /** An object relationship */
  pointofsale: Pointofsales;
  pointofsale_id: Scalars['uuid'];
};

/** aggregated selection of "participants" */
export type Participants_Aggregate = {
  __typename?: 'participants_aggregate';
  aggregate?: Maybe<Participants_Aggregate_Fields>;
  nodes: Array<Participants>;
};

/** aggregate fields of "participants" */
export type Participants_Aggregate_Fields = {
  __typename?: 'participants_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Participants_Max_Fields>;
  min?: Maybe<Participants_Min_Fields>;
};


/** aggregate fields of "participants" */
export type Participants_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Participants_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "participants" */
export type Participants_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Participants_Max_Order_By>;
  min?: Maybe<Participants_Min_Order_By>;
};

/** input type for inserting array relation for remote table "participants" */
export type Participants_Arr_Rel_Insert_Input = {
  data: Array<Participants_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Participants_On_Conflict>;
};

/** Boolean expression to filter rows from the table "participants". All fields are combined with a logical 'AND'. */
export type Participants_Bool_Exp = {
  _and?: Maybe<Array<Participants_Bool_Exp>>;
  _not?: Maybe<Participants_Bool_Exp>;
  _or?: Maybe<Array<Participants_Bool_Exp>>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_by?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  organization?: Maybe<Organizations_Bool_Exp>;
  organization_id?: Maybe<Uuid_Comparison_Exp>;
  pointofsale?: Maybe<Pointofsales_Bool_Exp>;
  pointofsale_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "participants" */
export enum Participants_Constraint {
  /** unique or primary key constraint */
  ParticipantsPkey = 'participants_pkey'
}

/** input type for inserting data into table "participants" */
export type Participants_Insert_Input = {
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: Maybe<Scalars['uuid']>;
  pointofsale?: Maybe<Pointofsales_Obj_Rel_Insert_Input>;
  pointofsale_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Participants_Max_Fields = {
  __typename?: 'participants_max_fields';
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  pointofsale_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "participants" */
export type Participants_Max_Order_By = {
  deleted_at?: Maybe<Order_By>;
  deleted_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  pointofsale_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Participants_Min_Fields = {
  __typename?: 'participants_min_fields';
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  pointofsale_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "participants" */
export type Participants_Min_Order_By = {
  deleted_at?: Maybe<Order_By>;
  deleted_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  pointofsale_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "participants" */
export type Participants_Mutation_Response = {
  __typename?: 'participants_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Participants>;
};

/** on conflict condition type for table "participants" */
export type Participants_On_Conflict = {
  constraint: Participants_Constraint;
  update_columns?: Array<Participants_Update_Column>;
  where?: Maybe<Participants_Bool_Exp>;
};

/** Ordering options when selecting data from "participants". */
export type Participants_Order_By = {
  deleted_at?: Maybe<Order_By>;
  deleted_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization?: Maybe<Organizations_Order_By>;
  organization_id?: Maybe<Order_By>;
  pointofsale?: Maybe<Pointofsales_Order_By>;
  pointofsale_id?: Maybe<Order_By>;
};

/** primary key columns input for table: participants */
export type Participants_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "participants" */
export enum Participants_Select_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  PointofsaleId = 'pointofsale_id'
}

/** input type for updating data in table "participants" */
export type Participants_Set_Input = {
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  pointofsale_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "participants" */
export enum Participants_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  PointofsaleId = 'pointofsale_id'
}

/** columns and relationships of "paymentmethods" */
export type Paymentmethods = {
  __typename?: 'paymentmethods';
  active: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  description: Scalars['String'];
  /** An object relationship */
  group?: Maybe<Groups>;
  group_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  locked: Scalars['Boolean'];
  method?: Maybe<Scalars['String']>;
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid'];
  /** An object relationship */
  pointofsale?: Maybe<Pointofsales>;
  pointofsale_id?: Maybe<Scalars['uuid']>;
  recipient?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "paymentmethods" */
export type Paymentmethods_Aggregate = {
  __typename?: 'paymentmethods_aggregate';
  aggregate?: Maybe<Paymentmethods_Aggregate_Fields>;
  nodes: Array<Paymentmethods>;
};

/** aggregate fields of "paymentmethods" */
export type Paymentmethods_Aggregate_Fields = {
  __typename?: 'paymentmethods_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Paymentmethods_Max_Fields>;
  min?: Maybe<Paymentmethods_Min_Fields>;
};


/** aggregate fields of "paymentmethods" */
export type Paymentmethods_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Paymentmethods_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "paymentmethods" */
export type Paymentmethods_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Paymentmethods_Max_Order_By>;
  min?: Maybe<Paymentmethods_Min_Order_By>;
};

/** input type for inserting array relation for remote table "paymentmethods" */
export type Paymentmethods_Arr_Rel_Insert_Input = {
  data: Array<Paymentmethods_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Paymentmethods_On_Conflict>;
};

/** Boolean expression to filter rows from the table "paymentmethods". All fields are combined with a logical 'AND'. */
export type Paymentmethods_Bool_Exp = {
  _and?: Maybe<Array<Paymentmethods_Bool_Exp>>;
  _not?: Maybe<Paymentmethods_Bool_Exp>;
  _or?: Maybe<Array<Paymentmethods_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Uuid_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_by?: Maybe<Uuid_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  group?: Maybe<Groups_Bool_Exp>;
  group_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  locked?: Maybe<Boolean_Comparison_Exp>;
  method?: Maybe<String_Comparison_Exp>;
  organization?: Maybe<Organizations_Bool_Exp>;
  organization_id?: Maybe<Uuid_Comparison_Exp>;
  pointofsale?: Maybe<Pointofsales_Bool_Exp>;
  pointofsale_id?: Maybe<Uuid_Comparison_Exp>;
  recipient?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_by?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "paymentmethods" */
export enum Paymentmethods_Constraint {
  /** unique or primary key constraint */
  PaymentmethodsPkey = 'paymentmethods_pkey'
}

/** input type for inserting data into table "paymentmethods" */
export type Paymentmethods_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  group?: Maybe<Groups_Obj_Rel_Insert_Input>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  locked?: Maybe<Scalars['Boolean']>;
  method?: Maybe<Scalars['String']>;
  organization?: Maybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: Maybe<Scalars['uuid']>;
  pointofsale?: Maybe<Pointofsales_Obj_Rel_Insert_Input>;
  pointofsale_id?: Maybe<Scalars['uuid']>;
  recipient?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Paymentmethods_Max_Fields = {
  __typename?: 'paymentmethods_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  method?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  pointofsale_id?: Maybe<Scalars['uuid']>;
  recipient?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "paymentmethods" */
export type Paymentmethods_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  deleted_by?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  method?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  pointofsale_id?: Maybe<Order_By>;
  recipient?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Paymentmethods_Min_Fields = {
  __typename?: 'paymentmethods_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  method?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  pointofsale_id?: Maybe<Scalars['uuid']>;
  recipient?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "paymentmethods" */
export type Paymentmethods_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  deleted_by?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  method?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  pointofsale_id?: Maybe<Order_By>;
  recipient?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
};

/** response of any mutation on the table "paymentmethods" */
export type Paymentmethods_Mutation_Response = {
  __typename?: 'paymentmethods_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Paymentmethods>;
};

/** on conflict condition type for table "paymentmethods" */
export type Paymentmethods_On_Conflict = {
  constraint: Paymentmethods_Constraint;
  update_columns?: Array<Paymentmethods_Update_Column>;
  where?: Maybe<Paymentmethods_Bool_Exp>;
};

/** Ordering options when selecting data from "paymentmethods". */
export type Paymentmethods_Order_By = {
  active?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  deleted_by?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  group?: Maybe<Groups_Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locked?: Maybe<Order_By>;
  method?: Maybe<Order_By>;
  organization?: Maybe<Organizations_Order_By>;
  organization_id?: Maybe<Order_By>;
  pointofsale?: Maybe<Pointofsales_Order_By>;
  pointofsale_id?: Maybe<Order_By>;
  recipient?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
};

/** primary key columns input for table: paymentmethods */
export type Paymentmethods_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "paymentmethods" */
export enum Paymentmethods_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Description = 'description',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  Locked = 'locked',
  /** column name */
  Method = 'method',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  PointofsaleId = 'pointofsale_id',
  /** column name */
  Recipient = 'recipient',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "paymentmethods" */
export type Paymentmethods_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  locked?: Maybe<Scalars['Boolean']>;
  method?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  pointofsale_id?: Maybe<Scalars['uuid']>;
  recipient?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** update columns of table "paymentmethods" */
export enum Paymentmethods_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Description = 'description',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  Locked = 'locked',
  /** column name */
  Method = 'method',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  PointofsaleId = 'pointofsale_id',
  /** column name */
  Recipient = 'recipient',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** columns and relationships of "pointofsales" */
export type Pointofsales = {
  __typename?: 'pointofsales';
  active: Scalars['Boolean'];
  autocom_end?: Maybe<Scalars['Boolean']>;
  autocom_mid?: Maybe<Scalars['Boolean']>;
  autocom_start?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  categories: Array<Categories>;
  /** An aggregate relationship */
  categories_aggregate: Categories_Aggregate;
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  demomode: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  enddate?: Maybe<Scalars['timestamptz']>;
  excludefrombilling: Scalars['Boolean'];
  /** An object relationship */
  group: Groups;
  group_id: Scalars['uuid'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  order_sessions: Array<Order_Sessions>;
  /** An aggregate relationship */
  order_sessions_aggregate: Order_Sessions_Aggregate;
  /** fetch data from the table: "orders" */
  orders: Array<Orders>;
  /** fetch aggregated fields from the table: "orders" */
  orders_aggregate: Orders_Aggregate;
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid'];
  /** An array relationship */
  participants: Array<Participants>;
  /** An aggregate relationship */
  participants_aggregate: Participants_Aggregate;
  /** An array relationship */
  paymentmethods: Array<Paymentmethods>;
  /** An aggregate relationship */
  paymentmethods_aggregate: Paymentmethods_Aggregate;
  /** An array relationship */
  products: Array<Products>;
  /** An aggregate relationship */
  products_aggregate: Products_Aggregate;
  responsible_id?: Maybe<Scalars['uuid']>;
  startdate: Scalars['timestamptz'];
  tags?: Maybe<Scalars['String']>;
  target_type?: Maybe<Scalars['String']>;
  target_value?: Maybe<Scalars['numeric']>;
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  zreports: Array<Order_Zreports>;
  /** An aggregate relationship */
  zreports_aggregate: Order_Zreports_Aggregate;
};


/** columns and relationships of "pointofsales" */
export type PointofsalesCategoriesArgs = {
  distinct_on?: Maybe<Array<Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Categories_Order_By>>;
  where?: Maybe<Categories_Bool_Exp>;
};


/** columns and relationships of "pointofsales" */
export type PointofsalesCategories_AggregateArgs = {
  distinct_on?: Maybe<Array<Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Categories_Order_By>>;
  where?: Maybe<Categories_Bool_Exp>;
};


/** columns and relationships of "pointofsales" */
export type PointofsalesOrder_SessionsArgs = {
  distinct_on?: Maybe<Array<Order_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Sessions_Order_By>>;
  where?: Maybe<Order_Sessions_Bool_Exp>;
};


/** columns and relationships of "pointofsales" */
export type PointofsalesOrder_Sessions_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Sessions_Order_By>>;
  where?: Maybe<Order_Sessions_Bool_Exp>;
};


/** columns and relationships of "pointofsales" */
export type PointofsalesOrdersArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


/** columns and relationships of "pointofsales" */
export type PointofsalesOrders_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


/** columns and relationships of "pointofsales" */
export type PointofsalesParticipantsArgs = {
  distinct_on?: Maybe<Array<Participants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Participants_Order_By>>;
  where?: Maybe<Participants_Bool_Exp>;
};


/** columns and relationships of "pointofsales" */
export type PointofsalesParticipants_AggregateArgs = {
  distinct_on?: Maybe<Array<Participants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Participants_Order_By>>;
  where?: Maybe<Participants_Bool_Exp>;
};


/** columns and relationships of "pointofsales" */
export type PointofsalesPaymentmethodsArgs = {
  distinct_on?: Maybe<Array<Paymentmethods_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Paymentmethods_Order_By>>;
  where?: Maybe<Paymentmethods_Bool_Exp>;
};


/** columns and relationships of "pointofsales" */
export type PointofsalesPaymentmethods_AggregateArgs = {
  distinct_on?: Maybe<Array<Paymentmethods_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Paymentmethods_Order_By>>;
  where?: Maybe<Paymentmethods_Bool_Exp>;
};


/** columns and relationships of "pointofsales" */
export type PointofsalesProductsArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


/** columns and relationships of "pointofsales" */
export type PointofsalesProducts_AggregateArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


/** columns and relationships of "pointofsales" */
export type PointofsalesZreportsArgs = {
  distinct_on?: Maybe<Array<Order_Zreports_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Zreports_Order_By>>;
  where?: Maybe<Order_Zreports_Bool_Exp>;
};


/** columns and relationships of "pointofsales" */
export type PointofsalesZreports_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Zreports_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Zreports_Order_By>>;
  where?: Maybe<Order_Zreports_Bool_Exp>;
};

/** aggregated selection of "pointofsales" */
export type Pointofsales_Aggregate = {
  __typename?: 'pointofsales_aggregate';
  aggregate?: Maybe<Pointofsales_Aggregate_Fields>;
  nodes: Array<Pointofsales>;
};

/** aggregate fields of "pointofsales" */
export type Pointofsales_Aggregate_Fields = {
  __typename?: 'pointofsales_aggregate_fields';
  avg?: Maybe<Pointofsales_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Pointofsales_Max_Fields>;
  min?: Maybe<Pointofsales_Min_Fields>;
  stddev?: Maybe<Pointofsales_Stddev_Fields>;
  stddev_pop?: Maybe<Pointofsales_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Pointofsales_Stddev_Samp_Fields>;
  sum?: Maybe<Pointofsales_Sum_Fields>;
  var_pop?: Maybe<Pointofsales_Var_Pop_Fields>;
  var_samp?: Maybe<Pointofsales_Var_Samp_Fields>;
  variance?: Maybe<Pointofsales_Variance_Fields>;
};


/** aggregate fields of "pointofsales" */
export type Pointofsales_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Pointofsales_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "pointofsales" */
export type Pointofsales_Aggregate_Order_By = {
  avg?: Maybe<Pointofsales_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Pointofsales_Max_Order_By>;
  min?: Maybe<Pointofsales_Min_Order_By>;
  stddev?: Maybe<Pointofsales_Stddev_Order_By>;
  stddev_pop?: Maybe<Pointofsales_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Pointofsales_Stddev_Samp_Order_By>;
  sum?: Maybe<Pointofsales_Sum_Order_By>;
  var_pop?: Maybe<Pointofsales_Var_Pop_Order_By>;
  var_samp?: Maybe<Pointofsales_Var_Samp_Order_By>;
  variance?: Maybe<Pointofsales_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "pointofsales" */
export type Pointofsales_Arr_Rel_Insert_Input = {
  data: Array<Pointofsales_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Pointofsales_On_Conflict>;
};

/** aggregate avg on columns */
export type Pointofsales_Avg_Fields = {
  __typename?: 'pointofsales_avg_fields';
  target_value?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "pointofsales" */
export type Pointofsales_Avg_Order_By = {
  target_value?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "pointofsales". All fields are combined with a logical 'AND'. */
export type Pointofsales_Bool_Exp = {
  _and?: Maybe<Array<Pointofsales_Bool_Exp>>;
  _not?: Maybe<Pointofsales_Bool_Exp>;
  _or?: Maybe<Array<Pointofsales_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  autocom_end?: Maybe<Boolean_Comparison_Exp>;
  autocom_mid?: Maybe<Boolean_Comparison_Exp>;
  autocom_start?: Maybe<Boolean_Comparison_Exp>;
  categories?: Maybe<Categories_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Uuid_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_by?: Maybe<Uuid_Comparison_Exp>;
  demomode?: Maybe<Boolean_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  enddate?: Maybe<Timestamptz_Comparison_Exp>;
  excludefrombilling?: Maybe<Boolean_Comparison_Exp>;
  group?: Maybe<Groups_Bool_Exp>;
  group_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  order_sessions?: Maybe<Order_Sessions_Bool_Exp>;
  orders?: Maybe<Orders_Bool_Exp>;
  organization?: Maybe<Organizations_Bool_Exp>;
  organization_id?: Maybe<Uuid_Comparison_Exp>;
  participants?: Maybe<Participants_Bool_Exp>;
  paymentmethods?: Maybe<Paymentmethods_Bool_Exp>;
  products?: Maybe<Products_Bool_Exp>;
  responsible_id?: Maybe<Uuid_Comparison_Exp>;
  startdate?: Maybe<Timestamptz_Comparison_Exp>;
  tags?: Maybe<String_Comparison_Exp>;
  target_type?: Maybe<String_Comparison_Exp>;
  target_value?: Maybe<Numeric_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_by?: Maybe<Uuid_Comparison_Exp>;
  zreports?: Maybe<Order_Zreports_Bool_Exp>;
};

/** unique or primary key constraints on table "pointofsales" */
export enum Pointofsales_Constraint {
  /** unique or primary key constraint */
  PointofsalesPkey = 'pointofsales_pkey'
}

/** input type for incrementing numeric columns in table "pointofsales" */
export type Pointofsales_Inc_Input = {
  target_value?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "pointofsales" */
export type Pointofsales_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  autocom_end?: Maybe<Scalars['Boolean']>;
  autocom_mid?: Maybe<Scalars['Boolean']>;
  autocom_start?: Maybe<Scalars['Boolean']>;
  categories?: Maybe<Categories_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  demomode?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  enddate?: Maybe<Scalars['timestamptz']>;
  excludefrombilling?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Groups_Obj_Rel_Insert_Input>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  order_sessions?: Maybe<Order_Sessions_Arr_Rel_Insert_Input>;
  orders?: Maybe<Orders_Arr_Rel_Insert_Input>;
  organization?: Maybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: Maybe<Scalars['uuid']>;
  participants?: Maybe<Participants_Arr_Rel_Insert_Input>;
  paymentmethods?: Maybe<Paymentmethods_Arr_Rel_Insert_Input>;
  products?: Maybe<Products_Arr_Rel_Insert_Input>;
  responsible_id?: Maybe<Scalars['uuid']>;
  startdate?: Maybe<Scalars['timestamptz']>;
  tags?: Maybe<Scalars['String']>;
  target_type?: Maybe<Scalars['String']>;
  target_value?: Maybe<Scalars['numeric']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
  zreports?: Maybe<Order_Zreports_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Pointofsales_Max_Fields = {
  __typename?: 'pointofsales_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  enddate?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  responsible_id?: Maybe<Scalars['uuid']>;
  startdate?: Maybe<Scalars['timestamptz']>;
  tags?: Maybe<Scalars['String']>;
  target_type?: Maybe<Scalars['String']>;
  target_value?: Maybe<Scalars['numeric']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "pointofsales" */
export type Pointofsales_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  deleted_by?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  enddate?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  responsible_id?: Maybe<Order_By>;
  startdate?: Maybe<Order_By>;
  tags?: Maybe<Order_By>;
  target_type?: Maybe<Order_By>;
  target_value?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Pointofsales_Min_Fields = {
  __typename?: 'pointofsales_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  enddate?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  responsible_id?: Maybe<Scalars['uuid']>;
  startdate?: Maybe<Scalars['timestamptz']>;
  tags?: Maybe<Scalars['String']>;
  target_type?: Maybe<Scalars['String']>;
  target_value?: Maybe<Scalars['numeric']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "pointofsales" */
export type Pointofsales_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  deleted_by?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  enddate?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  responsible_id?: Maybe<Order_By>;
  startdate?: Maybe<Order_By>;
  tags?: Maybe<Order_By>;
  target_type?: Maybe<Order_By>;
  target_value?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
};

/** response of any mutation on the table "pointofsales" */
export type Pointofsales_Mutation_Response = {
  __typename?: 'pointofsales_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Pointofsales>;
};

/** input type for inserting object relation for remote table "pointofsales" */
export type Pointofsales_Obj_Rel_Insert_Input = {
  data: Pointofsales_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Pointofsales_On_Conflict>;
};

/** on conflict condition type for table "pointofsales" */
export type Pointofsales_On_Conflict = {
  constraint: Pointofsales_Constraint;
  update_columns?: Array<Pointofsales_Update_Column>;
  where?: Maybe<Pointofsales_Bool_Exp>;
};

/** Ordering options when selecting data from "pointofsales". */
export type Pointofsales_Order_By = {
  active?: Maybe<Order_By>;
  autocom_end?: Maybe<Order_By>;
  autocom_mid?: Maybe<Order_By>;
  autocom_start?: Maybe<Order_By>;
  categories_aggregate?: Maybe<Categories_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  deleted_by?: Maybe<Order_By>;
  demomode?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  enddate?: Maybe<Order_By>;
  excludefrombilling?: Maybe<Order_By>;
  group?: Maybe<Groups_Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  order_sessions_aggregate?: Maybe<Order_Sessions_Aggregate_Order_By>;
  orders_aggregate?: Maybe<Orders_Aggregate_Order_By>;
  organization?: Maybe<Organizations_Order_By>;
  organization_id?: Maybe<Order_By>;
  participants_aggregate?: Maybe<Participants_Aggregate_Order_By>;
  paymentmethods_aggregate?: Maybe<Paymentmethods_Aggregate_Order_By>;
  products_aggregate?: Maybe<Products_Aggregate_Order_By>;
  responsible_id?: Maybe<Order_By>;
  startdate?: Maybe<Order_By>;
  tags?: Maybe<Order_By>;
  target_type?: Maybe<Order_By>;
  target_value?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
  zreports_aggregate?: Maybe<Order_Zreports_Aggregate_Order_By>;
};

/** primary key columns input for table: pointofsales */
export type Pointofsales_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "pointofsales" */
export enum Pointofsales_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AutocomEnd = 'autocom_end',
  /** column name */
  AutocomMid = 'autocom_mid',
  /** column name */
  AutocomStart = 'autocom_start',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Demomode = 'demomode',
  /** column name */
  Description = 'description',
  /** column name */
  Enddate = 'enddate',
  /** column name */
  Excludefrombilling = 'excludefrombilling',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  ResponsibleId = 'responsible_id',
  /** column name */
  Startdate = 'startdate',
  /** column name */
  Tags = 'tags',
  /** column name */
  TargetType = 'target_type',
  /** column name */
  TargetValue = 'target_value',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "pointofsales" */
export type Pointofsales_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  autocom_end?: Maybe<Scalars['Boolean']>;
  autocom_mid?: Maybe<Scalars['Boolean']>;
  autocom_start?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  demomode?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  enddate?: Maybe<Scalars['timestamptz']>;
  excludefrombilling?: Maybe<Scalars['Boolean']>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  responsible_id?: Maybe<Scalars['uuid']>;
  startdate?: Maybe<Scalars['timestamptz']>;
  tags?: Maybe<Scalars['String']>;
  target_type?: Maybe<Scalars['String']>;
  target_value?: Maybe<Scalars['numeric']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Pointofsales_Stddev_Fields = {
  __typename?: 'pointofsales_stddev_fields';
  target_value?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "pointofsales" */
export type Pointofsales_Stddev_Order_By = {
  target_value?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Pointofsales_Stddev_Pop_Fields = {
  __typename?: 'pointofsales_stddev_pop_fields';
  target_value?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "pointofsales" */
export type Pointofsales_Stddev_Pop_Order_By = {
  target_value?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Pointofsales_Stddev_Samp_Fields = {
  __typename?: 'pointofsales_stddev_samp_fields';
  target_value?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "pointofsales" */
export type Pointofsales_Stddev_Samp_Order_By = {
  target_value?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Pointofsales_Sum_Fields = {
  __typename?: 'pointofsales_sum_fields';
  target_value?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "pointofsales" */
export type Pointofsales_Sum_Order_By = {
  target_value?: Maybe<Order_By>;
};

/** update columns of table "pointofsales" */
export enum Pointofsales_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AutocomEnd = 'autocom_end',
  /** column name */
  AutocomMid = 'autocom_mid',
  /** column name */
  AutocomStart = 'autocom_start',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Demomode = 'demomode',
  /** column name */
  Description = 'description',
  /** column name */
  Enddate = 'enddate',
  /** column name */
  Excludefrombilling = 'excludefrombilling',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  ResponsibleId = 'responsible_id',
  /** column name */
  Startdate = 'startdate',
  /** column name */
  Tags = 'tags',
  /** column name */
  TargetType = 'target_type',
  /** column name */
  TargetValue = 'target_value',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate var_pop on columns */
export type Pointofsales_Var_Pop_Fields = {
  __typename?: 'pointofsales_var_pop_fields';
  target_value?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "pointofsales" */
export type Pointofsales_Var_Pop_Order_By = {
  target_value?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Pointofsales_Var_Samp_Fields = {
  __typename?: 'pointofsales_var_samp_fields';
  target_value?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "pointofsales" */
export type Pointofsales_Var_Samp_Order_By = {
  target_value?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Pointofsales_Variance_Fields = {
  __typename?: 'pointofsales_variance_fields';
  target_value?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "pointofsales" */
export type Pointofsales_Variance_Order_By = {
  target_value?: Maybe<Order_By>;
};

/** columns and relationships of "products" */
export type Products = {
  __typename?: 'products';
  active: Scalars['Boolean'];
  /** An object relationship */
  category?: Maybe<Categories>;
  category_id?: Maybe<Scalars['uuid']>;
  color?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  displayname?: Maybe<Scalars['String']>;
  editable: Scalars['Boolean'];
  flexible_price: Scalars['Boolean'];
  icon: Scalars['String'];
  id: Scalars['uuid'];
  image?: Maybe<Scalars['String']>;
  is_editing: Scalars['Boolean'];
  name: Scalars['String'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid'];
  parent_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  pointofsale: Pointofsales;
  pointofsale_id: Scalars['uuid'];
  price: Scalars['numeric'];
  price_incl_vat: Scalars['numeric'];
  product_number?: Maybe<Scalars['String']>;
  sortorder?: Maybe<Scalars['numeric']>;
  template_id?: Maybe<Scalars['uuid']>;
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['uuid']>;
  variant: Scalars['Boolean'];
  vat_rate?: Maybe<Scalars['String']>;
  vatrate_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "products" */
export type Products_Aggregate = {
  __typename?: 'products_aggregate';
  aggregate?: Maybe<Products_Aggregate_Fields>;
  nodes: Array<Products>;
};

/** aggregate fields of "products" */
export type Products_Aggregate_Fields = {
  __typename?: 'products_aggregate_fields';
  avg?: Maybe<Products_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Products_Max_Fields>;
  min?: Maybe<Products_Min_Fields>;
  stddev?: Maybe<Products_Stddev_Fields>;
  stddev_pop?: Maybe<Products_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Products_Stddev_Samp_Fields>;
  sum?: Maybe<Products_Sum_Fields>;
  var_pop?: Maybe<Products_Var_Pop_Fields>;
  var_samp?: Maybe<Products_Var_Samp_Fields>;
  variance?: Maybe<Products_Variance_Fields>;
};


/** aggregate fields of "products" */
export type Products_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Products_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "products" */
export type Products_Aggregate_Order_By = {
  avg?: Maybe<Products_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Products_Max_Order_By>;
  min?: Maybe<Products_Min_Order_By>;
  stddev?: Maybe<Products_Stddev_Order_By>;
  stddev_pop?: Maybe<Products_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Products_Stddev_Samp_Order_By>;
  sum?: Maybe<Products_Sum_Order_By>;
  var_pop?: Maybe<Products_Var_Pop_Order_By>;
  var_samp?: Maybe<Products_Var_Samp_Order_By>;
  variance?: Maybe<Products_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "products" */
export type Products_Arr_Rel_Insert_Input = {
  data: Array<Products_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Products_On_Conflict>;
};

/** aggregate avg on columns */
export type Products_Avg_Fields = {
  __typename?: 'products_avg_fields';
  price?: Maybe<Scalars['Float']>;
  price_incl_vat?: Maybe<Scalars['Float']>;
  sortorder?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "products" */
export type Products_Avg_Order_By = {
  price?: Maybe<Order_By>;
  price_incl_vat?: Maybe<Order_By>;
  sortorder?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "products". All fields are combined with a logical 'AND'. */
export type Products_Bool_Exp = {
  _and?: Maybe<Array<Products_Bool_Exp>>;
  _not?: Maybe<Products_Bool_Exp>;
  _or?: Maybe<Array<Products_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  category?: Maybe<Categories_Bool_Exp>;
  category_id?: Maybe<Uuid_Comparison_Exp>;
  color?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Uuid_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_by?: Maybe<Uuid_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  displayname?: Maybe<String_Comparison_Exp>;
  editable?: Maybe<Boolean_Comparison_Exp>;
  flexible_price?: Maybe<Boolean_Comparison_Exp>;
  icon?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  image?: Maybe<String_Comparison_Exp>;
  is_editing?: Maybe<Boolean_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  organization?: Maybe<Organizations_Bool_Exp>;
  organization_id?: Maybe<Uuid_Comparison_Exp>;
  parent_id?: Maybe<Uuid_Comparison_Exp>;
  pointofsale?: Maybe<Pointofsales_Bool_Exp>;
  pointofsale_id?: Maybe<Uuid_Comparison_Exp>;
  price?: Maybe<Numeric_Comparison_Exp>;
  price_incl_vat?: Maybe<Numeric_Comparison_Exp>;
  product_number?: Maybe<String_Comparison_Exp>;
  sortorder?: Maybe<Numeric_Comparison_Exp>;
  template_id?: Maybe<Uuid_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_by?: Maybe<Uuid_Comparison_Exp>;
  variant?: Maybe<Boolean_Comparison_Exp>;
  vat_rate?: Maybe<String_Comparison_Exp>;
  vatrate_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "products" */
export enum Products_Constraint {
  /** unique or primary key constraint */
  ProductsPkey = 'products_pkey'
}

/** input type for incrementing numeric columns in table "products" */
export type Products_Inc_Input = {
  price?: Maybe<Scalars['numeric']>;
  price_incl_vat?: Maybe<Scalars['numeric']>;
  sortorder?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "products" */
export type Products_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Categories_Obj_Rel_Insert_Input>;
  category_id?: Maybe<Scalars['uuid']>;
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  displayname?: Maybe<Scalars['String']>;
  editable?: Maybe<Scalars['Boolean']>;
  flexible_price?: Maybe<Scalars['Boolean']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  is_editing?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: Maybe<Scalars['uuid']>;
  parent_id?: Maybe<Scalars['uuid']>;
  pointofsale?: Maybe<Pointofsales_Obj_Rel_Insert_Input>;
  pointofsale_id?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['numeric']>;
  price_incl_vat?: Maybe<Scalars['numeric']>;
  product_number?: Maybe<Scalars['String']>;
  sortorder?: Maybe<Scalars['numeric']>;
  template_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
  variant?: Maybe<Scalars['Boolean']>;
  vat_rate?: Maybe<Scalars['String']>;
  vatrate_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Products_Max_Fields = {
  __typename?: 'products_max_fields';
  category_id?: Maybe<Scalars['uuid']>;
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  displayname?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  parent_id?: Maybe<Scalars['uuid']>;
  pointofsale_id?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['numeric']>;
  price_incl_vat?: Maybe<Scalars['numeric']>;
  product_number?: Maybe<Scalars['String']>;
  sortorder?: Maybe<Scalars['numeric']>;
  template_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
  vat_rate?: Maybe<Scalars['String']>;
  vatrate_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "products" */
export type Products_Max_Order_By = {
  category_id?: Maybe<Order_By>;
  color?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  deleted_by?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  displayname?: Maybe<Order_By>;
  icon?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  parent_id?: Maybe<Order_By>;
  pointofsale_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  price_incl_vat?: Maybe<Order_By>;
  product_number?: Maybe<Order_By>;
  sortorder?: Maybe<Order_By>;
  template_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
  vat_rate?: Maybe<Order_By>;
  vatrate_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Products_Min_Fields = {
  __typename?: 'products_min_fields';
  category_id?: Maybe<Scalars['uuid']>;
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  displayname?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  parent_id?: Maybe<Scalars['uuid']>;
  pointofsale_id?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['numeric']>;
  price_incl_vat?: Maybe<Scalars['numeric']>;
  product_number?: Maybe<Scalars['String']>;
  sortorder?: Maybe<Scalars['numeric']>;
  template_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
  vat_rate?: Maybe<Scalars['String']>;
  vatrate_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "products" */
export type Products_Min_Order_By = {
  category_id?: Maybe<Order_By>;
  color?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  deleted_by?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  displayname?: Maybe<Order_By>;
  icon?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  parent_id?: Maybe<Order_By>;
  pointofsale_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  price_incl_vat?: Maybe<Order_By>;
  product_number?: Maybe<Order_By>;
  sortorder?: Maybe<Order_By>;
  template_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
  vat_rate?: Maybe<Order_By>;
  vatrate_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "products" */
export type Products_Mutation_Response = {
  __typename?: 'products_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Products>;
};

/** on conflict condition type for table "products" */
export type Products_On_Conflict = {
  constraint: Products_Constraint;
  update_columns?: Array<Products_Update_Column>;
  where?: Maybe<Products_Bool_Exp>;
};

/** Ordering options when selecting data from "products". */
export type Products_Order_By = {
  active?: Maybe<Order_By>;
  category?: Maybe<Categories_Order_By>;
  category_id?: Maybe<Order_By>;
  color?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  deleted_by?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  displayname?: Maybe<Order_By>;
  editable?: Maybe<Order_By>;
  flexible_price?: Maybe<Order_By>;
  icon?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image?: Maybe<Order_By>;
  is_editing?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization?: Maybe<Organizations_Order_By>;
  organization_id?: Maybe<Order_By>;
  parent_id?: Maybe<Order_By>;
  pointofsale?: Maybe<Pointofsales_Order_By>;
  pointofsale_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  price_incl_vat?: Maybe<Order_By>;
  product_number?: Maybe<Order_By>;
  sortorder?: Maybe<Order_By>;
  template_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
  variant?: Maybe<Order_By>;
  vat_rate?: Maybe<Order_By>;
  vatrate_id?: Maybe<Order_By>;
};

/** primary key columns input for table: products */
export type Products_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "products" */
export enum Products_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Description = 'description',
  /** column name */
  Displayname = 'displayname',
  /** column name */
  Editable = 'editable',
  /** column name */
  FlexiblePrice = 'flexible_price',
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  IsEditing = 'is_editing',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  PointofsaleId = 'pointofsale_id',
  /** column name */
  Price = 'price',
  /** column name */
  PriceInclVat = 'price_incl_vat',
  /** column name */
  ProductNumber = 'product_number',
  /** column name */
  Sortorder = 'sortorder',
  /** column name */
  TemplateId = 'template_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Variant = 'variant',
  /** column name */
  VatRate = 'vat_rate',
  /** column name */
  VatrateId = 'vatrate_id'
}

/** input type for updating data in table "products" */
export type Products_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  category_id?: Maybe<Scalars['uuid']>;
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  displayname?: Maybe<Scalars['String']>;
  editable?: Maybe<Scalars['Boolean']>;
  flexible_price?: Maybe<Scalars['Boolean']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  is_editing?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  parent_id?: Maybe<Scalars['uuid']>;
  pointofsale_id?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['numeric']>;
  price_incl_vat?: Maybe<Scalars['numeric']>;
  product_number?: Maybe<Scalars['String']>;
  sortorder?: Maybe<Scalars['numeric']>;
  template_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
  variant?: Maybe<Scalars['Boolean']>;
  vat_rate?: Maybe<Scalars['String']>;
  vatrate_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Products_Stddev_Fields = {
  __typename?: 'products_stddev_fields';
  price?: Maybe<Scalars['Float']>;
  price_incl_vat?: Maybe<Scalars['Float']>;
  sortorder?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "products" */
export type Products_Stddev_Order_By = {
  price?: Maybe<Order_By>;
  price_incl_vat?: Maybe<Order_By>;
  sortorder?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Products_Stddev_Pop_Fields = {
  __typename?: 'products_stddev_pop_fields';
  price?: Maybe<Scalars['Float']>;
  price_incl_vat?: Maybe<Scalars['Float']>;
  sortorder?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "products" */
export type Products_Stddev_Pop_Order_By = {
  price?: Maybe<Order_By>;
  price_incl_vat?: Maybe<Order_By>;
  sortorder?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Products_Stddev_Samp_Fields = {
  __typename?: 'products_stddev_samp_fields';
  price?: Maybe<Scalars['Float']>;
  price_incl_vat?: Maybe<Scalars['Float']>;
  sortorder?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "products" */
export type Products_Stddev_Samp_Order_By = {
  price?: Maybe<Order_By>;
  price_incl_vat?: Maybe<Order_By>;
  sortorder?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Products_Sum_Fields = {
  __typename?: 'products_sum_fields';
  price?: Maybe<Scalars['numeric']>;
  price_incl_vat?: Maybe<Scalars['numeric']>;
  sortorder?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "products" */
export type Products_Sum_Order_By = {
  price?: Maybe<Order_By>;
  price_incl_vat?: Maybe<Order_By>;
  sortorder?: Maybe<Order_By>;
};

/** update columns of table "products" */
export enum Products_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Description = 'description',
  /** column name */
  Displayname = 'displayname',
  /** column name */
  Editable = 'editable',
  /** column name */
  FlexiblePrice = 'flexible_price',
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  IsEditing = 'is_editing',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  PointofsaleId = 'pointofsale_id',
  /** column name */
  Price = 'price',
  /** column name */
  PriceInclVat = 'price_incl_vat',
  /** column name */
  ProductNumber = 'product_number',
  /** column name */
  Sortorder = 'sortorder',
  /** column name */
  TemplateId = 'template_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Variant = 'variant',
  /** column name */
  VatRate = 'vat_rate',
  /** column name */
  VatrateId = 'vatrate_id'
}

/** aggregate var_pop on columns */
export type Products_Var_Pop_Fields = {
  __typename?: 'products_var_pop_fields';
  price?: Maybe<Scalars['Float']>;
  price_incl_vat?: Maybe<Scalars['Float']>;
  sortorder?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "products" */
export type Products_Var_Pop_Order_By = {
  price?: Maybe<Order_By>;
  price_incl_vat?: Maybe<Order_By>;
  sortorder?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Products_Var_Samp_Fields = {
  __typename?: 'products_var_samp_fields';
  price?: Maybe<Scalars['Float']>;
  price_incl_vat?: Maybe<Scalars['Float']>;
  sortorder?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "products" */
export type Products_Var_Samp_Order_By = {
  price?: Maybe<Order_By>;
  price_incl_vat?: Maybe<Order_By>;
  sortorder?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Products_Variance_Fields = {
  __typename?: 'products_variance_fields';
  price?: Maybe<Scalars['Float']>;
  price_incl_vat?: Maybe<Scalars['Float']>;
  sortorder?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "products" */
export type Products_Variance_Order_By = {
  price?: Maybe<Order_By>;
  price_incl_vat?: Maybe<Order_By>;
  sortorder?: Maybe<Order_By>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** An array relationship */
  agreement_contacts: Array<Agreement_Contacts>;
  /** An aggregate relationship */
  agreement_contacts_aggregate: Agreement_Contacts_Aggregate;
  /** fetch data from the table: "agreement_contacts" using primary key columns */
  agreement_contacts_by_pk?: Maybe<Agreement_Contacts>;
  /** fetch data from the table: "agreement_types" */
  agreement_types: Array<Agreement_Types>;
  /** fetch aggregated fields from the table: "agreement_types" */
  agreement_types_aggregate: Agreement_Types_Aggregate;
  /** fetch data from the table: "agreement_types" using primary key columns */
  agreement_types_by_pk?: Maybe<Agreement_Types>;
  /** An array relationship */
  categories: Array<Categories>;
  /** An aggregate relationship */
  categories_aggregate: Categories_Aggregate;
  /** fetch data from the table: "categories" using primary key columns */
  categories_by_pk?: Maybe<Categories>;
  /** An array relationship */
  groups: Array<Groups>;
  /** An aggregate relationship */
  groups_aggregate: Groups_Aggregate;
  /** fetch data from the table: "groups" using primary key columns */
  groups_by_pk?: Maybe<Groups>;
  numberCheck?: Maybe<NumbercheckOutput>;
  /** An array relationship */
  order_session_connected_users: Array<Order_Session_Connected_Users>;
  /** An aggregate relationship */
  order_session_connected_users_aggregate: Order_Session_Connected_Users_Aggregate;
  /** fetch data from the table: "order_session_connected_users" using primary key columns */
  order_session_connected_users_by_pk?: Maybe<Order_Session_Connected_Users>;
  /** An array relationship */
  order_session_groups_responsible: Array<Order_Session_Groups_Responsible>;
  /** An aggregate relationship */
  order_session_groups_responsible_aggregate: Order_Session_Groups_Responsible_Aggregate;
  /** fetch data from the table: "order_session_groups_responsible" using primary key columns */
  order_session_groups_responsible_by_pk?: Maybe<Order_Session_Groups_Responsible>;
  /** An array relationship */
  order_session_payment_methods: Array<Order_Session_Payment_Methods>;
  /** An aggregate relationship */
  order_session_payment_methods_aggregate: Order_Session_Payment_Methods_Aggregate;
  /** fetch data from the table: "order_session_payment_methods" using primary key columns */
  order_session_payment_methods_by_pk?: Maybe<Order_Session_Payment_Methods>;
  /** An array relationship */
  order_sessions: Array<Order_Sessions>;
  /** An aggregate relationship */
  order_sessions_aggregate: Order_Sessions_Aggregate;
  /** fetch data from the table: "order_sessions" using primary key columns */
  order_sessions_by_pk?: Maybe<Order_Sessions>;
  /** fetch data from the table: "order_zreports" */
  order_zreports: Array<Order_Zreports>;
  /** fetch aggregated fields from the table: "order_zreports" */
  order_zreports_aggregate: Order_Zreports_Aggregate;
  /** fetch data from the table: "order_zreports" using primary key columns */
  order_zreports_by_pk?: Maybe<Order_Zreports>;
  /** fetch data from the table: "orders" */
  orders: Array<Orders>;
  /** fetch aggregated fields from the table: "orders" */
  orders_aggregate: Orders_Aggregate;
  /** fetch data from the table: "orders" using primary key columns */
  orders_by_pk?: Maybe<Orders>;
  /** An array relationship */
  organization_users: Array<Organization_Users>;
  /** An aggregate relationship */
  organization_users_aggregate: Organization_Users_Aggregate;
  /** fetch data from the table: "organization_users" using primary key columns */
  organization_users_by_pk?: Maybe<Organization_Users>;
  /** An array relationship */
  organizations: Array<Organizations>;
  /** An aggregate relationship */
  organizations_aggregate: Organizations_Aggregate;
  /** fetch data from the table: "organizations" using primary key columns */
  organizations_by_pk?: Maybe<Organizations>;
  orgnumberCheck?: Maybe<OrgNumbercheckOutput>;
  /** An array relationship */
  participants: Array<Participants>;
  /** An aggregate relationship */
  participants_aggregate: Participants_Aggregate;
  /** fetch data from the table: "participants" using primary key columns */
  participants_by_pk?: Maybe<Participants>;
  /** An array relationship */
  paymentmethods: Array<Paymentmethods>;
  /** An aggregate relationship */
  paymentmethods_aggregate: Paymentmethods_Aggregate;
  /** fetch data from the table: "paymentmethods" using primary key columns */
  paymentmethods_by_pk?: Maybe<Paymentmethods>;
  /** fetch data from the table: "pointofsales" */
  pointofsales: Array<Pointofsales>;
  /** An aggregate relationship */
  pointofsales_aggregate: Pointofsales_Aggregate;
  /** fetch data from the table: "pointofsales" using primary key columns */
  pointofsales_by_pk?: Maybe<Pointofsales>;
  /** An array relationship */
  products: Array<Products>;
  /** An aggregate relationship */
  products_aggregate: Products_Aggregate;
  /** fetch data from the table: "products" using primary key columns */
  products_by_pk?: Maybe<Products>;
  /** fetch data from the table: "refreshtoken" */
  refreshtoken: Array<Refreshtoken>;
  /** fetch aggregated fields from the table: "refreshtoken" */
  refreshtoken_aggregate: Refreshtoken_Aggregate;
  /** fetch data from the table: "refreshtoken" using primary key columns */
  refreshtoken_by_pk?: Maybe<Refreshtoken>;
  /** An array relationship */
  tags: Array<Tags>;
  /** An aggregate relationship */
  tags_aggregate: Tags_Aggregate;
  /** fetch data from the table: "tags" using primary key columns */
  tags_by_pk?: Maybe<Tags>;
  /** An array relationship */
  templates: Array<Templates>;
  /** An aggregate relationship */
  templates_aggregate: Templates_Aggregate;
  /** fetch data from the table: "templates" using primary key columns */
  templates_by_pk?: Maybe<Templates>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "vatrates" */
  vatrates: Array<Vatrates>;
  /** fetch aggregated fields from the table: "vatrates" */
  vatrates_aggregate: Vatrates_Aggregate;
  /** fetch data from the table: "vatrates" using primary key columns */
  vatrates_by_pk?: Maybe<Vatrates>;
};


export type Query_RootAgreement_ContactsArgs = {
  distinct_on?: Maybe<Array<Agreement_Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agreement_Contacts_Order_By>>;
  where?: Maybe<Agreement_Contacts_Bool_Exp>;
};


export type Query_RootAgreement_Contacts_AggregateArgs = {
  distinct_on?: Maybe<Array<Agreement_Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agreement_Contacts_Order_By>>;
  where?: Maybe<Agreement_Contacts_Bool_Exp>;
};


export type Query_RootAgreement_Contacts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAgreement_TypesArgs = {
  distinct_on?: Maybe<Array<Agreement_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agreement_Types_Order_By>>;
  where?: Maybe<Agreement_Types_Bool_Exp>;
};


export type Query_RootAgreement_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Agreement_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agreement_Types_Order_By>>;
  where?: Maybe<Agreement_Types_Bool_Exp>;
};


export type Query_RootAgreement_Types_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCategoriesArgs = {
  distinct_on?: Maybe<Array<Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Categories_Order_By>>;
  where?: Maybe<Categories_Bool_Exp>;
};


export type Query_RootCategories_AggregateArgs = {
  distinct_on?: Maybe<Array<Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Categories_Order_By>>;
  where?: Maybe<Categories_Bool_Exp>;
};


export type Query_RootCategories_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGroupsArgs = {
  distinct_on?: Maybe<Array<Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Groups_Order_By>>;
  where?: Maybe<Groups_Bool_Exp>;
};


export type Query_RootGroups_AggregateArgs = {
  distinct_on?: Maybe<Array<Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Groups_Order_By>>;
  where?: Maybe<Groups_Bool_Exp>;
};


export type Query_RootGroups_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNumberCheckArgs = {
  mobile: Scalars['String'];
};


export type Query_RootOrder_Session_Connected_UsersArgs = {
  distinct_on?: Maybe<Array<Order_Session_Connected_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Session_Connected_Users_Order_By>>;
  where?: Maybe<Order_Session_Connected_Users_Bool_Exp>;
};


export type Query_RootOrder_Session_Connected_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Session_Connected_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Session_Connected_Users_Order_By>>;
  where?: Maybe<Order_Session_Connected_Users_Bool_Exp>;
};


export type Query_RootOrder_Session_Connected_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootOrder_Session_Groups_ResponsibleArgs = {
  distinct_on?: Maybe<Array<Order_Session_Groups_Responsible_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Session_Groups_Responsible_Order_By>>;
  where?: Maybe<Order_Session_Groups_Responsible_Bool_Exp>;
};


export type Query_RootOrder_Session_Groups_Responsible_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Session_Groups_Responsible_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Session_Groups_Responsible_Order_By>>;
  where?: Maybe<Order_Session_Groups_Responsible_Bool_Exp>;
};


export type Query_RootOrder_Session_Groups_Responsible_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootOrder_Session_Payment_MethodsArgs = {
  distinct_on?: Maybe<Array<Order_Session_Payment_Methods_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Session_Payment_Methods_Order_By>>;
  where?: Maybe<Order_Session_Payment_Methods_Bool_Exp>;
};


export type Query_RootOrder_Session_Payment_Methods_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Session_Payment_Methods_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Session_Payment_Methods_Order_By>>;
  where?: Maybe<Order_Session_Payment_Methods_Bool_Exp>;
};


export type Query_RootOrder_Session_Payment_Methods_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootOrder_SessionsArgs = {
  distinct_on?: Maybe<Array<Order_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Sessions_Order_By>>;
  where?: Maybe<Order_Sessions_Bool_Exp>;
};


export type Query_RootOrder_Sessions_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Sessions_Order_By>>;
  where?: Maybe<Order_Sessions_Bool_Exp>;
};


export type Query_RootOrder_Sessions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootOrder_ZreportsArgs = {
  distinct_on?: Maybe<Array<Order_Zreports_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Zreports_Order_By>>;
  where?: Maybe<Order_Zreports_Bool_Exp>;
};


export type Query_RootOrder_Zreports_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Zreports_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Zreports_Order_By>>;
  where?: Maybe<Order_Zreports_Bool_Exp>;
};


export type Query_RootOrder_Zreports_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootOrdersArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


export type Query_RootOrders_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


export type Query_RootOrders_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootOrganization_UsersArgs = {
  distinct_on?: Maybe<Array<Organization_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organization_Users_Order_By>>;
  where?: Maybe<Organization_Users_Bool_Exp>;
};


export type Query_RootOrganization_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Organization_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organization_Users_Order_By>>;
  where?: Maybe<Organization_Users_Bool_Exp>;
};


export type Query_RootOrganization_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootOrganizationsArgs = {
  distinct_on?: Maybe<Array<Organizations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organizations_Order_By>>;
  where?: Maybe<Organizations_Bool_Exp>;
};


export type Query_RootOrganizations_AggregateArgs = {
  distinct_on?: Maybe<Array<Organizations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organizations_Order_By>>;
  where?: Maybe<Organizations_Bool_Exp>;
};


export type Query_RootOrganizations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootOrgnumberCheckArgs = {
  orgnumber: Scalars['String'];
};


export type Query_RootParticipantsArgs = {
  distinct_on?: Maybe<Array<Participants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Participants_Order_By>>;
  where?: Maybe<Participants_Bool_Exp>;
};


export type Query_RootParticipants_AggregateArgs = {
  distinct_on?: Maybe<Array<Participants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Participants_Order_By>>;
  where?: Maybe<Participants_Bool_Exp>;
};


export type Query_RootParticipants_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPaymentmethodsArgs = {
  distinct_on?: Maybe<Array<Paymentmethods_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Paymentmethods_Order_By>>;
  where?: Maybe<Paymentmethods_Bool_Exp>;
};


export type Query_RootPaymentmethods_AggregateArgs = {
  distinct_on?: Maybe<Array<Paymentmethods_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Paymentmethods_Order_By>>;
  where?: Maybe<Paymentmethods_Bool_Exp>;
};


export type Query_RootPaymentmethods_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPointofsalesArgs = {
  distinct_on?: Maybe<Array<Pointofsales_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pointofsales_Order_By>>;
  where?: Maybe<Pointofsales_Bool_Exp>;
};


export type Query_RootPointofsales_AggregateArgs = {
  distinct_on?: Maybe<Array<Pointofsales_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pointofsales_Order_By>>;
  where?: Maybe<Pointofsales_Bool_Exp>;
};


export type Query_RootPointofsales_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProductsArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


export type Query_RootProducts_AggregateArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


export type Query_RootProducts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRefreshtokenArgs = {
  distinct_on?: Maybe<Array<Refreshtoken_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refreshtoken_Order_By>>;
  where?: Maybe<Refreshtoken_Bool_Exp>;
};


export type Query_RootRefreshtoken_AggregateArgs = {
  distinct_on?: Maybe<Array<Refreshtoken_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refreshtoken_Order_By>>;
  where?: Maybe<Refreshtoken_Bool_Exp>;
};


export type Query_RootRefreshtoken_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTagsArgs = {
  distinct_on?: Maybe<Array<Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tags_Order_By>>;
  where?: Maybe<Tags_Bool_Exp>;
};


export type Query_RootTags_AggregateArgs = {
  distinct_on?: Maybe<Array<Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tags_Order_By>>;
  where?: Maybe<Tags_Bool_Exp>;
};


export type Query_RootTags_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTemplatesArgs = {
  distinct_on?: Maybe<Array<Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Templates_Order_By>>;
  where?: Maybe<Templates_Bool_Exp>;
};


export type Query_RootTemplates_AggregateArgs = {
  distinct_on?: Maybe<Array<Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Templates_Order_By>>;
  where?: Maybe<Templates_Bool_Exp>;
};


export type Query_RootTemplates_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Query_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Query_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootVatratesArgs = {
  distinct_on?: Maybe<Array<Vatrates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vatrates_Order_By>>;
  where?: Maybe<Vatrates_Bool_Exp>;
};


export type Query_RootVatrates_AggregateArgs = {
  distinct_on?: Maybe<Array<Vatrates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vatrates_Order_By>>;
  where?: Maybe<Vatrates_Bool_Exp>;
};


export type Query_RootVatrates_By_PkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "refreshtoken" */
export type Refreshtoken = {
  __typename?: 'refreshtoken';
  expires_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "refreshtoken" */
export type Refreshtoken_Aggregate = {
  __typename?: 'refreshtoken_aggregate';
  aggregate?: Maybe<Refreshtoken_Aggregate_Fields>;
  nodes: Array<Refreshtoken>;
};

/** aggregate fields of "refreshtoken" */
export type Refreshtoken_Aggregate_Fields = {
  __typename?: 'refreshtoken_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Refreshtoken_Max_Fields>;
  min?: Maybe<Refreshtoken_Min_Fields>;
};


/** aggregate fields of "refreshtoken" */
export type Refreshtoken_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Refreshtoken_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "refreshtoken". All fields are combined with a logical 'AND'. */
export type Refreshtoken_Bool_Exp = {
  _and?: Maybe<Array<Refreshtoken_Bool_Exp>>;
  _not?: Maybe<Refreshtoken_Bool_Exp>;
  _or?: Maybe<Array<Refreshtoken_Bool_Exp>>;
  expires_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "refreshtoken" */
export enum Refreshtoken_Constraint {
  /** unique or primary key constraint */
  RefreshtokenPkey = 'refreshtoken_pkey'
}

/** input type for inserting data into table "refreshtoken" */
export type Refreshtoken_Insert_Input = {
  expires_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Refreshtoken_Max_Fields = {
  __typename?: 'refreshtoken_max_fields';
  expires_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Refreshtoken_Min_Fields = {
  __typename?: 'refreshtoken_min_fields';
  expires_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "refreshtoken" */
export type Refreshtoken_Mutation_Response = {
  __typename?: 'refreshtoken_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Refreshtoken>;
};

/** on conflict condition type for table "refreshtoken" */
export type Refreshtoken_On_Conflict = {
  constraint: Refreshtoken_Constraint;
  update_columns?: Array<Refreshtoken_Update_Column>;
  where?: Maybe<Refreshtoken_Bool_Exp>;
};

/** Ordering options when selecting data from "refreshtoken". */
export type Refreshtoken_Order_By = {
  expires_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: refreshtoken */
export type Refreshtoken_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "refreshtoken" */
export enum Refreshtoken_Select_Column {
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "refreshtoken" */
export type Refreshtoken_Set_Input = {
  expires_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "refreshtoken" */
export enum Refreshtoken_Update_Column {
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** An array relationship */
  agreement_contacts: Array<Agreement_Contacts>;
  /** An aggregate relationship */
  agreement_contacts_aggregate: Agreement_Contacts_Aggregate;
  /** fetch data from the table: "agreement_contacts" using primary key columns */
  agreement_contacts_by_pk?: Maybe<Agreement_Contacts>;
  /** fetch data from the table: "agreement_types" */
  agreement_types: Array<Agreement_Types>;
  /** fetch aggregated fields from the table: "agreement_types" */
  agreement_types_aggregate: Agreement_Types_Aggregate;
  /** fetch data from the table: "agreement_types" using primary key columns */
  agreement_types_by_pk?: Maybe<Agreement_Types>;
  /** An array relationship */
  categories: Array<Categories>;
  /** An aggregate relationship */
  categories_aggregate: Categories_Aggregate;
  /** fetch data from the table: "categories" using primary key columns */
  categories_by_pk?: Maybe<Categories>;
  /** An array relationship */
  groups: Array<Groups>;
  /** An aggregate relationship */
  groups_aggregate: Groups_Aggregate;
  /** fetch data from the table: "groups" using primary key columns */
  groups_by_pk?: Maybe<Groups>;
  /** An array relationship */
  order_session_connected_users: Array<Order_Session_Connected_Users>;
  /** An aggregate relationship */
  order_session_connected_users_aggregate: Order_Session_Connected_Users_Aggregate;
  /** fetch data from the table: "order_session_connected_users" using primary key columns */
  order_session_connected_users_by_pk?: Maybe<Order_Session_Connected_Users>;
  /** An array relationship */
  order_session_groups_responsible: Array<Order_Session_Groups_Responsible>;
  /** An aggregate relationship */
  order_session_groups_responsible_aggregate: Order_Session_Groups_Responsible_Aggregate;
  /** fetch data from the table: "order_session_groups_responsible" using primary key columns */
  order_session_groups_responsible_by_pk?: Maybe<Order_Session_Groups_Responsible>;
  /** An array relationship */
  order_session_payment_methods: Array<Order_Session_Payment_Methods>;
  /** An aggregate relationship */
  order_session_payment_methods_aggregate: Order_Session_Payment_Methods_Aggregate;
  /** fetch data from the table: "order_session_payment_methods" using primary key columns */
  order_session_payment_methods_by_pk?: Maybe<Order_Session_Payment_Methods>;
  /** An array relationship */
  order_sessions: Array<Order_Sessions>;
  /** An aggregate relationship */
  order_sessions_aggregate: Order_Sessions_Aggregate;
  /** fetch data from the table: "order_sessions" using primary key columns */
  order_sessions_by_pk?: Maybe<Order_Sessions>;
  /** fetch data from the table: "order_zreports" */
  order_zreports: Array<Order_Zreports>;
  /** fetch aggregated fields from the table: "order_zreports" */
  order_zreports_aggregate: Order_Zreports_Aggregate;
  /** fetch data from the table: "order_zreports" using primary key columns */
  order_zreports_by_pk?: Maybe<Order_Zreports>;
  /** fetch data from the table: "orders" */
  orders: Array<Orders>;
  /** fetch aggregated fields from the table: "orders" */
  orders_aggregate: Orders_Aggregate;
  /** fetch data from the table: "orders" using primary key columns */
  orders_by_pk?: Maybe<Orders>;
  /** An array relationship */
  organization_users: Array<Organization_Users>;
  /** An aggregate relationship */
  organization_users_aggregate: Organization_Users_Aggregate;
  /** fetch data from the table: "organization_users" using primary key columns */
  organization_users_by_pk?: Maybe<Organization_Users>;
  /** An array relationship */
  organizations: Array<Organizations>;
  /** An aggregate relationship */
  organizations_aggregate: Organizations_Aggregate;
  /** fetch data from the table: "organizations" using primary key columns */
  organizations_by_pk?: Maybe<Organizations>;
  /** An array relationship */
  participants: Array<Participants>;
  /** An aggregate relationship */
  participants_aggregate: Participants_Aggregate;
  /** fetch data from the table: "participants" using primary key columns */
  participants_by_pk?: Maybe<Participants>;
  /** An array relationship */
  paymentmethods: Array<Paymentmethods>;
  /** An aggregate relationship */
  paymentmethods_aggregate: Paymentmethods_Aggregate;
  /** fetch data from the table: "paymentmethods" using primary key columns */
  paymentmethods_by_pk?: Maybe<Paymentmethods>;
  /** fetch data from the table: "pointofsales" */
  pointofsales: Array<Pointofsales>;
  /** An aggregate relationship */
  pointofsales_aggregate: Pointofsales_Aggregate;
  /** fetch data from the table: "pointofsales" using primary key columns */
  pointofsales_by_pk?: Maybe<Pointofsales>;
  /** An array relationship */
  products: Array<Products>;
  /** An aggregate relationship */
  products_aggregate: Products_Aggregate;
  /** fetch data from the table: "products" using primary key columns */
  products_by_pk?: Maybe<Products>;
  /** fetch data from the table: "refreshtoken" */
  refreshtoken: Array<Refreshtoken>;
  /** fetch aggregated fields from the table: "refreshtoken" */
  refreshtoken_aggregate: Refreshtoken_Aggregate;
  /** fetch data from the table: "refreshtoken" using primary key columns */
  refreshtoken_by_pk?: Maybe<Refreshtoken>;
  /** An array relationship */
  tags: Array<Tags>;
  /** An aggregate relationship */
  tags_aggregate: Tags_Aggregate;
  /** fetch data from the table: "tags" using primary key columns */
  tags_by_pk?: Maybe<Tags>;
  /** An array relationship */
  templates: Array<Templates>;
  /** An aggregate relationship */
  templates_aggregate: Templates_Aggregate;
  /** fetch data from the table: "templates" using primary key columns */
  templates_by_pk?: Maybe<Templates>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "vatrates" */
  vatrates: Array<Vatrates>;
  /** fetch aggregated fields from the table: "vatrates" */
  vatrates_aggregate: Vatrates_Aggregate;
  /** fetch data from the table: "vatrates" using primary key columns */
  vatrates_by_pk?: Maybe<Vatrates>;
};


export type Subscription_RootAgreement_ContactsArgs = {
  distinct_on?: Maybe<Array<Agreement_Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agreement_Contacts_Order_By>>;
  where?: Maybe<Agreement_Contacts_Bool_Exp>;
};


export type Subscription_RootAgreement_Contacts_AggregateArgs = {
  distinct_on?: Maybe<Array<Agreement_Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agreement_Contacts_Order_By>>;
  where?: Maybe<Agreement_Contacts_Bool_Exp>;
};


export type Subscription_RootAgreement_Contacts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAgreement_TypesArgs = {
  distinct_on?: Maybe<Array<Agreement_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agreement_Types_Order_By>>;
  where?: Maybe<Agreement_Types_Bool_Exp>;
};


export type Subscription_RootAgreement_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Agreement_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agreement_Types_Order_By>>;
  where?: Maybe<Agreement_Types_Bool_Exp>;
};


export type Subscription_RootAgreement_Types_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCategoriesArgs = {
  distinct_on?: Maybe<Array<Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Categories_Order_By>>;
  where?: Maybe<Categories_Bool_Exp>;
};


export type Subscription_RootCategories_AggregateArgs = {
  distinct_on?: Maybe<Array<Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Categories_Order_By>>;
  where?: Maybe<Categories_Bool_Exp>;
};


export type Subscription_RootCategories_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGroupsArgs = {
  distinct_on?: Maybe<Array<Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Groups_Order_By>>;
  where?: Maybe<Groups_Bool_Exp>;
};


export type Subscription_RootGroups_AggregateArgs = {
  distinct_on?: Maybe<Array<Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Groups_Order_By>>;
  where?: Maybe<Groups_Bool_Exp>;
};


export type Subscription_RootGroups_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootOrder_Session_Connected_UsersArgs = {
  distinct_on?: Maybe<Array<Order_Session_Connected_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Session_Connected_Users_Order_By>>;
  where?: Maybe<Order_Session_Connected_Users_Bool_Exp>;
};


export type Subscription_RootOrder_Session_Connected_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Session_Connected_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Session_Connected_Users_Order_By>>;
  where?: Maybe<Order_Session_Connected_Users_Bool_Exp>;
};


export type Subscription_RootOrder_Session_Connected_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootOrder_Session_Groups_ResponsibleArgs = {
  distinct_on?: Maybe<Array<Order_Session_Groups_Responsible_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Session_Groups_Responsible_Order_By>>;
  where?: Maybe<Order_Session_Groups_Responsible_Bool_Exp>;
};


export type Subscription_RootOrder_Session_Groups_Responsible_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Session_Groups_Responsible_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Session_Groups_Responsible_Order_By>>;
  where?: Maybe<Order_Session_Groups_Responsible_Bool_Exp>;
};


export type Subscription_RootOrder_Session_Groups_Responsible_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootOrder_Session_Payment_MethodsArgs = {
  distinct_on?: Maybe<Array<Order_Session_Payment_Methods_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Session_Payment_Methods_Order_By>>;
  where?: Maybe<Order_Session_Payment_Methods_Bool_Exp>;
};


export type Subscription_RootOrder_Session_Payment_Methods_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Session_Payment_Methods_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Session_Payment_Methods_Order_By>>;
  where?: Maybe<Order_Session_Payment_Methods_Bool_Exp>;
};


export type Subscription_RootOrder_Session_Payment_Methods_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootOrder_SessionsArgs = {
  distinct_on?: Maybe<Array<Order_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Sessions_Order_By>>;
  where?: Maybe<Order_Sessions_Bool_Exp>;
};


export type Subscription_RootOrder_Sessions_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Sessions_Order_By>>;
  where?: Maybe<Order_Sessions_Bool_Exp>;
};


export type Subscription_RootOrder_Sessions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootOrder_ZreportsArgs = {
  distinct_on?: Maybe<Array<Order_Zreports_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Zreports_Order_By>>;
  where?: Maybe<Order_Zreports_Bool_Exp>;
};


export type Subscription_RootOrder_Zreports_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Zreports_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Zreports_Order_By>>;
  where?: Maybe<Order_Zreports_Bool_Exp>;
};


export type Subscription_RootOrder_Zreports_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootOrdersArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


export type Subscription_RootOrders_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


export type Subscription_RootOrders_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootOrganization_UsersArgs = {
  distinct_on?: Maybe<Array<Organization_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organization_Users_Order_By>>;
  where?: Maybe<Organization_Users_Bool_Exp>;
};


export type Subscription_RootOrganization_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Organization_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organization_Users_Order_By>>;
  where?: Maybe<Organization_Users_Bool_Exp>;
};


export type Subscription_RootOrganization_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootOrganizationsArgs = {
  distinct_on?: Maybe<Array<Organizations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organizations_Order_By>>;
  where?: Maybe<Organizations_Bool_Exp>;
};


export type Subscription_RootOrganizations_AggregateArgs = {
  distinct_on?: Maybe<Array<Organizations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organizations_Order_By>>;
  where?: Maybe<Organizations_Bool_Exp>;
};


export type Subscription_RootOrganizations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootParticipantsArgs = {
  distinct_on?: Maybe<Array<Participants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Participants_Order_By>>;
  where?: Maybe<Participants_Bool_Exp>;
};


export type Subscription_RootParticipants_AggregateArgs = {
  distinct_on?: Maybe<Array<Participants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Participants_Order_By>>;
  where?: Maybe<Participants_Bool_Exp>;
};


export type Subscription_RootParticipants_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPaymentmethodsArgs = {
  distinct_on?: Maybe<Array<Paymentmethods_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Paymentmethods_Order_By>>;
  where?: Maybe<Paymentmethods_Bool_Exp>;
};


export type Subscription_RootPaymentmethods_AggregateArgs = {
  distinct_on?: Maybe<Array<Paymentmethods_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Paymentmethods_Order_By>>;
  where?: Maybe<Paymentmethods_Bool_Exp>;
};


export type Subscription_RootPaymentmethods_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPointofsalesArgs = {
  distinct_on?: Maybe<Array<Pointofsales_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pointofsales_Order_By>>;
  where?: Maybe<Pointofsales_Bool_Exp>;
};


export type Subscription_RootPointofsales_AggregateArgs = {
  distinct_on?: Maybe<Array<Pointofsales_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pointofsales_Order_By>>;
  where?: Maybe<Pointofsales_Bool_Exp>;
};


export type Subscription_RootPointofsales_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProductsArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


export type Subscription_RootProducts_AggregateArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


export type Subscription_RootProducts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRefreshtokenArgs = {
  distinct_on?: Maybe<Array<Refreshtoken_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refreshtoken_Order_By>>;
  where?: Maybe<Refreshtoken_Bool_Exp>;
};


export type Subscription_RootRefreshtoken_AggregateArgs = {
  distinct_on?: Maybe<Array<Refreshtoken_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refreshtoken_Order_By>>;
  where?: Maybe<Refreshtoken_Bool_Exp>;
};


export type Subscription_RootRefreshtoken_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTagsArgs = {
  distinct_on?: Maybe<Array<Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tags_Order_By>>;
  where?: Maybe<Tags_Bool_Exp>;
};


export type Subscription_RootTags_AggregateArgs = {
  distinct_on?: Maybe<Array<Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tags_Order_By>>;
  where?: Maybe<Tags_Bool_Exp>;
};


export type Subscription_RootTags_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTemplatesArgs = {
  distinct_on?: Maybe<Array<Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Templates_Order_By>>;
  where?: Maybe<Templates_Bool_Exp>;
};


export type Subscription_RootTemplates_AggregateArgs = {
  distinct_on?: Maybe<Array<Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Templates_Order_By>>;
  where?: Maybe<Templates_Bool_Exp>;
};


export type Subscription_RootTemplates_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootVatratesArgs = {
  distinct_on?: Maybe<Array<Vatrates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vatrates_Order_By>>;
  where?: Maybe<Vatrates_Bool_Exp>;
};


export type Subscription_RootVatrates_AggregateArgs = {
  distinct_on?: Maybe<Array<Vatrates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vatrates_Order_By>>;
  where?: Maybe<Vatrates_Bool_Exp>;
};


export type Subscription_RootVatrates_By_PkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "tags" */
export type Tags = {
  __typename?: 'tags';
  active: Scalars['Boolean'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid'];
};

/** aggregated selection of "tags" */
export type Tags_Aggregate = {
  __typename?: 'tags_aggregate';
  aggregate?: Maybe<Tags_Aggregate_Fields>;
  nodes: Array<Tags>;
};

/** aggregate fields of "tags" */
export type Tags_Aggregate_Fields = {
  __typename?: 'tags_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Tags_Max_Fields>;
  min?: Maybe<Tags_Min_Fields>;
};


/** aggregate fields of "tags" */
export type Tags_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tags_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tags" */
export type Tags_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Tags_Max_Order_By>;
  min?: Maybe<Tags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tags" */
export type Tags_Arr_Rel_Insert_Input = {
  data: Array<Tags_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Tags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tags". All fields are combined with a logical 'AND'. */
export type Tags_Bool_Exp = {
  _and?: Maybe<Array<Tags_Bool_Exp>>;
  _not?: Maybe<Tags_Bool_Exp>;
  _or?: Maybe<Array<Tags_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  organization?: Maybe<Organizations_Bool_Exp>;
  organization_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "tags" */
export enum Tags_Constraint {
  /** unique or primary key constraint */
  TagsPkey = 'tags_pkey'
}

/** input type for inserting data into table "tags" */
export type Tags_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Tags_Max_Fields = {
  __typename?: 'tags_max_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "tags" */
export type Tags_Max_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Tags_Min_Fields = {
  __typename?: 'tags_min_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "tags" */
export type Tags_Min_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "tags" */
export type Tags_Mutation_Response = {
  __typename?: 'tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tags>;
};

/** on conflict condition type for table "tags" */
export type Tags_On_Conflict = {
  constraint: Tags_Constraint;
  update_columns?: Array<Tags_Update_Column>;
  where?: Maybe<Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "tags". */
export type Tags_Order_By = {
  active?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization?: Maybe<Organizations_Order_By>;
  organization_id?: Maybe<Order_By>;
};

/** primary key columns input for table: tags */
export type Tags_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "tags" */
export enum Tags_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id'
}

/** input type for updating data in table "tags" */
export type Tags_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "tags" */
export enum Tags_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id'
}

/** columns and relationships of "templates" */
export type Templates = {
  __typename?: 'templates';
  global: Scalars['Boolean'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An object relationship */
  organization?: Maybe<Organizations>;
  organization_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "templates" */
export type Templates_Aggregate = {
  __typename?: 'templates_aggregate';
  aggregate?: Maybe<Templates_Aggregate_Fields>;
  nodes: Array<Templates>;
};

/** aggregate fields of "templates" */
export type Templates_Aggregate_Fields = {
  __typename?: 'templates_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Templates_Max_Fields>;
  min?: Maybe<Templates_Min_Fields>;
};


/** aggregate fields of "templates" */
export type Templates_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Templates_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "templates" */
export type Templates_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Templates_Max_Order_By>;
  min?: Maybe<Templates_Min_Order_By>;
};

/** input type for inserting array relation for remote table "templates" */
export type Templates_Arr_Rel_Insert_Input = {
  data: Array<Templates_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Templates_On_Conflict>;
};

/** Boolean expression to filter rows from the table "templates". All fields are combined with a logical 'AND'. */
export type Templates_Bool_Exp = {
  _and?: Maybe<Array<Templates_Bool_Exp>>;
  _not?: Maybe<Templates_Bool_Exp>;
  _or?: Maybe<Array<Templates_Bool_Exp>>;
  global?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  organization?: Maybe<Organizations_Bool_Exp>;
  organization_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "templates" */
export enum Templates_Constraint {
  /** unique or primary key constraint */
  TemplatesPkey = 'templates_pkey'
}

/** input type for inserting data into table "templates" */
export type Templates_Insert_Input = {
  global?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Templates_Max_Fields = {
  __typename?: 'templates_max_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "templates" */
export type Templates_Max_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Templates_Min_Fields = {
  __typename?: 'templates_min_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "templates" */
export type Templates_Min_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "templates" */
export type Templates_Mutation_Response = {
  __typename?: 'templates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Templates>;
};

/** on conflict condition type for table "templates" */
export type Templates_On_Conflict = {
  constraint: Templates_Constraint;
  update_columns?: Array<Templates_Update_Column>;
  where?: Maybe<Templates_Bool_Exp>;
};

/** Ordering options when selecting data from "templates". */
export type Templates_Order_By = {
  global?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization?: Maybe<Organizations_Order_By>;
  organization_id?: Maybe<Order_By>;
};

/** primary key columns input for table: templates */
export type Templates_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "templates" */
export enum Templates_Select_Column {
  /** column name */
  Global = 'global',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id'
}

/** input type for updating data in table "templates" */
export type Templates_Set_Input = {
  global?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "templates" */
export enum Templates_Update_Column {
  /** column name */
  Global = 'global',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id'
}

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  active: Scalars['Boolean'];
  authcode?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  email_address?: Maybe<Scalars['String']>;
  email_confirmed: Scalars['Boolean'];
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  last_name?: Maybe<Scalars['String']>;
  locale: Scalars['String'];
  mobile_number: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  /** An array relationship */
  organization_users: Array<Organization_Users>;
  /** An aggregate relationship */
  organization_users_aggregate: Organization_Users_Aggregate;
  role: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "users" */
export type UsersOrganization_UsersArgs = {
  distinct_on?: Maybe<Array<Organization_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organization_Users_Order_By>>;
  where?: Maybe<Organization_Users_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersOrganization_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Organization_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organization_Users_Order_By>>;
  where?: Maybe<Organization_Users_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: Maybe<Array<Users_Bool_Exp>>;
  _not?: Maybe<Users_Bool_Exp>;
  _or?: Maybe<Array<Users_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  authcode?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Uuid_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_by?: Maybe<Uuid_Comparison_Exp>;
  email_address?: Maybe<String_Comparison_Exp>;
  email_confirmed?: Maybe<Boolean_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  locale?: Maybe<String_Comparison_Exp>;
  mobile_number?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  organization_users?: Maybe<Organization_Users_Bool_Exp>;
  role?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_by?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint */
  UsersMobileKey = 'users_mobile_key',
  /** unique or primary key constraint */
  UsersPkey = 'users_pkey'
}

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  authcode?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  email_address?: Maybe<Scalars['String']>;
  email_confirmed?: Maybe<Scalars['Boolean']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  mobile_number?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organization_users?: Maybe<Organization_Users_Arr_Rel_Insert_Input>;
  role?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  authcode?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  email_address?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  mobile_number?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  authcode?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  email_address?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  mobile_number?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** on conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: Maybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  active?: Maybe<Order_By>;
  authcode?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  deleted_by?: Maybe<Order_By>;
  email_address?: Maybe<Order_By>;
  email_confirmed?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  locale?: Maybe<Order_By>;
  mobile_number?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization_users_aggregate?: Maybe<Organization_Users_Aggregate_Order_By>;
  role?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Authcode = 'authcode',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  EmailAddress = 'email_address',
  /** column name */
  EmailConfirmed = 'email_confirmed',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Locale = 'locale',
  /** column name */
  MobileNumber = 'mobile_number',
  /** column name */
  Name = 'name',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  authcode?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['uuid']>;
  email_address?: Maybe<Scalars['String']>;
  email_confirmed?: Maybe<Scalars['Boolean']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  mobile_number?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Authcode = 'authcode',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  EmailAddress = 'email_address',
  /** column name */
  EmailConfirmed = 'email_confirmed',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Locale = 'locale',
  /** column name */
  MobileNumber = 'mobile_number',
  /** column name */
  Name = 'name',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "vatrates" */
export type Vatrates = {
  __typename?: 'vatrates';
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  products: Array<Products>;
  /** An aggregate relationship */
  products_aggregate: Products_Aggregate;
  rate: Scalars['numeric'];
  systemdefault: Scalars['Boolean'];
};


/** columns and relationships of "vatrates" */
export type VatratesProductsArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


/** columns and relationships of "vatrates" */
export type VatratesProducts_AggregateArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};

/** aggregated selection of "vatrates" */
export type Vatrates_Aggregate = {
  __typename?: 'vatrates_aggregate';
  aggregate?: Maybe<Vatrates_Aggregate_Fields>;
  nodes: Array<Vatrates>;
};

/** aggregate fields of "vatrates" */
export type Vatrates_Aggregate_Fields = {
  __typename?: 'vatrates_aggregate_fields';
  avg?: Maybe<Vatrates_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Vatrates_Max_Fields>;
  min?: Maybe<Vatrates_Min_Fields>;
  stddev?: Maybe<Vatrates_Stddev_Fields>;
  stddev_pop?: Maybe<Vatrates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vatrates_Stddev_Samp_Fields>;
  sum?: Maybe<Vatrates_Sum_Fields>;
  var_pop?: Maybe<Vatrates_Var_Pop_Fields>;
  var_samp?: Maybe<Vatrates_Var_Samp_Fields>;
  variance?: Maybe<Vatrates_Variance_Fields>;
};


/** aggregate fields of "vatrates" */
export type Vatrates_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Vatrates_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Vatrates_Avg_Fields = {
  __typename?: 'vatrates_avg_fields';
  rate?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "vatrates". All fields are combined with a logical 'AND'. */
export type Vatrates_Bool_Exp = {
  _and?: Maybe<Array<Vatrates_Bool_Exp>>;
  _not?: Maybe<Vatrates_Bool_Exp>;
  _or?: Maybe<Array<Vatrates_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  products?: Maybe<Products_Bool_Exp>;
  rate?: Maybe<Numeric_Comparison_Exp>;
  systemdefault?: Maybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "vatrates" */
export enum Vatrates_Constraint {
  /** unique or primary key constraint */
  VatratesPkey = 'vatrates_pkey'
}

/** input type for incrementing numeric columns in table "vatrates" */
export type Vatrates_Inc_Input = {
  rate?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "vatrates" */
export type Vatrates_Insert_Input = {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  products?: Maybe<Products_Arr_Rel_Insert_Input>;
  rate?: Maybe<Scalars['numeric']>;
  systemdefault?: Maybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Vatrates_Max_Fields = {
  __typename?: 'vatrates_max_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Vatrates_Min_Fields = {
  __typename?: 'vatrates_min_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "vatrates" */
export type Vatrates_Mutation_Response = {
  __typename?: 'vatrates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vatrates>;
};

/** on conflict condition type for table "vatrates" */
export type Vatrates_On_Conflict = {
  constraint: Vatrates_Constraint;
  update_columns?: Array<Vatrates_Update_Column>;
  where?: Maybe<Vatrates_Bool_Exp>;
};

/** Ordering options when selecting data from "vatrates". */
export type Vatrates_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  products_aggregate?: Maybe<Products_Aggregate_Order_By>;
  rate?: Maybe<Order_By>;
  systemdefault?: Maybe<Order_By>;
};

/** primary key columns input for table: vatrates */
export type Vatrates_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "vatrates" */
export enum Vatrates_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Rate = 'rate',
  /** column name */
  Systemdefault = 'systemdefault'
}

/** input type for updating data in table "vatrates" */
export type Vatrates_Set_Input = {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['numeric']>;
  systemdefault?: Maybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Vatrates_Stddev_Fields = {
  __typename?: 'vatrates_stddev_fields';
  rate?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Vatrates_Stddev_Pop_Fields = {
  __typename?: 'vatrates_stddev_pop_fields';
  rate?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Vatrates_Stddev_Samp_Fields = {
  __typename?: 'vatrates_stddev_samp_fields';
  rate?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Vatrates_Sum_Fields = {
  __typename?: 'vatrates_sum_fields';
  rate?: Maybe<Scalars['numeric']>;
};

/** update columns of table "vatrates" */
export enum Vatrates_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Rate = 'rate',
  /** column name */
  Systemdefault = 'systemdefault'
}

/** aggregate var_pop on columns */
export type Vatrates_Var_Pop_Fields = {
  __typename?: 'vatrates_var_pop_fields';
  rate?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Vatrates_Var_Samp_Fields = {
  __typename?: 'vatrates_var_samp_fields';
  rate?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Vatrates_Variance_Fields = {
  __typename?: 'vatrates_variance_fields';
  rate?: Maybe<Scalars['Float']>;
};

export type DealsDashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type DealsDashboardQuery = { __typename?: 'query_root', active: { __typename?: 'organizations_aggregate', aggregate?: { __typename?: 'organizations_aggregate_fields', count: number } | null | undefined }, inactive: { __typename?: 'organizations_aggregate', aggregate?: { __typename?: 'organizations_aggregate_fields', count: number } | null | undefined }, expired: Array<{ __typename?: 'organizations', id: any, name: string, active_agreement: boolean, agreement_code: string, organization_number: string, agreement_contacts: Array<{ __typename?: 'agreement_contacts', id: any, agreement_contact_name: string, agreement_contact_email: string, agreement_contact_phone: string }> }>, expiring: Array<{ __typename?: 'organizations', id: any, name: string, active_agreement: boolean, agreement_code: string, organization_number: string, agreement_contacts: Array<{ __typename?: 'agreement_contacts', id: any, agreement_contact_name: string, agreement_contact_email: string, agreement_contact_phone: string }> }>, agreement_types: Array<{ __typename?: 'agreement_types', code: string, rate: any, organizations: Array<{ __typename?: 'organizations', id: any, name: string, zreports_aggregate: { __typename?: 'order_zreports_aggregate', aggregate?: { __typename?: 'order_zreports_aggregate_fields', sum?: { __typename?: 'order_zreports_sum_fields', total_sales?: any | null | undefined } | null | undefined } | null | undefined } }>, organizations_aggregate: { __typename?: 'organizations_aggregate', aggregate?: { __typename?: 'organizations_aggregate_fields', count: number } | null | undefined } }> };

export type AllOrganizationsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type AllOrganizationsQuery = { __typename?: 'query_root', organizations_aggregate: { __typename?: 'organizations_aggregate', aggregate?: { __typename?: 'organizations_aggregate_fields', count: number } | null | undefined }, organizations: Array<{ __typename?: 'organizations', id: any, name: string, organization_number: string, agreement_code: string, active_agreement: boolean, agreement_contacts: Array<{ __typename?: 'agreement_contacts', agreement_contact_name: string, agreement_contact_email: string, agreement_contact_phone: string }>, zreports_aggregate: { __typename?: 'order_zreports_aggregate', aggregate?: { __typename?: 'order_zreports_aggregate_fields', count: number, sum?: { __typename?: 'order_zreports_sum_fields', total_sales?: any | null | undefined } | null | undefined } | null | undefined } }> };

export type GetOrgByPkQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetOrgByPkQuery = { __typename?: 'query_root', organizations_by_pk?: { __typename?: 'organizations', id: any, name: string, icon: string, registered_for_vat: boolean } | null | undefined };

export type AllOrganizationsWithDealQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  agreement_code?: Maybe<Scalars['String']>;
}>;


export type AllOrganizationsWithDealQuery = { __typename?: 'query_root', organizations_aggregate: { __typename?: 'organizations_aggregate', aggregate?: { __typename?: 'organizations_aggregate_fields', count: number } | null | undefined }, organizations: Array<{ __typename?: 'organizations', id: any, name: string, active_agreement: boolean, agreement_code: string, organization_number: string, agreement_contacts: Array<{ __typename?: 'agreement_contacts', id: any, agreement_contact_name: string, agreement_contact_email: string, agreement_contact_phone: string }> }> };

export type QuickstatsQueryVariables = Exact<{ [key: string]: never; }>;


export type QuickstatsQuery = { __typename?: 'query_root', organizations: { __typename?: 'organizations_aggregate', aggregate?: { __typename?: 'organizations_aggregate_fields', count: number } | null | undefined }, total_sales: { __typename?: 'order_zreports_aggregate', aggregate?: { __typename?: 'order_zreports_aggregate_fields', sum?: { __typename?: 'order_zreports_sum_fields', total_sales?: any | null | undefined } | null | undefined } | null | undefined }, users: { __typename?: 'users_aggregate', aggregate?: { __typename?: 'users_aggregate_fields', count: number } | null | undefined }, turnover: Array<{ __typename?: 'agreement_types', name: string, rate: any, organizations: Array<{ __typename?: 'organizations', zreports_aggregate: { __typename?: 'order_zreports_aggregate', aggregate?: { __typename?: 'order_zreports_aggregate_fields', sum?: { __typename?: 'order_zreports_sum_fields', total_sales?: any | null | undefined } | null | undefined } | null | undefined } }> }> };

export type GetAllZreportsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllZreportsQuery = { __typename?: 'query_root', order_zreports: Array<{ __typename?: 'order_zreports', date: any, total_sales: any }> };

export type GetTop5OrgsSalesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTop5OrgsSalesQuery = { __typename?: 'query_root', organizations: Array<{ __typename?: 'organizations', name: string, id: any, zreports_aggregate: { __typename?: 'order_zreports_aggregate', aggregate?: { __typename?: 'order_zreports_aggregate_fields', count: number, sum?: { __typename?: 'order_zreports_sum_fields', total_sales?: any | null | undefined } | null | undefined } | null | undefined } }> };

export type GetTop5OrgsNumZreportsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTop5OrgsNumZreportsQuery = { __typename?: 'query_root', organizations: Array<{ __typename?: 'organizations', name: string, id: any, zreports_aggregate: { __typename?: 'order_zreports_aggregate', aggregate?: { __typename?: 'order_zreports_aggregate_fields', count: number, sum?: { __typename?: 'order_zreports_sum_fields', total_sales?: any | null | undefined } | null | undefined } | null | undefined } }> };

export type GetTop5OrgsNumPosQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTop5OrgsNumPosQuery = { __typename?: 'query_root', organizations: Array<{ __typename?: 'organizations', name: string, id: any, pointofsales_aggregate: { __typename?: 'pointofsales_aggregate', aggregate?: { __typename?: 'pointofsales_aggregate_fields', count: number } | null | undefined } }> };

export type GetTop5OrgsNumUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTop5OrgsNumUsersQuery = { __typename?: 'query_root', organizations: Array<{ __typename?: 'organizations', name: string, id: any, organization_users_aggregate: { __typename?: 'organization_users_aggregate', aggregate?: { __typename?: 'organization_users_aggregate_fields', count: number } | null | undefined } }> };

export type GetTop5PosSalesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTop5PosSalesQuery = { __typename?: 'query_root', pointofsales: Array<{ __typename?: 'pointofsales', name: string, id: any, organization: { __typename?: 'organizations', name: string, id: any }, zreports_aggregate: { __typename?: 'order_zreports_aggregate', aggregate?: { __typename?: 'order_zreports_aggregate_fields', count: number, sum?: { __typename?: 'order_zreports_sum_fields', total_sales?: any | null | undefined } | null | undefined } | null | undefined } }> };

export type GetTop5PosNumZreportsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTop5PosNumZreportsQuery = { __typename?: 'query_root', pointofsales: Array<{ __typename?: 'pointofsales', name: string, id: any, organization: { __typename?: 'organizations', name: string, id: any }, zreports_aggregate: { __typename?: 'order_zreports_aggregate', aggregate?: { __typename?: 'order_zreports_aggregate_fields', count: number, sum?: { __typename?: 'order_zreports_sum_fields', total_sales?: any | null | undefined } | null | undefined } | null | undefined } }> };

export type SystemSearchQueryVariables = Exact<{
  str?: Maybe<Scalars['String']>;
}>;


export type SystemSearchQuery = { __typename?: 'query_root', organizations: Array<{ __typename?: 'organizations', id: any, name: string, organization_number: string }>, users: Array<{ __typename?: 'users', first_name?: string | null | undefined, last_name?: string | null | undefined, mobile_number: string, id: any }> };

export type GetVatRatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetVatRatesQuery = { __typename?: 'query_root', vatrates: Array<{ __typename?: 'vatrates', id: any, name: string, rate: any, systemdefault: boolean, products_aggregate: { __typename?: 'products_aggregate', aggregate?: { __typename?: 'products_aggregate_fields', count: number } | null | undefined } }> };

export type AddVatrateMutationVariables = Exact<{
  name: Scalars['String'];
  rate?: Maybe<Scalars['numeric']>;
}>;


export type AddVatrateMutation = { __typename?: 'mutation_root', insert_vatrates_one?: { __typename?: 'vatrates', id: any } | null | undefined };

export type DeleteVatrateMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteVatrateMutation = { __typename?: 'mutation_root', delete_vatrates_by_pk?: { __typename?: 'vatrates', id: any } | null | undefined };

export type GetAgreementTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAgreementTypesQuery = { __typename?: 'query_root', agreement_types: Array<{ __typename?: 'agreement_types', id: any, code: string, valid_num_days?: any | null | undefined, getaccept_template_id?: string | null | undefined, name: string, description?: string | null | undefined, rate: any, created_at: any, updated_at: any, organizations_aggregate: { __typename?: 'organizations_aggregate', aggregate?: { __typename?: 'organizations_aggregate_fields', count: number } | null | undefined } }> };

export type AddAgreementTypeMutationVariables = Exact<{
  code: Scalars['String'];
  valid_num_days?: Maybe<Scalars['numeric']>;
  getaccept_template_id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description: Scalars['String'];
  rate?: Maybe<Scalars['numeric']>;
}>;


export type AddAgreementTypeMutation = { __typename?: 'mutation_root', insert_agreement_types_one?: { __typename?: 'agreement_types', id: any } | null | undefined };

export type AllUsersQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type AllUsersQuery = { __typename?: 'query_root', users_aggregate: { __typename?: 'users_aggregate', aggregate?: { __typename?: 'users_aggregate_fields', count: number } | null | undefined }, users: Array<{ __typename?: 'users', id: any, first_name?: string | null | undefined, last_name?: string | null | undefined, email_address?: string | null | undefined, mobile_number: string, organization_users: Array<{ __typename?: 'organization_users', organization: { __typename?: 'organizations', name: string } }>, organization_users_aggregate: { __typename?: 'organization_users_aggregate', aggregate?: { __typename?: 'organization_users_aggregate_fields', count: number } | null | undefined } }> };

export type GetUserByPkQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetUserByPkQuery = { __typename?: 'query_root', users_by_pk?: { __typename?: 'users', id: any, first_name?: string | null | undefined, last_name?: string | null | undefined, mobile_number: string } | null | undefined };


export const DealsDashboardDocument = gql`
    query dealsDashboard {
  active: organizations_aggregate(where: {active_agreement: {_eq: true}}) {
    aggregate {
      count
    }
  }
  inactive: organizations_aggregate(where: {active_agreement: {_eq: false}}) {
    aggregate {
      count
    }
  }
  expired: organizations(where: {active_agreement: {_eq: false}}) {
    id
    name
    active_agreement
    agreement_code
    organization_number
    agreement_contacts {
      id
      agreement_contact_name
      agreement_contact_email
      agreement_contact_phone
    }
  }
  expiring: organizations(
    where: {_and: [{agreement_code: {_eq: "TRIAL"}}, {active_agreement: {_eq: true}}]}
  ) {
    id
    name
    active_agreement
    agreement_code
    organization_number
    agreement_contacts {
      id
      agreement_contact_name
      agreement_contact_email
      agreement_contact_phone
    }
  }
  agreement_types {
    code
    rate
    organizations {
      id
      name
      zreports_aggregate {
        aggregate {
          sum {
            total_sales
          }
        }
      }
    }
    organizations_aggregate {
      aggregate {
        count(distinct: true)
      }
    }
  }
}
    `;

/**
 * __useDealsDashboardQuery__
 *
 * To run a query within a React component, call `useDealsDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealsDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealsDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useDealsDashboardQuery(baseOptions?: Apollo.QueryHookOptions<DealsDashboardQuery, DealsDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DealsDashboardQuery, DealsDashboardQueryVariables>(DealsDashboardDocument, options);
      }
export function useDealsDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DealsDashboardQuery, DealsDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DealsDashboardQuery, DealsDashboardQueryVariables>(DealsDashboardDocument, options);
        }
export type DealsDashboardQueryHookResult = ReturnType<typeof useDealsDashboardQuery>;
export type DealsDashboardLazyQueryHookResult = ReturnType<typeof useDealsDashboardLazyQuery>;
export type DealsDashboardQueryResult = Apollo.QueryResult<DealsDashboardQuery, DealsDashboardQueryVariables>;
export const AllOrganizationsDocument = gql`
    query allOrganizations($limit: Int = 25, $offset: Int = 0) {
  organizations_aggregate {
    aggregate {
      count
    }
  }
  organizations(limit: $limit, offset: $offset, order_by: {name: asc_nulls_last}) {
    id
    name
    organization_number
    agreement_code
    active_agreement
    agreement_contacts {
      agreement_contact_name
      agreement_contact_email
      agreement_contact_phone
    }
    zreports_aggregate {
      aggregate {
        count
        sum {
          total_sales
        }
      }
    }
  }
}
    `;

/**
 * __useAllOrganizationsQuery__
 *
 * To run a query within a React component, call `useAllOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllOrganizationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAllOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<AllOrganizationsQuery, AllOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllOrganizationsQuery, AllOrganizationsQueryVariables>(AllOrganizationsDocument, options);
      }
export function useAllOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllOrganizationsQuery, AllOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllOrganizationsQuery, AllOrganizationsQueryVariables>(AllOrganizationsDocument, options);
        }
export type AllOrganizationsQueryHookResult = ReturnType<typeof useAllOrganizationsQuery>;
export type AllOrganizationsLazyQueryHookResult = ReturnType<typeof useAllOrganizationsLazyQuery>;
export type AllOrganizationsQueryResult = Apollo.QueryResult<AllOrganizationsQuery, AllOrganizationsQueryVariables>;
export const GetOrgByPkDocument = gql`
    query getOrgByPk($id: uuid!) {
  organizations_by_pk(id: $id) {
    id
    name
    icon
    registered_for_vat
  }
}
    `;

/**
 * __useGetOrgByPkQuery__
 *
 * To run a query within a React component, call `useGetOrgByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrgByPkQuery(baseOptions: Apollo.QueryHookOptions<GetOrgByPkQuery, GetOrgByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrgByPkQuery, GetOrgByPkQueryVariables>(GetOrgByPkDocument, options);
      }
export function useGetOrgByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrgByPkQuery, GetOrgByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrgByPkQuery, GetOrgByPkQueryVariables>(GetOrgByPkDocument, options);
        }
export type GetOrgByPkQueryHookResult = ReturnType<typeof useGetOrgByPkQuery>;
export type GetOrgByPkLazyQueryHookResult = ReturnType<typeof useGetOrgByPkLazyQuery>;
export type GetOrgByPkQueryResult = Apollo.QueryResult<GetOrgByPkQuery, GetOrgByPkQueryVariables>;
export const AllOrganizationsWithDealDocument = gql`
    query allOrganizationsWithDeal($limit: Int = 25, $offset: Int = 0, $agreement_code: String = "%") {
  organizations_aggregate {
    aggregate {
      count
    }
  }
  organizations(
    limit: $limit
    offset: $offset
    order_by: {name: asc_nulls_last}
    where: {agreement_code: {_ilike: $agreement_code}}
  ) {
    id
    name
    active_agreement
    agreement_code
    organization_number
    agreement_contacts {
      id
      agreement_contact_name
      agreement_contact_email
      agreement_contact_phone
    }
  }
}
    `;

/**
 * __useAllOrganizationsWithDealQuery__
 *
 * To run a query within a React component, call `useAllOrganizationsWithDealQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllOrganizationsWithDealQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllOrganizationsWithDealQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      agreement_code: // value for 'agreement_code'
 *   },
 * });
 */
export function useAllOrganizationsWithDealQuery(baseOptions?: Apollo.QueryHookOptions<AllOrganizationsWithDealQuery, AllOrganizationsWithDealQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllOrganizationsWithDealQuery, AllOrganizationsWithDealQueryVariables>(AllOrganizationsWithDealDocument, options);
      }
export function useAllOrganizationsWithDealLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllOrganizationsWithDealQuery, AllOrganizationsWithDealQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllOrganizationsWithDealQuery, AllOrganizationsWithDealQueryVariables>(AllOrganizationsWithDealDocument, options);
        }
export type AllOrganizationsWithDealQueryHookResult = ReturnType<typeof useAllOrganizationsWithDealQuery>;
export type AllOrganizationsWithDealLazyQueryHookResult = ReturnType<typeof useAllOrganizationsWithDealLazyQuery>;
export type AllOrganizationsWithDealQueryResult = Apollo.QueryResult<AllOrganizationsWithDealQuery, AllOrganizationsWithDealQueryVariables>;
export const QuickstatsDocument = gql`
    query quickstats {
  organizations: organizations_aggregate {
    aggregate {
      count
    }
  }
  total_sales: order_zreports_aggregate {
    aggregate {
      sum {
        total_sales
      }
    }
  }
  users: users_aggregate {
    aggregate {
      count
    }
  }
  turnover: agreement_types(where: {rate: {_gt: 0}}) {
    name
    rate
    organizations {
      zreports_aggregate {
        aggregate {
          sum {
            total_sales
          }
        }
      }
    }
  }
}
    `;

/**
 * __useQuickstatsQuery__
 *
 * To run a query within a React component, call `useQuickstatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickstatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickstatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useQuickstatsQuery(baseOptions?: Apollo.QueryHookOptions<QuickstatsQuery, QuickstatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuickstatsQuery, QuickstatsQueryVariables>(QuickstatsDocument, options);
      }
export function useQuickstatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuickstatsQuery, QuickstatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuickstatsQuery, QuickstatsQueryVariables>(QuickstatsDocument, options);
        }
export type QuickstatsQueryHookResult = ReturnType<typeof useQuickstatsQuery>;
export type QuickstatsLazyQueryHookResult = ReturnType<typeof useQuickstatsLazyQuery>;
export type QuickstatsQueryResult = Apollo.QueryResult<QuickstatsQuery, QuickstatsQueryVariables>;
export const GetAllZreportsDocument = gql`
    query getAllZreports {
  order_zreports {
    date
    total_sales
  }
}
    `;

/**
 * __useGetAllZreportsQuery__
 *
 * To run a query within a React component, call `useGetAllZreportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllZreportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllZreportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllZreportsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllZreportsQuery, GetAllZreportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllZreportsQuery, GetAllZreportsQueryVariables>(GetAllZreportsDocument, options);
      }
export function useGetAllZreportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllZreportsQuery, GetAllZreportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllZreportsQuery, GetAllZreportsQueryVariables>(GetAllZreportsDocument, options);
        }
export type GetAllZreportsQueryHookResult = ReturnType<typeof useGetAllZreportsQuery>;
export type GetAllZreportsLazyQueryHookResult = ReturnType<typeof useGetAllZreportsLazyQuery>;
export type GetAllZreportsQueryResult = Apollo.QueryResult<GetAllZreportsQuery, GetAllZreportsQueryVariables>;
export const GetTop5OrgsSalesDocument = gql`
    query getTop5OrgsSales {
  organizations(
    order_by: {zreports_aggregate: {sum: {total_sales: desc_nulls_last}}}
  ) {
    name
    id
    zreports_aggregate {
      aggregate {
        count
        sum {
          total_sales
        }
      }
    }
  }
}
    `;

/**
 * __useGetTop5OrgsSalesQuery__
 *
 * To run a query within a React component, call `useGetTop5OrgsSalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTop5OrgsSalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTop5OrgsSalesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTop5OrgsSalesQuery(baseOptions?: Apollo.QueryHookOptions<GetTop5OrgsSalesQuery, GetTop5OrgsSalesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTop5OrgsSalesQuery, GetTop5OrgsSalesQueryVariables>(GetTop5OrgsSalesDocument, options);
      }
export function useGetTop5OrgsSalesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTop5OrgsSalesQuery, GetTop5OrgsSalesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTop5OrgsSalesQuery, GetTop5OrgsSalesQueryVariables>(GetTop5OrgsSalesDocument, options);
        }
export type GetTop5OrgsSalesQueryHookResult = ReturnType<typeof useGetTop5OrgsSalesQuery>;
export type GetTop5OrgsSalesLazyQueryHookResult = ReturnType<typeof useGetTop5OrgsSalesLazyQuery>;
export type GetTop5OrgsSalesQueryResult = Apollo.QueryResult<GetTop5OrgsSalesQuery, GetTop5OrgsSalesQueryVariables>;
export const GetTop5OrgsNumZreportsDocument = gql`
    query getTop5OrgsNumZreports {
  organizations(order_by: {zreports_aggregate: {count: desc_nulls_last}}) {
    name
    id
    zreports_aggregate {
      aggregate {
        count
        sum {
          total_sales
        }
      }
    }
  }
}
    `;

/**
 * __useGetTop5OrgsNumZreportsQuery__
 *
 * To run a query within a React component, call `useGetTop5OrgsNumZreportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTop5OrgsNumZreportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTop5OrgsNumZreportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTop5OrgsNumZreportsQuery(baseOptions?: Apollo.QueryHookOptions<GetTop5OrgsNumZreportsQuery, GetTop5OrgsNumZreportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTop5OrgsNumZreportsQuery, GetTop5OrgsNumZreportsQueryVariables>(GetTop5OrgsNumZreportsDocument, options);
      }
export function useGetTop5OrgsNumZreportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTop5OrgsNumZreportsQuery, GetTop5OrgsNumZreportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTop5OrgsNumZreportsQuery, GetTop5OrgsNumZreportsQueryVariables>(GetTop5OrgsNumZreportsDocument, options);
        }
export type GetTop5OrgsNumZreportsQueryHookResult = ReturnType<typeof useGetTop5OrgsNumZreportsQuery>;
export type GetTop5OrgsNumZreportsLazyQueryHookResult = ReturnType<typeof useGetTop5OrgsNumZreportsLazyQuery>;
export type GetTop5OrgsNumZreportsQueryResult = Apollo.QueryResult<GetTop5OrgsNumZreportsQuery, GetTop5OrgsNumZreportsQueryVariables>;
export const GetTop5OrgsNumPosDocument = gql`
    query getTop5OrgsNumPos {
  organizations(order_by: {pointofsales_aggregate: {count: desc_nulls_last}}) {
    name
    id
    pointofsales_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetTop5OrgsNumPosQuery__
 *
 * To run a query within a React component, call `useGetTop5OrgsNumPosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTop5OrgsNumPosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTop5OrgsNumPosQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTop5OrgsNumPosQuery(baseOptions?: Apollo.QueryHookOptions<GetTop5OrgsNumPosQuery, GetTop5OrgsNumPosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTop5OrgsNumPosQuery, GetTop5OrgsNumPosQueryVariables>(GetTop5OrgsNumPosDocument, options);
      }
export function useGetTop5OrgsNumPosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTop5OrgsNumPosQuery, GetTop5OrgsNumPosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTop5OrgsNumPosQuery, GetTop5OrgsNumPosQueryVariables>(GetTop5OrgsNumPosDocument, options);
        }
export type GetTop5OrgsNumPosQueryHookResult = ReturnType<typeof useGetTop5OrgsNumPosQuery>;
export type GetTop5OrgsNumPosLazyQueryHookResult = ReturnType<typeof useGetTop5OrgsNumPosLazyQuery>;
export type GetTop5OrgsNumPosQueryResult = Apollo.QueryResult<GetTop5OrgsNumPosQuery, GetTop5OrgsNumPosQueryVariables>;
export const GetTop5OrgsNumUsersDocument = gql`
    query getTop5OrgsNumUsers {
  organizations(
    order_by: {organization_users_aggregate: {count: desc_nulls_last}}
  ) {
    name
    id
    organization_users_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetTop5OrgsNumUsersQuery__
 *
 * To run a query within a React component, call `useGetTop5OrgsNumUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTop5OrgsNumUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTop5OrgsNumUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTop5OrgsNumUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetTop5OrgsNumUsersQuery, GetTop5OrgsNumUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTop5OrgsNumUsersQuery, GetTop5OrgsNumUsersQueryVariables>(GetTop5OrgsNumUsersDocument, options);
      }
export function useGetTop5OrgsNumUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTop5OrgsNumUsersQuery, GetTop5OrgsNumUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTop5OrgsNumUsersQuery, GetTop5OrgsNumUsersQueryVariables>(GetTop5OrgsNumUsersDocument, options);
        }
export type GetTop5OrgsNumUsersQueryHookResult = ReturnType<typeof useGetTop5OrgsNumUsersQuery>;
export type GetTop5OrgsNumUsersLazyQueryHookResult = ReturnType<typeof useGetTop5OrgsNumUsersLazyQuery>;
export type GetTop5OrgsNumUsersQueryResult = Apollo.QueryResult<GetTop5OrgsNumUsersQuery, GetTop5OrgsNumUsersQueryVariables>;
export const GetTop5PosSalesDocument = gql`
    query getTop5PosSales {
  pointofsales(
    order_by: {zreports_aggregate: {sum: {total_sales: desc_nulls_last}}}
  ) {
    name
    id
    organization {
      name
      id
    }
    zreports_aggregate {
      aggregate {
        count
        sum {
          total_sales
        }
      }
    }
  }
}
    `;

/**
 * __useGetTop5PosSalesQuery__
 *
 * To run a query within a React component, call `useGetTop5PosSalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTop5PosSalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTop5PosSalesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTop5PosSalesQuery(baseOptions?: Apollo.QueryHookOptions<GetTop5PosSalesQuery, GetTop5PosSalesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTop5PosSalesQuery, GetTop5PosSalesQueryVariables>(GetTop5PosSalesDocument, options);
      }
export function useGetTop5PosSalesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTop5PosSalesQuery, GetTop5PosSalesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTop5PosSalesQuery, GetTop5PosSalesQueryVariables>(GetTop5PosSalesDocument, options);
        }
export type GetTop5PosSalesQueryHookResult = ReturnType<typeof useGetTop5PosSalesQuery>;
export type GetTop5PosSalesLazyQueryHookResult = ReturnType<typeof useGetTop5PosSalesLazyQuery>;
export type GetTop5PosSalesQueryResult = Apollo.QueryResult<GetTop5PosSalesQuery, GetTop5PosSalesQueryVariables>;
export const GetTop5PosNumZreportsDocument = gql`
    query getTop5PosNumZreports {
  pointofsales(order_by: {zreports_aggregate: {count: desc_nulls_last}}) {
    name
    id
    organization {
      name
      id
    }
    zreports_aggregate {
      aggregate {
        count
        sum {
          total_sales
        }
      }
    }
  }
}
    `;

/**
 * __useGetTop5PosNumZreportsQuery__
 *
 * To run a query within a React component, call `useGetTop5PosNumZreportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTop5PosNumZreportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTop5PosNumZreportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTop5PosNumZreportsQuery(baseOptions?: Apollo.QueryHookOptions<GetTop5PosNumZreportsQuery, GetTop5PosNumZreportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTop5PosNumZreportsQuery, GetTop5PosNumZreportsQueryVariables>(GetTop5PosNumZreportsDocument, options);
      }
export function useGetTop5PosNumZreportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTop5PosNumZreportsQuery, GetTop5PosNumZreportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTop5PosNumZreportsQuery, GetTop5PosNumZreportsQueryVariables>(GetTop5PosNumZreportsDocument, options);
        }
export type GetTop5PosNumZreportsQueryHookResult = ReturnType<typeof useGetTop5PosNumZreportsQuery>;
export type GetTop5PosNumZreportsLazyQueryHookResult = ReturnType<typeof useGetTop5PosNumZreportsLazyQuery>;
export type GetTop5PosNumZreportsQueryResult = Apollo.QueryResult<GetTop5PosNumZreportsQuery, GetTop5PosNumZreportsQueryVariables>;
export const SystemSearchDocument = gql`
    query systemSearch($str: String) {
  organizations(
    where: {_or: [{name: {_ilike: $str}}, {organization_number: {_ilike: $str}}]}
    order_by: {name: asc_nulls_last}
    limit: 10
  ) {
    id
    name
    organization_number
  }
  users(
    where: {_or: [{first_name: {_ilike: $str}}, {last_name: {_ilike: $str}}, {mobile_number: {_ilike: $str}}]}
    order_by: {first_name: asc_nulls_last, last_name: asc_nulls_last}
    limit: 10
  ) {
    first_name
    last_name
    mobile_number
    id
  }
}
    `;

/**
 * __useSystemSearchQuery__
 *
 * To run a query within a React component, call `useSystemSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemSearchQuery({
 *   variables: {
 *      str: // value for 'str'
 *   },
 * });
 */
export function useSystemSearchQuery(baseOptions?: Apollo.QueryHookOptions<SystemSearchQuery, SystemSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SystemSearchQuery, SystemSearchQueryVariables>(SystemSearchDocument, options);
      }
export function useSystemSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SystemSearchQuery, SystemSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SystemSearchQuery, SystemSearchQueryVariables>(SystemSearchDocument, options);
        }
export type SystemSearchQueryHookResult = ReturnType<typeof useSystemSearchQuery>;
export type SystemSearchLazyQueryHookResult = ReturnType<typeof useSystemSearchLazyQuery>;
export type SystemSearchQueryResult = Apollo.QueryResult<SystemSearchQuery, SystemSearchQueryVariables>;
export const GetVatRatesDocument = gql`
    query getVatRates {
  vatrates(order_by: {rate: asc_nulls_last}) {
    id
    name
    rate
    systemdefault
    products_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetVatRatesQuery__
 *
 * To run a query within a React component, call `useGetVatRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVatRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVatRatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVatRatesQuery(baseOptions?: Apollo.QueryHookOptions<GetVatRatesQuery, GetVatRatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVatRatesQuery, GetVatRatesQueryVariables>(GetVatRatesDocument, options);
      }
export function useGetVatRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVatRatesQuery, GetVatRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVatRatesQuery, GetVatRatesQueryVariables>(GetVatRatesDocument, options);
        }
export type GetVatRatesQueryHookResult = ReturnType<typeof useGetVatRatesQuery>;
export type GetVatRatesLazyQueryHookResult = ReturnType<typeof useGetVatRatesLazyQuery>;
export type GetVatRatesQueryResult = Apollo.QueryResult<GetVatRatesQuery, GetVatRatesQueryVariables>;
export const AddVatrateDocument = gql`
    mutation addVatrate($name: String!, $rate: numeric) {
  insert_vatrates_one(object: {name: $name, rate: $rate}) {
    id
  }
}
    `;
export type AddVatrateMutationFn = Apollo.MutationFunction<AddVatrateMutation, AddVatrateMutationVariables>;

/**
 * __useAddVatrateMutation__
 *
 * To run a mutation, you first call `useAddVatrateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVatrateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVatrateMutation, { data, loading, error }] = useAddVatrateMutation({
 *   variables: {
 *      name: // value for 'name'
 *      rate: // value for 'rate'
 *   },
 * });
 */
export function useAddVatrateMutation(baseOptions?: Apollo.MutationHookOptions<AddVatrateMutation, AddVatrateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddVatrateMutation, AddVatrateMutationVariables>(AddVatrateDocument, options);
      }
export type AddVatrateMutationHookResult = ReturnType<typeof useAddVatrateMutation>;
export type AddVatrateMutationResult = Apollo.MutationResult<AddVatrateMutation>;
export type AddVatrateMutationOptions = Apollo.BaseMutationOptions<AddVatrateMutation, AddVatrateMutationVariables>;
export const DeleteVatrateDocument = gql`
    mutation deleteVatrate($id: uuid!) {
  delete_vatrates_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteVatrateMutationFn = Apollo.MutationFunction<DeleteVatrateMutation, DeleteVatrateMutationVariables>;

/**
 * __useDeleteVatrateMutation__
 *
 * To run a mutation, you first call `useDeleteVatrateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVatrateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVatrateMutation, { data, loading, error }] = useDeleteVatrateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVatrateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVatrateMutation, DeleteVatrateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVatrateMutation, DeleteVatrateMutationVariables>(DeleteVatrateDocument, options);
      }
export type DeleteVatrateMutationHookResult = ReturnType<typeof useDeleteVatrateMutation>;
export type DeleteVatrateMutationResult = Apollo.MutationResult<DeleteVatrateMutation>;
export type DeleteVatrateMutationOptions = Apollo.BaseMutationOptions<DeleteVatrateMutation, DeleteVatrateMutationVariables>;
export const GetAgreementTypesDocument = gql`
    query getAgreementTypes {
  agreement_types(order_by: {code: asc_nulls_last}) {
    id
    code
    valid_num_days
    getaccept_template_id
    name
    description
    rate
    created_at
    updated_at
    organizations_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetAgreementTypesQuery__
 *
 * To run a query within a React component, call `useGetAgreementTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgreementTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgreementTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAgreementTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetAgreementTypesQuery, GetAgreementTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAgreementTypesQuery, GetAgreementTypesQueryVariables>(GetAgreementTypesDocument, options);
      }
export function useGetAgreementTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAgreementTypesQuery, GetAgreementTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAgreementTypesQuery, GetAgreementTypesQueryVariables>(GetAgreementTypesDocument, options);
        }
export type GetAgreementTypesQueryHookResult = ReturnType<typeof useGetAgreementTypesQuery>;
export type GetAgreementTypesLazyQueryHookResult = ReturnType<typeof useGetAgreementTypesLazyQuery>;
export type GetAgreementTypesQueryResult = Apollo.QueryResult<GetAgreementTypesQuery, GetAgreementTypesQueryVariables>;
export const AddAgreementTypeDocument = gql`
    mutation addAgreementType($code: String!, $valid_num_days: numeric = null, $getaccept_template_id: String = null, $name: String!, $description: String!, $rate: numeric = 0) {
  insert_agreement_types_one(
    object: {code: $code, valid_num_days: $valid_num_days, getaccept_template_id: $getaccept_template_id, name: $name, description: $description, rate: $rate}
  ) {
    id
  }
}
    `;
export type AddAgreementTypeMutationFn = Apollo.MutationFunction<AddAgreementTypeMutation, AddAgreementTypeMutationVariables>;

/**
 * __useAddAgreementTypeMutation__
 *
 * To run a mutation, you first call `useAddAgreementTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAgreementTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAgreementTypeMutation, { data, loading, error }] = useAddAgreementTypeMutation({
 *   variables: {
 *      code: // value for 'code'
 *      valid_num_days: // value for 'valid_num_days'
 *      getaccept_template_id: // value for 'getaccept_template_id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      rate: // value for 'rate'
 *   },
 * });
 */
export function useAddAgreementTypeMutation(baseOptions?: Apollo.MutationHookOptions<AddAgreementTypeMutation, AddAgreementTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAgreementTypeMutation, AddAgreementTypeMutationVariables>(AddAgreementTypeDocument, options);
      }
export type AddAgreementTypeMutationHookResult = ReturnType<typeof useAddAgreementTypeMutation>;
export type AddAgreementTypeMutationResult = Apollo.MutationResult<AddAgreementTypeMutation>;
export type AddAgreementTypeMutationOptions = Apollo.BaseMutationOptions<AddAgreementTypeMutation, AddAgreementTypeMutationVariables>;
export const AllUsersDocument = gql`
    query allUsers($limit: Int = 25, $offset: Int = 0) {
  users_aggregate {
    aggregate {
      count
    }
  }
  users(
    limit: $limit
    offset: $offset
    order_by: {first_name: asc_nulls_last, last_name: asc_nulls_last}
  ) {
    id
    first_name
    last_name
    email_address
    mobile_number
    organization_users(order_by: {created_at: asc_nulls_last}, limit: 1) {
      organization {
        name
      }
    }
    organization_users_aggregate(distinct_on: organization_id) {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useAllUsersQuery__
 *
 * To run a query within a React component, call `useAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUsersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAllUsersQuery(baseOptions?: Apollo.QueryHookOptions<AllUsersQuery, AllUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllUsersQuery, AllUsersQueryVariables>(AllUsersDocument, options);
      }
export function useAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllUsersQuery, AllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllUsersQuery, AllUsersQueryVariables>(AllUsersDocument, options);
        }
export type AllUsersQueryHookResult = ReturnType<typeof useAllUsersQuery>;
export type AllUsersLazyQueryHookResult = ReturnType<typeof useAllUsersLazyQuery>;
export type AllUsersQueryResult = Apollo.QueryResult<AllUsersQuery, AllUsersQueryVariables>;
export const GetUserByPkDocument = gql`
    query getUserByPk($id: uuid!) {
  users_by_pk(id: $id) {
    id
    first_name
    last_name
    mobile_number
  }
}
    `;

/**
 * __useGetUserByPkQuery__
 *
 * To run a query within a React component, call `useGetUserByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserByPkQuery(baseOptions: Apollo.QueryHookOptions<GetUserByPkQuery, GetUserByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserByPkQuery, GetUserByPkQueryVariables>(GetUserByPkDocument, options);
      }
export function useGetUserByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserByPkQuery, GetUserByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserByPkQuery, GetUserByPkQueryVariables>(GetUserByPkDocument, options);
        }
export type GetUserByPkQueryHookResult = ReturnType<typeof useGetUserByPkQuery>;
export type GetUserByPkLazyQueryHookResult = ReturnType<typeof useGetUserByPkLazyQuery>;
export type GetUserByPkQueryResult = Apollo.QueryResult<GetUserByPkQuery, GetUserByPkQueryVariables>;
export const namedOperations = {
  Query: {
    dealsDashboard: 'dealsDashboard',
    allOrganizations: 'allOrganizations',
    getOrgByPk: 'getOrgByPk',
    allOrganizationsWithDeal: 'allOrganizationsWithDeal',
    quickstats: 'quickstats',
    getAllZreports: 'getAllZreports',
    getTop5OrgsSales: 'getTop5OrgsSales',
    getTop5OrgsNumZreports: 'getTop5OrgsNumZreports',
    getTop5OrgsNumPos: 'getTop5OrgsNumPos',
    getTop5OrgsNumUsers: 'getTop5OrgsNumUsers',
    getTop5PosSales: 'getTop5PosSales',
    getTop5PosNumZreports: 'getTop5PosNumZreports',
    systemSearch: 'systemSearch',
    getVatRates: 'getVatRates',
    getAgreementTypes: 'getAgreementTypes',
    allUsers: 'allUsers',
    getUserByPk: 'getUserByPk'
  },
  Mutation: {
    addVatrate: 'addVatrate',
    deleteVatrate: 'deleteVatrate',
    addAgreementType: 'addAgreementType'
  }
}