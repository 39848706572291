import React from "react";

interface Props {
  label: string;
}

export const SidebarNavLabel: React.FC<Props> = ({ label }) => {
  return (
    <li className="px-5 hidden md:block">
      <div className="flex flex-row items-center h-8">
        <div className="text-sm font-light tracking-wide text-gray-400 uppercase">
          {label}
        </div>
      </div>
    </li>
  );
};
