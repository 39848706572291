import { ApolloClient, InMemoryCache } from "@apollo/client";
// import { localStorageVars } from "./config/localstorageVars";
// import { isServer } from "../utils/isServer";

const client = new ApolloClient({
  uri: "https://data.dev.getplinka.com/v1/graphql",
  headers: {
    // authorization: `Bearer ${
    //   isServer
    //     ? ""
    //     : localStorage.getItem(localStorageVars.PLINKA_TOKEN) || "none"
    // }`,
    "x-hasura-admin-secret": "aE9wQ3sE6hY0wX9dE1iH1vG2hW2eU3rP",
  },
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
});

export default client;
