import React from "react";

export const SidebarNav: React.FC = ({ children }) => {
  return (
    <div className="fixed flex flex-col top-14 items-center  bg-gray-200 h-full  transition-all duration-300 border-none z-10 sidebar shadow-xl w-1/6">
      <div className="overflow-y-auto overflow-x-hidden flex flex-col justify-between flex-grow">
        <ul className="flex flex-col py-4 space-y-1">{children}</ul>
      </div>
    </div>
  );
};
